import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import PageContent from '../../components/page-content/page-content';
import {Button, Table, DatePicker,Tabs} from 'antd';
import {getStat5} from '../../lib/api_stat'
import Box from '../../components/box';
import moment from 'moment';
import excelUtil from '../../lib/excelUtil.js'
import JiaoAn2 from './jiaolian2'
import StoreSelect from '../members/store-select';

const {Column} = Table;
const {TabPane} = Tabs;


// @hasAuth
class Staff extends Component {
    state = {
        store_id: 1,
        date: moment(),
        list1: [],
        list2: [],

        detailItem: null
    };

    componentDidMount() {
        // console.log(this);
        // this.hasRole();
        this.loadData()
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    loadData = () => {
        let end_time = moment(this.state.date).endOf('month').format("YYYY-MM-DD");
        getStat5(end_time, this.state.store_id)
            .then((result) => {
                if (result.code === 0) {
                    this.setState({
                        list1: result.data.list1,
                        list2: result.data.list2
                    })
                    // this.props.setStaff(result.data);
                } else {

                }
            })
    };
    onChange = (date, dateString) => {
        console.log(date, dateString);
        this.setState({
            date: date
        }, () => {
            this.loadData()
        })
    };

    onStoreChange = (id)=>{
        console.log(id)
        this.setState({
            store_id: id
        }, () => {
            this.loadData()
        })
    };

    columns = [{
        title: '教练',
        dataIndex: '教练',
        key: '教练',
    }, {
        title: '15分钟',
        dataIndex: '15分钟',
        key: '15分钟',
    }, {
        title: '30分钟',
        dataIndex: '30分钟',
        key: '30分钟',
    }, {
        title: '45分钟',
        dataIndex: '45分钟',
        key: '45分钟',
    }, {
        title: '合计',
        dataIndex: '合计',
        key: '合计',
    }, {
        title: '散客15分钟',
        dataIndex: '散客15分钟',
        key: '散客15分钟',
    }, {
        title: '散客30分钟',
        dataIndex: '散客30分钟',
        key: '散客30分钟',
    }, {
        title: '散客45分钟',
        dataIndex: '散客45分钟',
        key: '散客45分钟',
    }, {
        title: '会员',
        dataIndex: '会员',
        key: '会员',
    }, {
        title: '潜在',
        dataIndex: '潜在',
        key: '潜在',
    }, {
        title: '合计',
        dataIndex: '合计2',
        key: '合计2',
    }];

    getColumns = (list)=>{
        if(list.length > 0){
            return Object.keys(list[0]).map(name=>{
                return {
                    title: name,
                    dataIndex: name,
                    key: name,
                }
            })
        }
        return [];
    };

    exportXlsx = () => {
        excelUtil.exportExcels({
            "次卡":{
                headers: this.getColumns(this.state.list1),
                data: this.state.list1
            },
            "年卡":{
                headers: this.getColumns(this.state.list2),
                data: this.state.list2
            }
        }, `会员消课统计.xlsx`)
    };


    render() {
        let {list1,list2} = this.state;
        return (

                <Box>
                    <StoreSelect
                        style={{width:150}}
                        onChange={this.onStoreChange}
                        value={this.state.store_id}
                    />

                    <DatePicker
                        allowClear={false}
                        onChange={this.onChange}
                        value={this.state.date}
                        picker="month"/>
                    <Button onClick={this.exportXlsx}>导出</Button>
                    <Tabs defaultActiveKey="1">
                        <TabPane tab="次卡" key="1">
                            <Table
                                dataSource={list1}
                                rowKey={"教练"}
                                pagination={false}
                                size={"small"}
                            >
                                <Column
                                    title="编号"
                                    dataIndex="编号"
                                    key="编号"
                                />
                                <Column
                                    title="门店"
                                    dataIndex="门店"
                                    key="门店"
                                />
                                <Column
                                    title="会员名"
                                    dataIndex="会员名"
                                    key="会员名"
                                    // render={()=>}
                                />
                                <Column
                                    title="销售"
                                    dataIndex="销售"
                                    key="销售"
                                />
                                <Column
                                    title="教练"
                                    dataIndex="教练"
                                    key="教练"
                                />
                                <Column
                                    title="有效/过期"
                                    dataIndex="有效/过期"
                                    key="有效/过期"
                                />
                                <Column
                                    title="会籍数量"
                                    dataIndex="会籍数量"
                                    key="会籍数量"
                                />
                                <Column
                                    title="首次办卡日期"
                                    dataIndex="首次办卡日期"
                                    key="首次办卡日期"
                                />
                                <Column
                                    title="有效期至"
                                    dataIndex="有效期至"
                                    key="有效期至"
                                />
                                <Column
                                    title="累计充值金额"
                                    dataIndex="累计充值金额"
                                    key="累计充值金额"
                                />
                                <Column
                                    title="累计充值课时"
                                    dataIndex="累计充值课时"
                                    key="累计充值课时"
                                />
                                <Column
                                    title="累计赠送课时"
                                    dataIndex="累计赠送课时"
                                    key="累计赠送课时"
                                />
                                <Column
                                    title="1月消课"
                                    dataIndex="1月消课"
                                    key="1月消课"
                                />
                                <Column
                                    title="2月消课"
                                    dataIndex="2月消课"
                                    key="2月消课"
                                />
                                <Column
                                    title="3月消课"
                                    dataIndex="3月消课"
                                    key="3月消课"
                                />
                                <Column
                                    title="4月消课"
                                    dataIndex="4月消课"
                                    key="4月消课"
                                />
                                <Column
                                    title="5月消课"
                                    dataIndex="5月消课"
                                    key="5月消课"
                                />
                                <Column
                                    title="6月消课"
                                    dataIndex="6月消课"
                                    key="6月消课"
                                />
                                <Column
                                    title="7月消课"
                                    dataIndex="7月消课"
                                    key="7月消课"
                                />
                                <Column
                                    title="8月消课"
                                    dataIndex="8月消课"
                                    key="8月消课"
                                />
                                <Column
                                    title="9月消课"
                                    dataIndex="9月消课"
                                    key="9月消课"
                                />
                                <Column
                                    title="10月消课"
                                    dataIndex="10月消课"
                                    key="10月消课"
                                />
                                <Column
                                    title="11月消课"
                                    dataIndex="11月消课"
                                    key="11月消课"
                                />
                                <Column
                                    title="12月消课"
                                    dataIndex="12月消课"
                                    key="12月消课"
                                />
                                <Column
                                    title="剩余充值课时"
                                    dataIndex="剩余充值课时"
                                    key="剩余充值课时"
                                />
                                <Column
                                    title="剩余赠送课时"
                                    dataIndex="剩余赠送课时"
                                    key="剩余赠送课时"
                                />



                            </Table>

                        </TabPane>
                        <TabPane tab="年卡" key="2">
                            <Table
                                dataSource={list2}
                                rowKey={"教练"}
                                pagination={false}
                                size={"small"}
                            >
                                <Column
                                    title="编号"
                                    dataIndex="编号"
                                    key="编号"
                                />
                                <Column
                                    title="门店"
                                    dataIndex="门店"
                                    key="门店"
                                />
                                <Column
                                    title="会员名"
                                    dataIndex="会员名"
                                    key="会员名"
                                />
                                <Column
                                    title="销售"
                                    dataIndex="销售"
                                    key="销售"
                                />
                                <Column
                                    title="教练"
                                    dataIndex="教练"
                                    key="教练"
                                />
                                <Column
                                    title="有效/过期"
                                    dataIndex="有效/过期"
                                    key="有效/过期"
                                />
                                <Column
                                    title="会籍数量"
                                    dataIndex="会籍数量"
                                    key="会籍数量"
                                />
                                <Column
                                    title="首次办卡日期"
                                    dataIndex="首次办卡日期"
                                    key="首次办卡日期"
                                />
                                <Column
                                    title="有效期至"
                                    dataIndex="有效期至"
                                    key="有效期至"
                                />
                                <Column
                                    title="累计充值年费"
                                    dataIndex="累计充值年费"
                                    key="累计充值年费"
                                />
                                <Column
                                    title="累计充值教练费"
                                    dataIndex="累计充值教练费"
                                    key="累计充值教练费"
                                />
                                <Column
                                    title="累计赠送教练费"
                                    dataIndex="累计赠送教练费"
                                    key="累计赠送教练费"
                                />
                                <Column
                                    title="1月消课"
                                    dataIndex="1月消课"
                                    key="1月消课"
                                />
                                <Column
                                    title="2月消课"
                                    dataIndex="2月消课"
                                    key="2月消课"
                                />
                                <Column
                                    title="3月消课"
                                    dataIndex="3月消课"
                                    key="3月消课"
                                />
                                <Column
                                    title="4月消课"
                                    dataIndex="4月消课"
                                    key="4月消课"
                                />
                                <Column
                                    title="5月消课"
                                    dataIndex="5月消课"
                                    key="5月消课"
                                />
                                <Column
                                    title="6月消课"
                                    dataIndex="6月消课"
                                    key="6月消课"
                                />
                                <Column
                                    title="7月消课"
                                    dataIndex="7月消课"
                                    key="7月消课"
                                />
                                <Column
                                    title="8月消课"
                                    dataIndex="8月消课"
                                    key="8月消课"
                                />
                                <Column
                                    title="9月消课"
                                    dataIndex="9月消课"
                                    key="9月消课"
                                />
                                <Column
                                    title="10月消课"
                                    dataIndex="10月消课"
                                    key="10月消课"
                                />
                                <Column
                                    title="11月消课"
                                    dataIndex="11月消课"
                                    key="11月消课"
                                />
                                <Column
                                    title="12月消课"
                                    dataIndex="12月消课"
                                    key="12月消课"
                                />
                                <Column
                                    title="剩余充值教练费"
                                    dataIndex="剩余充值教练费"
                                    key="剩余充值教练费"
                                />
                                <Column
                                    title="剩余赠送教练费"
                                    dataIndex="剩余赠送教练费"
                                    key="剩余赠送教练费"
                                />



                            </Table>

                        </TabPane>

                    </Tabs>
                    {/*{detailItem &&*/}
                    {/*<JiaoAn2*/}
                        {/*visible={true}*/}
                        {/*nick_name={detailItem["教练"]}*/}
                        {/*begin_time={begin_time}*/}
                        {/*end_time={end_time}*/}
                        {/*onClose={()=>{*/}
                            {/*this.setState({detailItem: null})*/}
                        {/*}*/}
                        {/*}*/}
                    {/*/>*/}
                    {/*}*/}
                </Box>


        );
    }
}


export default withRouter(Staff);



