/**
 * @name: 学校信息
 * @description:
 * @author: Wood
 * @date: 2019/3/28
 * @update:
 */
import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {connect} from 'react-redux';
import styles from './login.module.css';
import {LockOutlined, UserOutlined} from '@ant-design/icons';
import {Form} from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
// import AccountMenuComponent from '../components/account-menu/account-menu';
// import {signOut} from "../reducers/account";
// import {showLoginModal} from "../reducers/login-modal";
import {Input, Button, Checkbox, Row} from 'antd';
// import bubbly from 'bubbly-bg';
import md5 from 'md5';
import {postAccountLogin, getAccountInfo} from "../../lib/api_account.js";
import {setAccount, signOut} from "../../reducers/account";
import {setStaff} from "../../reducers/staff";
import {setCoursesType} from "../../reducers/courses_type";
import {setStudents} from "../../reducers/students";
import {setUsersCards} from "../../reducers/users_cards";
import {setStores} from "../../reducers/stores";
import {setMenus} from "../../reducers/menus";
import {setMemberships} from "../../reducers/memberships";
import cookies from 'js-cookie';
import logo from '../../assets/images/资源 27@4x.png';

class Login extends Component {
    // handleLogout=()=>{
    //     this.props.signOut();
    //     this.props.showLoginModal();
    // };
    componentDidMount() {
        // window.bubbly();
        // 获取用户信息，判断是否已登录
        getAccountInfo()
            .then(res => {
                if (res.code === 0) {
                    // 设置用户信息
                    this.props.setMenus(res.data.menus);
                    this.props.setAccount(res.data.account);
                    this.props.setStaff(res.data.staff);
                    this.props.setCoursesType(res.data.courses_type);
                    this.props.setStudents(res.data.students);
                    this.props.setUsersCards(res.data.users_cards);
                    this.props.setStores(res.data.stores);
                    this.props.setMemberships(res.data.memberships);

                } else {
                    // 清除登录信息
                    this.props.signOut();
                }
            })
            .catch((err) => {
                // 清除登录信息
                // this.props.signOut();
            });
    }

    componentWillUnmount() {
        // 删除特效
        // document.body.removeChild(document.querySelector("canvas"))
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
                // 登录请求
                postAccountLogin(values.username, md5(values.password))
                    .then((result) => {
                        switch (result.code) {
                            case 0: {
                                this.props.setMenus(result.data.menus);
                                this.props.setAccount(result.data.account);
                                this.props.setStaff(result.data.staff);
                                this.props.setCoursesType(result.data.courses_type);
                                this.props.setStudents(result.data.students);
                                this.props.setUsersCards(result.data.users_cards);
                                this.props.setStores(result.data.stores);
                                break;
                            }
                            case 1011: {
                                this.props.form.setFields({
                                    username: {
                                        value: values.username,
                                        errors: [new Error(result.message)],
                                    },
                                });
                                break;
                            }
                            case 1012: {
                                this.props.form.setFields({
                                    password: {
                                        value: values.password,
                                        errors: [new Error(result.message)],
                                    },
                                });
                                break;
                            }
                            default:
                                break;
                        }
                    })
            }
        });
    };


    render() {
        const {getFieldDecorator, getFieldsError, getFieldError, isFieldTouched} = this.props.form;
        // const usernameError = isFieldTouched('username') && getFieldError('username');

        return (
            <div type="flex" justify="center" className={styles["wrapper"]}>
                <div>
                    <Form onSubmit={this.handleSubmit} className={styles["login-form"]} wrapperCol={{span: 24}}>
                        {/*<h1 style={{textAlign: "center"}}>郡安里·JEC郡域马术中心</h1>*/}
                        <div style={{textAlign:"center", padding: 30}}>
                        <img style={{width: 300}} src={logo} />
                        </div>
                        <Form.Item>
                            {getFieldDecorator('username', {
                                rules: [{required: true, message: '请输入账号!'}],
                            })(
                                <Input
                                    prefix={<UserOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
                                    placeholder="账号"
                                    size="large"
                                    block
                                />,
                            )}
                        </Form.Item>
                        <Form.Item>
                            {getFieldDecorator('password', {
                                rules: [{required: true, message: '请输入密码!'}],
                            })(
                                <Input
                                    prefix={<LockOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
                                    type="password"
                                    placeholder="密码"
                                    size="large"
                                />,
                            )}
                        </Form.Item>
                        <Form.Item>
                            {/*{getFieldDecorator('remember', {*/}
                            {/*valuePropName: 'checked',*/}
                            {/*initialValue: true,*/}
                            {/*})(<Checkbox>7天内免登录</Checkbox>)}*/}
                            {/*<a className={styles["login-form-forgot"]} href="">*/}
                            {/*忘记密码?*/}
                            {/*</a>*/}
                            <Button type="primary" htmlType="submit" className={styles["login-form-button"]}
                                    size="large">
                                登录
                            </Button>
                            {/*联系电话：<a href="tel:13362205802">13362205802</a>*/}
                        </Form.Item>
                    </Form>
                </div>
                <div className={styles["copy-right"]}>iCA爱宝莉骑士院 © 2019 版权所有 浙ICP备16013852号-5</div>
            </div>
        );
    }
}

const WrappedNormalLoginForm = Form.create({name: 'normal_login'})(Login);

const mapStateToProps = state => ({
    account: state.account
});

const mapDispatchToProps = dispatch => ({
    setAccount: (account) => dispatch(setAccount(account)),
    signOut: () => dispatch(signOut()),
    setStaff: (staff) => dispatch(setStaff(staff)),
    setCoursesType: (arr) => dispatch(setCoursesType(arr)),
    setStudents: (arr) => dispatch(setStudents(arr)),
    setUsersCards: (arr) => dispatch(setUsersCards(arr)),
    setStores: (arr) => dispatch(setStores(arr)),
    setMenus: (arr) => dispatch(setMenus(arr)),
    setMemberships: (arr) => dispatch(setMemberships(arr)),
    // showLoginModal: () => dispatch(showLoginModal()),
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(WrappedNormalLoginForm));

