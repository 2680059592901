import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {Card, Button, Space, Divider, Popconfirm, Table} from 'antd';
import PageContent from '../../../components/page-content/page-content';
import MembershipAddModal from './membership_add_modal';
import {PlusOutlined} from '@ant-design/icons';
import {getMembership} from "../../../lib/api_membership";
import moment from 'moment';
import Box from '../../../components/box';

const Membership = ({match}) => {
    const [list, setList] = useState([]);
    const [visible, setVisible] = useState(false);

    const loadData = () => {
        getMembership()
            .then(res => {
                if (res.code === 0) {
                    setList(res.data);
                }
            })
    };
    useEffect(loadData, []);

    return (
        <PageContent title={"vip会籍管理"}>
            <Box bordered={false}>
                <Button
                    type="primary"
                    icon={<PlusOutlined/>}
                    onClick={() => setVisible(true)}
                >添加</Button>
                <Table
                    // title="课程设置"
                    size="small"
                    pagination={false}
                    dataSource={list}
                    rowKey={"id"}
                    columns={[{
                        title: '会籍名称',
                        dataIndex: 'name',
                        key: "会籍名称",
                        render:(name,record)=>{
                            return (
                                <Link to={`/member/member_ship/${record.id}`}>{name}</Link>
                            )
                        }
                    }, {
                        title: '开始日期',
                        dataIndex: 'begin_time',
                        key: "开始日期",
                        render: (begin_time)=> moment(begin_time).format("YYYY-MM-DD")
                    }, {
                        title: '结束日期',
                        dataIndex: 'end_time',
                        key: "结束日期",
                        render: (end_time)=> moment(end_time).format("YYYY-MM-DD")
                    }, {
                        title: '成员',
                        key: "成员",
                        render: (record)=> {
                            return record.users.map(_=><Link to={`/member/members/${_.id}`} style={{marginRight:10}}>{_.nick_name}</Link>)
                        }
                    }, {
                        title: '剩余教练费',
                        dataIndex: 'fee_remaining',
                        key: "剩余教练费",
                        align: "right"
                    }, {
                        title: '剩余赠送教练费',
                        dataIndex: 'fee_free_remaining',
                        key: "剩余赠送教练费",
                        align: "right"
                    }, {
                        title: '状态',
                        dataIndex: 'status',
                        key: "状态",
                        render: (status => {
                            return status === 1 ? "停用" : "启用"
                        })
                    }]}/>
            </Box>
            <MembershipAddModal
                visible={visible}
                onOk={() => {
                    setVisible(false);
                    loadData()
                }}
                onCancel={() => {
                    setVisible(false)
                }}
                {...match.params}
            />

        </PageContent>
    )
};


export default Membership;


