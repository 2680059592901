import PropTypes from 'prop-types';
import {connect} from 'react-redux';

const DictName = ({value, type_value, dict_types}) => {
    if (!dict_types) return "";
    if(!value) return"";
    const dict_type = dict_types.find(_ => _.value === type_value);
    if (dict_type) {
        let dict = dict_type.sys_dicts.find(_ => _.value === value.toString());
        if (dict) {
            return dict.key;
        }
    }
    return "";
};

DictName.propTypes = {
    value: PropTypes.string,
    type_value: PropTypes.string,
};


const mapStateToProps = state => ({
    dict_types: state.account.dict_types
});

const mapDispatchToProps = dispatch => ({});


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DictName);


