
import axios from './rest-axios';

const getCards = () => axios.get(`/v2/cards`);
const postCard = (model) => axios.post(`/v1/cards`, model);
const putCard = (model) => axios.put(`/v1/cards/${model.id}`, model);


export {
    getCards,
    postCard,
    putCard,
};
