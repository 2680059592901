/**
 * @name: 会员启用对话框
 * @description:
 * @author: Wood
 * @date: 2021/12/16
 * @update:
 */
import React from 'react';
import {Modal, Input, Form} from 'antd';
import {postMemberUnFile} from "../../../lib/api_members";

const MembersFileModal = ({user_info, onOk, onCancel}) => {
    const [form] = Form.useForm();

    const handleOk = () => {
        form.validateFields()
            .then(values => {
                postMemberUnFile(user_info.id, values.remark)
                    .then(result => {
                        console.log(result);
                        // 刷新数据
                        if (result.code === 0) {
                            onOk && onOk()
                        }
                    })
            })
    };

    const handleClose = () => {
        onCancel && onCancel();
    };
    return (
        <Modal
            title="启用会员"
            visible={!!user_info}
            onOk={handleOk}
            onCancel={handleClose}
            destroyOnClose={true}
        >
            <Form form={form}>
                <Form.Item label="会员名">
                    {user_info && user_info.nick_name}
                </Form.Item>
                <Form.Item label="手机号">
                    {user_info && user_info.mobile_number}
                </Form.Item>
                <Form.Item label="说明" name="remark"
                           rules={[
                               {
                                   required: true,
                                   message: '请输入说明',
                               },
                           ]}
                >
                    <Input.TextArea/>
                </Form.Item>
            </Form>
        </Modal>
    )
};


export default MembersFileModal;
