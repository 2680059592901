import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {postMembers} from "../../lib/api_members.js";
import {Modal, Input, Row, Col, Radio, DatePicker, Form, Cascader} from 'antd';
import TeacherSelect from './teacher-select';
import {PlusOutlined} from '@ant-design/icons';
import MemberNumber from './member-number';
import options from '../../lib/cascader-address-options';
import StoreSelect from './store-select';
import DictRadioGroup from '../../components/dict/dict-radio-group';

function filter(inputValue, path) {
    return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
}

class MembersAddModal extends Component {
    formRef = React.createRef();


    handleClose = () => {
        this.props.onClose && this.props.onClose();
    };

    handleOK = () => {
        this.props.onOK && this.props.onOK();
    };


    handleSubmit = e => {
        e.preventDefault();
        this.formRef.current.validateFields()
            .then(values => {
                console.log('Received values of form: ', values);

                postMembers(values)
                    .then(result => {
                        console.log(result);
                        // 刷新数据
                        if (result.code === 0) {
                            this.handleOK();

                        }
                    })
            });
    };


    render() {

        const layout = {
            labelCol: {span: 8},
            wrapperCol: {span: 16},
        };


        return (
            <Modal
                title={<span><PlusOutlined/> 添加会员</span>}
                visible={this.props.visible}
                onOk={this.handleSubmit}
                onCancel={this.handleClose}
                width={800}
                destroyOnClose={true}
            >

                <Form
                    {...layout}
                    className="ant-advanced-search-form"
                    onSubmit={this.handleSubmit}
                    ref={this.formRef}
                    initialValues={{
                        sex: 1,
                        tag1:"",
                        tag2:"",
                        store_id: 1,
                    }}
                >

                    <Row gutter={0}>
                        <Col span={8}>
                            <Form.Item
                                label="会员姓名"
                                name="nick_name"
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入姓名',
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="会员昵称"
                                name="other_name"
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={0}>
                        <Col span={8}>
                            <Form.Item
                                label="会员性别"
                                name="sex"
                                rules={[
                                    {
                                        required: true,
                                        message: '请选择性别',
                                    },
                                ]}
                            >
                                <Radio.Group>
                                    <Radio value={1}>男</Radio>
                                    <Radio value={2}>女</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="出生年月"
                                name="birthday"
                                rules={[
                                    {
                                        required: true,
                                        message: '请选择出生年月',
                                    },
                                ]}
                            >
                                <DatePicker/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={0}>

                        <Col span={8}>
                            <Form.Item
                                label="联系电话"
                                name="mobile_number"
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入联系电话',
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="车牌号码"
                                name="license_plate"
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={0}>
                        <Col span={8}>
                            <Form.Item
                                label="专属教练"
                                name="teacher"
                                rules={[
                                    {
                                        required: true,
                                        message: '请选择教练',
                                    },
                                ]}
                            >
                                <TeacherSelect isCoach={true}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="课程顾问"
                                name="consultant"
                                rules={[
                                    {
                                        required: true,
                                        message: '请选择课程顾问',
                                    },
                                ]}
                            >
                                <TeacherSelect isCoach={false}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="初始密码"
                                name="password"
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form.Item
                        label="会员编号"
                        labelCol={{flex: "83px"}}
                        name="member_number"
                    >
                        <MemberNumber/>
                    </Form.Item>
                    <Form.Item
                        label="备　　注"
                        labelCol={{flex: "83px"}}
                        name="remark"
                    >
                        <Input.TextArea autosize={{minRows: 2}}/>

                    </Form.Item>
                    <Form.Item
                        label="居 住 地"
                        labelCol={{flex: "83px"}}
                        name="residence"
                    >
                        <Cascader
                            expandTrigger="hover"
                            options={options}
                            showSearch={{filter}}
                            placeholder="请选择居住地"
                            style={{width: 300}}
                            changeOnSelect
                        />

                    </Form.Item>
                    <Form.Item
                        label="训练类型"
                        labelCol={{flex: "83px"}}
                        name="train_type"
                        rules={[
                            {
                                required: true,
                                message: '请选择训练类型',
                            },
                        ]}
                    >
                        <DictRadioGroup type_value={"train_type"} />
                    </Form.Item>
                    <Form.Item
                        label="标记1"
                        labelCol={{flex: "83px"}}
                        name="tag1"
                    >
                        <DictRadioGroup type_value={"member-tag1"} />
                        {/*<Radio.Group>*/}
                            {/*<Radio value={""}>无</Radio>*/}
                            {/*<Radio value={"*"}>*</Radio>*/}
                            {/*<Radio value={"**"}>**</Radio>*/}
                        {/*</Radio.Group>*/}
                    </Form.Item>
                    <Form.Item
                        label="标记2"
                        labelCol={{flex: "83px"}}
                        name="tag2"
                    >
                        <DictRadioGroup type_value={"member-tag2"} />
                        {/*<Radio.Group>*/}
                            {/*<Radio value={""}>无</Radio>*/}
                            {/*<Radio value={"^"}>^</Radio>*/}
                            {/*<Radio value={"^^"}>^^</Radio>*/}
                        {/*</Radio.Group>*/}
                    </Form.Item>
                    <Form.Item
                        label="门店"
                        labelCol={{flex: "83px"}}
                        name="store_id"
                        rules={[
                            {
                                required: true,
                                message: '请选择门店',
                            },
                        ]}
                    >
                        <StoreSelect />
                    </Form.Item>



                </Form>
            </Modal>
        )
    }
}

MembersAddModal.propTypes = {
    visible: PropTypes.bool,
    onClose: PropTypes.func,
    onOK: PropTypes.func,
};

export default MembersAddModal;
