import PropTypes from 'prop-types';
import React, {Component} from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Input, message } from 'antd';
import md5 from 'md5';
import {resetPassword} from "../../../lib/api_members";

class StaffResetModal extends Component {

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    handleClose = () => {
        this.props.onClose && this.props.onClose();
    };

    handleOK = () => {
        this.props.onOK && this.props.onOK();
    };

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);

                resetPassword(this.props.user_info.id, md5(values.password))
                    .then(result => {
                        if (result.code === 0) {
                            message.success("重置密码成功！");
                            this.handleOK();
                        } else {
                            message.error(result.message);
                        }
                    })
                    .catch(err => {
                        message.error(err);
                    });
            }
        });
    };

    render() {
        const {getFieldDecorator} = this.props.form;

        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
            },
            wrapperCol: {
                xs: {span: 24},
            },
        };

        return (
            <Modal
                title="重置密码"
                visible={!!this.props.user_info}
                onOk={this.handleSubmit}
                onCancel={this.handleClose}
                destroyOnClose={true}
            >
                <div>
                    姓名：{this.props.user_info && this.props.user_info.nick_name}<br/>
                    手机号：{this.props.user_info && this.props.user_info.mobile_number}
                </div>
                <Form {...formItemLayout} labelAlign="left" onSubmit={this.handleSubmit}>
                    <Form.Item label="重置密码">
                        {getFieldDecorator("password", {
                            rules: [
                                {
                                    required: true,
                                    message: '请输入密码',
                                }
                            ],
                            initialValue: "123456"
                        })(<Input/>)}
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}

StaffResetModal.propTypes = {
    onClose: PropTypes.func,
    onOK: PropTypes.func,
    user_info: PropTypes.object
};

export default Form.create({name: 'staff-reset'})(StaffResetModal);
