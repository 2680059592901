import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Form} from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {Modal, Input, InputNumber, Radio, DatePicker, Checkbox} from 'antd';
import CoursesTypeCheck from './courses_type_check';
import {putCard} from "../../../lib/api_cards";
import moment from 'moment';

class CardsEditModal extends Component {
    state = {
        card_type: 0
    };

    componentDidMount() {
        if (this.props.data) {
            this.setState({
                card_type: this.props.data.card_type
            })
        }
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    handleClose = () => {
        this.props.onClose && this.props.onClose();
    };

    handleOK = () => {
        this.props.onOK && this.props.onOK();
    };

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
                values.id = this.props.data.id;
                values.holiday_use = values.holiday_use ? 1 : 0;
                values.weekdays_use = values.weekdays_use ? 1 : 0;
                values.special_use = values.special_use ? 1 : 0;
                values.expired_date = values.expired_date ? values.expired_date.format("YYYY-MM-DD") : null;
                putCard(values)
                    .then(result => {
                        console.log(result);
                        // 刷新数据
                        this.handleOK();
                    })
            }
        });
    };
    handleCardTypeChange = (e)=>{
        console.log(e)
        this.setState({
            card_type: e.target.value
        })
    };

    render() {
        const {getFieldDecorator} = this.props.form;
        const {data} = this.props;
        let {card_type}  = this.state;

        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 6},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 18},
            },
        };

        if (data) {
            return (

                <Modal
                    title="修改次卡信息"
                    visible={!!data}
                    onOk={this.handleSubmit}
                    onCancel={this.handleClose}
                    destroyOnClose={true}
                >
                    <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                        <Form.Item label="卡片名称">
                            {getFieldDecorator("name", {
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入卡片名称',
                                    },
                                ],
                                initialValue: data.name
                            })(<Input/>)}
                        </Form.Item>

                        <Form.Item label="正课">
                            {getFieldDecorator("keshi", {
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入正课课时数目',
                                    },
                                ],
                                initialValue: data.keshi
                            })(<InputNumber min={1}/>)}
                        </Form.Item>
                        <Form.Item label="门市价">
                            {getFieldDecorator("retail_price", {
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入门市价',
                                    },
                                ],
                                initialValue:data.retail_price
                            })(<InputNumber min={0} precision={2}/>)}
                        </Form.Item>
                        <Form.Item label="课程">
                            {getFieldDecorator("courses_type", {
                                rules: [
                                    {
                                        required: true,
                                        message: '请选择课程',
                                    },
                                ],
                                initialValue: data.courses_type
                            })(<CoursesTypeCheck/>)}
                        </Form.Item>
                        <Form.Item label="使用限制">
                            {getFieldDecorator("time_type", {
                                rules: [
                                    {
                                        required: true,
                                        message: '请选择使用限制',
                                    },
                                ],
                                initialValue: data.time_type
                            })(
                                <Radio.Group>
                                    <Radio value={0} style={{display: "block"}}>开卡后
                                        {getFieldDecorator("limit_days", {
                                            initialValue: data.limit_days
                                        })(
                                            <InputNumber min={1}/>
                                        )}
                                        天内可使用</Radio>
                                    <Radio value={1}>固定日期：到
                                        {getFieldDecorator("expired_date", {
                                            initialValue: data.expired_date ? moment(data.expired_date) : null
                                        })(
                                            <DatePicker format={"YYYY-MM-DD"}/>
                                        )}
                                        之前可用</Radio>
                                </Radio.Group>
                            )}
                        </Form.Item>
                        <Form.Item label="使用时间">
                            {getFieldDecorator("holiday_use", {
                                valuePropName: 'checked',
                                initialValue: data.holiday_use === 1
                            })(
                                <Checkbox>周末</Checkbox>
                            )}
                            {getFieldDecorator("weekdays_use", {
                                valuePropName: 'checked',
                                initialValue: data.weekdays_use === 1
                            })(
                                <Checkbox>工作日</Checkbox>
                            )}

                            {getFieldDecorator("special_use", {
                                valuePropName:'checked',
                                initialValue: data.special_use === 1
                            })(
                                <Checkbox>特殊日期</Checkbox>
                            )}
                        </Form.Item>
                    </Form>
                </Modal>
            )
        } else {
            return null;
        }
    }
}

CardsEditModal.propTypes = {
    onClose: PropTypes.func,
    onOK: PropTypes.func,
    data: PropTypes.object
};

export default Form.create({name: 'CoursesTypeEdit'})(CardsEditModal);
