import React, {useState} from 'react';
import {Button, message} from 'antd';
import {postStaffFromQYWX} from "../../../lib/api_staff";


const ButtonTongBu = ({onSuccess, onFailed}) => {
    const [loading, setLoading] = useState(false);

    const tongbufromqywx = () => {
        setLoading(true);
        postStaffFromQYWX()
            .then(res => {
                if (res.code === 0) {
                    message.success("同步成功！")
                    onSuccess && onSuccess()
                } else {
                    onFailed && onFailed(res.message)
                }
            })
            .catch(err => {
                onFailed && onFailed(err)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (<Button onClick={tongbufromqywx} loading={loading}>从企业微信同步信息</Button>)
};

export default ButtonTongBu;