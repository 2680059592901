
import axios from './rest-axios';

// const getStaff = () => axios.get(`/v1/staff`);
const postYuYue = (model) => axios.post(`/v2/yuyue`, model);
const getYuYue = () => axios.get(`/v1/yuyue`);
const postYuYueShangma = (id) => axios.post(`/v2/yuyue/shangma`, {id});
const postYuYueWancheng = (id) => axios.post(`/v2/yuyue/wancheng`, {id});
const postYuYueQuxiao = (id) => axios.post(`/v2/yuyue/quxiao`, {id});
const postYuYueQuXiaoShangma = (id) => axios.post(`/v2/yuyue/qxshangma`, {id});
const postYuYueTongBao = (date, ids) => axios.post(`/v1/yuyue/tongbao`, {date, ids});
const putYuYueDrag = (model) => axios.put(`/v2/yuyue/${model.id}/drag`, model);
const getYuYueStat = (date) => axios.get(`/v2/yuyue/stat`, {date});
const postYuYueStat = (list) => axios.post(`/v2/yuyue/stat`, {data: list});
const putYuYueStat = (data) => axios.put(`/v1/yuyue/stat`, data);

const postYuYueDelete = (id) => axios.post(`/v2/yuyue/delete`, {id});

export {
    // getStaff,
    postYuYue,
    getYuYue,
    postYuYueShangma,
    postYuYueWancheng,
    postYuYueQuxiao,
    postYuYueQuXiaoShangma,
    postYuYueTongBao,
    putYuYueDrag,
    getYuYueStat,
    postYuYueStat,
    postYuYueDelete,
    putYuYueStat
};
