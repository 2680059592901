
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {Modal} from 'antd';
import Form, {useForm} from 'form-render';
import FormDataCheck from '../../../components/data/form-data-check';
import {putData} from "../../../lib/api_data";

const schema = {
    "type": "object",
    "properties": {
        "name": {
            "title": "名称",
            "required": true,
            "disabled": false,
            "readOnly": false,
            "hidden": false,
            "type": "string"
        },
        "order_index": {
            "title": "排序",
            "required": true,
            "disabled": false,
            "readOnly": false,
            "hidden": false,
            "type": "number",
            "min": 0,
        }
    },
    "column": 1,
    "labelWidth": 150,
    "displayType": "row",
    "showDescIcon": true
};


const SysDictEditModal = ({visible, data, onOk, onCancel}) => {
    const form = useForm();

    const onFinish = (formData, errors) => {
        if (errors.length > 0) {
        } else {
            console.log(formData);
            formData = {...data, ...formData};
            putData("staff_config", formData)
                .then(res => {
                    if (res.code === 0) {
                        onOk && onOk(res.data);
                    }
                })
        }
    };

    useEffect(() => {
        form.setValues(data)
    }, []);

    return (
        <Modal
            title="修改自定义信息"
            visible={visible}
            onOk={form.submit}
            onCancel={onCancel}
            // width={800}
            destroyOnClose={true}
        >
            <Form
                form={form}
                schema={schema}
                widgets={{FormDataCheck: FormDataCheck}}
                onFinish={onFinish}
                // initialValues={data}
            />
        </Modal>
    )
};

SysDictEditModal.propTypes = {
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
    onOk: PropTypes.func,
    data: PropTypes.object,
    type_id: PropTypes.string,
};

export default SysDictEditModal;
