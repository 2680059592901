
import axios from './rest-axios';

const getKeshiBatch = () => axios.get(`/v1/keshi_batch`);
const postKeshiBatch = (model) => axios.post(`/v1/keshi_batch`, model);
const deleteKeshiBatch = (id) => axios.delete(`/v1/keshi_batch/${id}`);
// const putCard = (model) => axios.put(`/v1/cards/${model.id}`, model);


export {
    getKeshiBatch,
    postKeshiBatch,
    deleteKeshiBatch,
};
