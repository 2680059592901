
import axios from './rest-axios';

const getMembershipType = (id) => axios.get(`/v2/membership_type`, {id});
const postMembershipType = (model) => axios.post(`/v2/membership_type`, model);
const putMembershipType = (model) => axios.put(`/v2/membership_type/${model.id}`, model);

export {
    getMembershipType,
    postMembershipType,
    putMembershipType,
};
