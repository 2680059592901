import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Checkbox, Row, Col, Table, Select, Input} from 'antd';
// import {courses_type} from "../../config";
// import {getCoursesType} from '../../lib/api_courses_type';
const {Column} = Table;

class MemberCardSelecter extends Component {

    state = {
        value: "",
        list: [],
    };

    componentDidMount() {
        this.dealValue(this.props.value);
    }

    componentWillReceiveProps(props) {
        if (props.value !== this.state.value) {
            this.dealValue(props.value);
        }
    }

    // 处理数据
    dealValue = (value) => {
        console.log(value);
        if (value) {
            let list = JSON.parse(value);
            if (list) {
                list.forEach(mc => {
                    let student = this.props.students.find(item => item.id === mc.user_id);
                    if (student) {
                        student.cards = this.props.users_cards.filter(item => item.user_id === student.id);
                        mc.nick_name = student.nick_name;
                        mc.cards = student.cards;
                        // mc.mobile_number = student.mobile_number;
                        // mc.license_plate = student.license_plate;
                    }
                });

                this.setState({
                    list: list,
                    value: value
                })
            } else {
                this.setState({
                    list: [],
                    value: value
                })
            }
        } else {
            this.setState({
                list: [],
                value: value
            })
        }
    };

    onMemberChange = (id) => {
        // console.log(id)
        let {users_cards} = this.props;
        let {list} = this.state;
        if (list.some(item => item.user_id === id)) {
            return;
        }
        let student = this.props.students.find(item => item.id === id);
        if (student) {
            // 获取卡片信息
            console.log(id)
            student.cards = users_cards.filter(item => item.user_id === student.id);

            console.log(student.cards);
            let card_id = 0;
            let keshi_remaining =0;
            if (student.cards.length > 0) {
                card_id = student.cards[0].id;
                keshi_remaining=student.cards[0].keshi_remaining;
            }
            list.push({
                user_id: student.id,
                nick_name: student.nick_name,
                card_id: card_id,
                keshi_remaining:keshi_remaining,
                cards: student.cards,
                mobile_number: student.mobile_number,
                license_plate: student.license_plate,
            });
            this.setState({list: [...list]}, this.handleChange)
        }
    };

    onMemberRemove = (id) => {
        let {list} = this.state;
        list = list.filter(item => item.user_id !== id);
        this.setState({list: list}, this.handleChange)
    };

    onCardChange = (record, card_id, keshi_remaining) => {
        record.card_id = card_id;
        let card = record.cards.find(item=> item.id === card_id);
        record.keshi_remaining = card.keshi_remaining;
        this.setState({}, this.handleChange)
    };

    // fetchData = () => {
    //     getCoursesType()
    //         .then(res=>{
    //             if(res.code===0){
    //                 this.setState({
    //                     list: res.data
    //                 })
    //             }
    //         })
    // };

    handleChange = () => {
        // console.log(e);
        let {list} = this.state;
        console.log(list);
        this.props.onChange && this.props.onChange(JSON.stringify(list.map(item => {
            return {
                nick_name: item.nick_name,
                user_id: item.user_id,
                card_id: item.card_id,
                keshi_remaining: item.keshi_remaining,
                mobile_number: item.mobile_number,
                license_plate: item.license_plate,
            }
        })));
    };

    render() {
        let {list} = this.state;
        let {staff, students, users_cards} = this.props;
        // console.log(list);
        return (
            <div>
                <Select
                    showSearch
                    // size={"large"}
                    // style={{ width: 200 }}
                    placeholder="搜索会员"
                    optionFilterProp="children"
                    onChange={this.onMemberChange}
                    // onFocus={onFocus}
                    // onBlur={onBlur}
                    // onSearch={onSearch}
                    // filterOption={(input, option) => {
                    //     console.log(option);
                    //     return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    // }}
                >
                    {students.map(item => <Select.Option
                        value={item.id}>{item.nick_name} {item.mobile_number} {item.py}</Select.Option>)}

                </Select>
                <Table
                    dataSource={list}
                    rowKey={"user_id"}
                    pagination={false}
                    size={"small"}
                    bordered
                >
                    <Column
                        title="姓名"
                        dataIndex="nick_name"
                        key="姓名"
                        width={60}
                    />
                    <Column
                        title="卡片"
                        dataIndex="card_id"
                        key="卡片"
                        render={(card_id, record) => {
                            if (record.cards.length === 0) {
                                return <span style={{color: "red"}}>无会员卡</span>
                            }
                            return <Select
                                value={card_id}
                                onChange={(id) => this.onCardChange(record, id)}
                            >
                                {record.cards.map(card => <Select.Option
                                    value={card.id}>{card.name} (余{card.keshi_remaining+card.keshi_free_remaining})</Select.Option>)}
                            </Select>
                        }}
                    />
                    <Column
                        title="电话"
                        dataIndex="mobile_number"
                        key="电话"
                        render={(mobile_number, record) => {
                            return <Input
                                value={mobile_number}
                                onChange={(e) => {
                                    record.mobile_number = e.target.value;
                                    this.setState({}, this.handleChange);
                                }}
                            />
                        }}
                    />
                    <Column
                        title="车牌"
                        dataIndex="license_plate"
                        key="车牌"
                        render={(license_plate, record) => {
                            return <Input
                                value={license_plate}
                                onChange={(e) => {
                                    record.license_plate = e.target.value;
                                    this.setState({}, this.handleChange);
                                }}
                            />
                        }}
                    />
                    <Column
                        title="操作"
                        key="操作"
                        width={50}
                        render={(record) => {
                            return (<a onClick={() => this.onMemberRemove(record.user_id)}>删除</a>)
                        }}
                    />
                </Table>

            </div>
        )
    }
}

MemberCardSelecter.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
};

const mapStateToProps = state => ({
    students: state.students,
    users_cards: state.users_cards
});

const mapDispatchToProps = dispatch => ({});


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MemberCardSelecter);


