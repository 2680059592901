/**
 * @name: 日历，这里不要做的太复杂
 * @description:
 * @author: Wood
 * @date: 2020/2/11
 * @update:
 */
import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import '@ant-design/compatible/assets/index.css';
import {
    Button,
    Popover,
    Drawer,
    Calendar,
    Select,
    Row,
    Col,
    Typography,
    Radio,
    Space,
    Menu,
    Tabs,
    message,
    Badge,
    Checkbox,
    Tag,
    Popconfirm,
    Modal
} from 'antd';
import moment from 'moment';
import {getCalendar} from "../../lib/api_calendar";
import FormSanke from './form-sanke2';
import FormQianZai from './form-qianzai2';
import FormHuiYuan from './form-huiyuan2';
import FormXiaoZu from './form-xiaozu';
import {mergeRange, subtractRanges} from '../../lib/time-util';
import EventCalendar from '../../containers/test/event-calendar';
import YuyueDetail from './yuyue-detail';
import {
    CalendarOutlined,
    UserOutlined,
    CrownOutlined,
    FieldNumberOutlined,
    CheckCircleOutlined,
    ExclamationCircleOutlined,
} from '@ant-design/icons';
import styles from './calendar3.module.css'
import YuyueTypeName from "../../components/yuyue_type_name";
import {
    postYuYueTongBao,
    putYuYueDrag,
    postYuYueShangma,
    postYuYueWancheng,
    postYuYueQuxiao,
    postYuYueDelete,
    postYuYueQuXiaoShangma,
    postYuYue
} from "../../lib/api_yuyue";
import store from 'store';

const {confirm} = Modal;

class CalendarList extends Component {

    state = {
        visible: false,
        begin_date: moment(moment().format("YYYY-MM-DD")).weekday(0),
        end_date: moment(moment().format("YYYY-MM-DD")).weekday(6).add(1, "d"),
        current_date: moment(moment().format("YYYY-MM-DD")),
        step: 60,

        type: 1,
        duration: 15,
        begin_time: null,
        end_time: null,
        teacher: 0,

        yuyue: [],  // 预约情况
        paiban: [], // 排班情况
        staff: [],  // 教练
        license_plate_date: [],

        // 当前页面状态 0:关闭，1:详情，2：新建或修改
        status: 0,

        // 创建或修改时使用的数据
        selectedData: null,
        createItem: {
            yuyue_type: 0
        },

        editItem: null,

        // 新预约，用于可视化
        yuyue_new: null,

        // 当前日期
        date: moment().format("YYYY-MM-DD"),

        paiban_staff: [],
        width: 60,

        data: {
            type: 1,    // 课程类型
            duration: 15,   // 时长
            begin_time: null,   // 开始时间
            end_time: null, // 结束时间
            teacher: 0,     // 教练
            user_type: 0,
            yuyue_type: 0,
            nick_name: "",
            mobile_number: "",
            license_plate: "",
            remark: "",
        },
        columns: [],
        eventOffset: [0, 0], // 事件的位置
        showMenu: false,
        menu_new: null,  // 新建的菜单

        showUnPaiBan: store.get("showUnPaiBan", false), // 显示未排班教练
        show08: store.get("show08", false),  // 是否显示0-8点
        showgift: store.get("showgift", true), // 显示送课标记

        copy: null, // 复制的记录
    };

    componentDidMount() {
        this.fetchData();
        console.log(this.props.history.location.state)
        // window.addEventListener('DOMMouseScroll', this.wheel, false);
    }

    // wheel = (event) => {
    //     var delta = 0;
    //     console.log(delta)
    //     if (!event) event = window.event;
    //     if (event.wheelDelta) {//IE、chrome浏览器使用的是wheelDelta，并且值为“正负120”
    //         delta = event.wheelDelta / 120;
    //         if (window.opera) delta = -delta;//因为IE、chrome等向下滚动是负值，FF是正值，为了处理一致性，在此取反处理
    //     } else if (event.detail) {//FF浏览器使用的是detail,其值为“正负3”
    //         delta = -event.detail / 3;
    //     }
    //     console.log(delta)
    //     if (delta)
    //         if (delta < 0) {//向下滚动
    //
    //         } else {//向上滚动
    //
    //         }
    // };

    componentWillReceiveProps(props) {
    }

    // 获取5分钟后的时间，并取整5
    getTime = (minute) => {
        let now = moment(moment().format("YYYY-MM-DD HH:mm"));
        now.add(minute, "m");
        let m = (parseInt(now.minutes() / 5) + 1) * 5;
        now.minutes(m);
        return now;
    };


    // 加载当天的排班情况
    fetchData = () => {
        let {begin_date, end_date} = this.state;
        getCalendar(
            begin_date.format("YYYY-MM-DD"),
            end_date.format("YYYY-MM-DD")
        ).then(result => {
            if (result.code === 0) {
                this.setState({
                    staff: result.data.staff,   // 员工
                    yuyue: result.data.yuyue,   // 预约情况
                    paiban: result.data.paiban, // 排班情况
                    license_plate_date: result.data.license_plate_date,
                }, () => {
                    // this.recommendTeacher()
                    this.handleDateChange(this.state.date);
                    // this.jisuankuandu()
                    //
                    // if (this.props.history.location.state && this.props.history.location.state.user_id) {
                    //     this.showCreateForm(1, this.props.history.location.state.user_id)
                    //     this.props.history.location.state = null
                    // }
                })
            } else {

            }
        });
    };


    // 显示创建表单
    showCreateForm = (yuyue_type, user_id = null, teacher = null, begin_time = null, type = 6, duration = 15, id = 0) => {
        // console.log(begin_time);
        let {createItem} = this.state;
        createItem.yuyue_type = yuyue_type;
        createItem.teacher = parseInt(teacher);
        createItem.begin_time = begin_time;
        createItem.type = type;
        createItem.duration = duration;
        createItem.end_time = moment(begin_time).add(duration, "m").format("YYYY-MM-DD HH:mm");
        createItem.id = id;
        if(yuyue_type === 1){
            createItem.anshi = 1
        }
        console.log(createItem);
        // if (yuyue_type === 1) {
        //     if (user_id) {
        //         createItem.user_id = user_id
        //     } else {
        //         this.props.history.push("/members", {type: "select"})
        //     }
        //
        // }
        this.showEditForm(createItem)
        // this.setState({
        //     status: 2,
        //     selectedData: createItem,
        // })
    };
    hideCreateForm = () => {
        this.setState({
            status: 0,
            createItem: {
                yuyue_type: 0
            },
            yuyue_new: null
        }, () => {
            this.handleDateChange(this.state.date);
        })
    };

    handleShangma = (id) => {
        postYuYueShangma(id)
            .then(res => {
                if (res.code === 0) {
                    this.fetchData();
                } else {
                    message.error(res.message);
                }
            })
    };

    handleWancheng = (id) => {
        postYuYueWancheng(id)
            .then(res => {
                if (res.code === 0) {
                    this.fetchData();
                } else {
                    message.error(res.message);
                }
            })
    };

    handleQuXiao = (id) => {
        postYuYueQuxiao(id)
            .then(res => {
                if (res.code === 0) {
                    this.fetchData();
                } else {
                    message.error(res.message);
                }
            })
    };
    handleDelete = (id) => {
        postYuYueDelete(id)
            .then(res => {
                if (res.code === 0) {
                    this.fetchData();
                } else {
                    // message.error(res.message);
                }
            })
    };

    handleQuXiaoShangma = (id) => {
        confirm({
            title: '确定要取消上马吗？',
            icon: <ExclamationCircleOutlined/>,
            // content: '删除后数据无法恢复，请谨慎操作！',
            okText: '确定取消',
            okType: 'danger',
            cancelText: '不取消',
            onOk: () => {
                postYuYueQuXiaoShangma(id)
                    .then(res => {
                        if (res.code === 0) {
                            this.fetchData();
                        } else {
                            message.error(res.message);
                        }
                    })

            },
            onCancel() {
                console.log('Cancel');
            },
        });

    };

    handleCopy = (yuyue) => {
        this.setState({
            copy: yuyue
        }, () => {
            message.success("已复制")
        })
    };

    // 日期改变事件
    handleDateChange = (date) => {
        // .weekday(0)
        let {begin_date, end_date} = this.state;
        console.log(moment(date).format("YYYY-MM-DD"), begin_date.format("YYYY-MM-DD"), end_date.format("YYYY-MM-DD"));
        console.log(moment(date).isBetween(begin_date, end_date));
        if (moment(date).isBetween(begin_date, end_date, "d", "[)")) {
            console.log(111);
            let {staff, paiban, yuyue} = this.state;
            let columns = [];
            let columns2 = [];
            staff.forEach(staf => {
                let column = {
                    key: staf.id,
                    title: staf.name,
                    events: []
                };
                let nopaiban = true;
                // 排班情况
                paiban.filter(item => item.user_id === staf.id && moment(item.begin_time).isSame(date, 'day'))
                    .forEach(item => {
                        column.events.push({
                            begin_time: moment(item.begin_time).format("HH:mm"),
                            end_time: moment(item.end_time).format("HH:mm"),
                            text: "",
                            color: "green",
                            lock: true,
                            event_type: "paiban",
                            extra_time: 0
                        })
                    });
                if (column.events.length > 0) {
                    nopaiban = false;
                }

                // 预约情况
                yuyue.filter(item => (item.teacher === staf.id || (item.yuyue_type === 3 && item.teachers && JSON.parse(item.teachers).includes(staf.id))) && moment(item.begin_time).isSame(date, 'day'))
                    .forEach(item => {
                        let extra_time = 0;
                        // console.log(item.duration);
                        switch (item.duration) {
                            case 15:
                                extra_time = 5;
                                break;
                            case 30:
                                extra_time = 10;
                                break;
                            case 45:
                                extra_time = 10;
                                break;
                            case 90:
                                extra_time = 10;
                                break;
                            default:
                                extra_time = 0;
                                break;
                        }
                        let color = "";
                        switch (item.yuyue_type) {
                            case 0:
                                color = "blue";
                                break;
                            case 1:
                                color = "orange";
                                break;
                            case 2:
                                color = "pink";
                                break;
                            case 3:
                                color = "orange-dark";
                                break;
                            default:
                                color = "gray";
                                break;
                        }
                        let text_arr = [];
                        if (item.yuyue_type === 1 || item.yuyue_type === 3) {
                            if (item.members) {
                                let members = JSON.parse(item.members);
                                if (members) {
                                    members.forEach(member => {
                                        text_arr.push(<b>{member.nick_name}</b>);
                                        text_arr.push(',');
                                        let text_arr2 = [item.duration + "分", item.age, item.height ? item.height + "cm" : "", item.remark, member.mobile_number, member.license_plate];
                                        text_arr2 = text_arr2.filter(item => !!item);

                                        text_arr.push(text_arr2.join(','))
                                        text_arr.push(<br/>)
                                    })
                                }

                            }
                        } else {
                            text_arr.push(<b>{item.nick_name}</b>);
                            text_arr.push(',');
                            let text_arr2 = [item.duration + "分", item.age, item.height ? item.height + "cm" : "", item.remark, item.mobile_number, item.license_plate];
                            text_arr2 = text_arr2.filter(item => !!item);

                            text_arr.push(text_arr2.join(','))
                        }


                        let menu = null;
                        if (item.status === 0) {
                            menu = <Menu style={{width: 100}}>
                                <Menu.Item key="1" onClick={() => this.handleShangma(item.id)}>已上马</Menu.Item>
                                <Menu.Item key="2" onClick={() => this.handleQuXiao(item.id)} style={{color: "red"}}>取消预约</Menu.Item>
                                {(item.yuyue_type === 1 || item.yuyue_type === 3) && <Menu.Item key="5" onClick={() => {
                                    let data = {...item};
                                    data.yuyue_type = 3;
                                    if (!data.members) {
                                        data.members = JSON.stringify([{
                                            user_id: data.user_id,
                                            card_id: data.card_id,
                                            mobile_number: data.mobile_number,
                                            license_plate: data.license_plate,
                                        }])

                                    }
                                    this.showEditForm(data)
                                }}>增加学员</Menu.Item>}
                                {/*<Menu.Item key="3" >删除</Menu.Item>*/}
                                <Menu.Divider/>
                                {([0, 1, 2].some(id=> id ===item.yuyue_type)) &&
                                   <Menu.Item key="4" onClick={() => this.handleCopy(item)}>复制</Menu.Item>
                                }
                                {/*<Menu.Item key="5">粘贴</Menu.Item>*/}
                            </Menu>
                        }
                        if (item.status === 1) {
                            menu = <Menu style={{width: 100}}>
                                <Menu.Item key="1" onClick={() => this.handleWancheng(item.id)}>下课</Menu.Item>
                                <Menu.Item key="2" onClick={() => this.handleQuXiaoShangma(item.id)}
                                           style={{color: "red"}}>取消上马</Menu.Item>
                                {(item.yuyue_type === 1 || item.yuyue_type === 3) &&
                                <Menu.Item key="2">增加学员</Menu.Item>}
                                {/*<Menu.Item key="3" >删除</Menu.Item>*/}
                                <Menu.Divider/>
                                {([0, 1, 2].some(id=> id ===item.yuyue_type)) &&
                                <Menu.Item key="4" onClick={() => this.handleCopy(item)}>复制</Menu.Item>
                                }
                                {/*<Menu.Item key="5">粘贴</Menu.Item>*/}
                            </Menu>
                        }
                        if (item.status === 2) {
                            menu = <Menu style={{width: 100}}>
                                <Menu.Item key="2" onClick={() => this.handleDelete(item.id)}
                                           style={{color: "red"}}>删除</Menu.Item>
                                {/*<Menu.Item key="3" >删除</Menu.Item>*/}
                                <Menu.Divider/>
                                {([0, 1, 2].some(id=> id ===item.yuyue_type)) &&
                                <Menu.Item key="4" onClick={() => this.handleCopy(item)}>复制</Menu.Item>
                                }
                                {/*<Menu.Item key="5">粘贴</Menu.Item>*/}
                            </Menu>
                        }

                        column.events.push({
                            begin_time: moment(item.begin_time).format("HH:mm"),
                            end_time: moment(item.end_time).format("HH:mm"),
                            text: <div className={styles.eventContent}>
                                <div
                                    className={styles[`state${item.status}`]}>{moment(item.begin_time).format("H:mm")}</div>
                                <span>
                                    {item.user_type === "一般" && "[散]"}
                                    {item.user_type === "房客" && "[房]"}
                                    {item.user_type === "名宿" && "[宿]"}
                                    {item.user_type === "款待" && "[V]"}
                                    {item.user_type === "赠券" && this.state.showgift &&
                                    <span style={{color: "red"}}>[券]</span>}
                                    {item.user_type === "其他" && "[其他]"}

                                    {text_arr}
                                </span>
                            </div>,
                            color: color,
                            lock: item.status === 2,
                            event_type: "yuyue",
                            data: item,
                            extra_time: extra_time,
                            menu: menu
                        })
                    });

                if (nopaiban) {
                    columns2.push(column)
                } else {
                    columns.push(column)
                }
            });

            if (this.state.showUnPaiBan) {
                columns.push(...columns2);

            }

            console.log(columns)
            this.setState({
                columns: []
            }, () => {
                this.setState({
                    date: moment(date).format("YYYY-MM-DD"),
                    columns: columns
                })
            });

        } else {
            this.setState({
                date: moment(date).format("YYYY-MM-DD"),
                begin_date: moment(date).weekday(0),
                end_date: moment(date).weekday(6).add(1, "d"),
            }, () => {
                this.fetchData()
            })
        }

    };

    // 激活单元格改变事件
    handleActiveCellChange = (model) => {
        console.log(model);
        let createItem = this.state.createItem;
        createItem.begin_time = model.begin_time;
        createItem.teacher = model.user_id;
        this.setState({
            createItem: createItem
        })
    };

    handleActiveYuYueChange = (model) => {
        console.log("aaaaaaaa", model);
        this.setState({
            status: 2,
            editItem: model
        })
    };

    hideEditForm = () => {
        this.setState({
            status: 0,
            editItem: null,
            yuyue_new: null
        })
    };

    // 计算宽度
    jisuankuandu = () => {
        let {staff, paiban} = this.state;
        // 筛选出已排课人员
        let paiban_staff = [];
        staff.forEach(staf => {
            if (!staf.courses_type) return;
            let courses_type = JSON.parse(staf.courses_type);
            if (!courses_type) return;
            let staf_paiban = paiban.filter(item => item.user_id === staf.id);
            if (staf_paiban.length === 0) return;
            paiban_staff.push(staf);
        });
        // 计算显示宽度
        // let width = (this.props.size.width - 60) / (paiban_staff.length)
        this.setState({
            paiban_staff,
            // width
        })

    };

    handlePaibanClick = (e) => {
        console.log("handlePaibanClick", e);

        let time = this.getMinTime(e, this.state.yuyue);
        console.log(time);
        this.showCreateForm(0, 0, e.user_id, time);
    };

    // 获得时间范围
    getTimeRanges = (timeRanges, notInRanges) => {
        for (let i in timeRanges) {
            let tr = timeRanges[i];

        }
    };

    // 获取最早的空余时间
    getMinTime = (paiban, yuyue) => {
        let now = moment();
        let minTime = paiban.begin_time;

        // 超出当前时间范围
        if (now.isSameOrAfter(paiban.end_time)) {
            return null
        }
        if (now.isAfter(paiban.begin_time)) {
            minTime = now.format("YYYY-MM-DD HH:mm:ss")
        }

        // 获得当前时间段的空余时间
        let times = subtractRanges(
            [{
                begin_time: minTime,
                end_time: paiban.end_time
            }]
            , this.state.yuyue.filter(item => item.teacher === paiban.user_id));
        if (times.length === 0) {
            return null
        } else {
            return times[0].begin_time;
        }
    };

    showCreateMenu = (column, time, offset, yuyue_type) => {
        this.showCreateForm(yuyue_type, 0, column.key, time.format("YYYY-MM-DD HH:mm"))
        // this.setState({
        //     menu_new: null
        // }, () => {
        //     if (column && time) {
        //         this.setState({
        //             menu_new: {
        //                 key: column.key,
        //                 time: time.format("YYYY-MM-DD HH:mm"),
        //                 nick_name: column.title,
        //                 x: offset[0] + 160,
        //                 y: offset[1] + 30 + 60
        //             }
        //         })
        //     }
        // });
    };

    showCreateMark = (column, time, offset) => {
        this.setState({
            mark_new: null
        }, () => {
            if (column && time) {
                this.setState({
                    mark_new: {
                        key: column.key,
                        time: time.format("YYYY-MM-DD HH:mm"),
                        nick_name: column.title,
                        x: offset[0] + 160,
                        y: offset[1] + 30 + 60
                    }
                })
            }
        });
    }

    // 表格空白区域点击
    handleTableClick = (e) => {
        if (this.state.status === 0) {
            this.showCreateMenu(e.column, e.time, e.offset, e.yuyue_type);

        }
    };

    // 粘贴操作
    handlePaste = (e) => {
        let {copy} = this.state;
        if (!copy) {
            message.error("请先复制");
        } else {
            let data = {...copy};
            data.id = 0;
            data.begin_time = e.time.format("YYYY-MM-DD HH:mm");
            data.end_time = moment(data.begin_time).add(data.duration, "m").format("YYYY-MM-DD HH:mm");
            data.teacher = parseInt(e.column.key);
            data.course_type = data.type;

            console.log(data);
            postYuYue(data)
            // postMembers(values)
                .then(result => {
                    // console.log(result);
                    // 刷新数据
                    if (result.code === 0) {
                        this.fetchData()
                    }
                    // this.handleOK();
                })
        }
    };

    // 表格事件点击
    handleEventClick = (e) => {
        console.log(e);
        if (e.event.event_type === "paiban") {
            if (!e.ismove) {
                this.showCreateMenu(e.column, e.time, e.offset);
            }
        } else if (e.event.event_type === "yuyue") {
            console.log(e.event);
            let data = {...e.event.data};
            if (e.ismove === true) {
                data.begin_time = e.time.format("YYYY-MM-DD HH:mm");
                data.teacher = parseInt(e.newcolumn.key)
                console.log(data)
                this.showEditForm(data);
            } else {
                this.showDetail(data);
            }
            // this.setState({
            //     menu_new: null,
            //     status: 2,
            //     selectedData: data,
            // })
            // this.showCreateForm(0, 0, e.event.teacher, e.event.yuyue_begin_time, e.event.type, e.event.duration, e.event.id)
        }
    };

    handleEventDragEnd = (e) => {
        if (e.event.event_type === "yuyue") {
            console.log(e.event);
            let data = {...e.event.data};
            if (e.ismove === true) {
                data.begin_time = e.time.format("YYYY-MM-DD HH:mm");
                data.teacher = parseInt(e.newcolumn.key);
                data.end_time = moment(data.begin_time).add(data.duration, "m").format("YYYY-MM-DD HH:mm");
                console.log(data);
                putYuYueDrag(data)
                    .then(res => {
                        if (res.code === 0) {
                            this.fetchData()
                        } else {
                            // message.error(res.message);
                            this.fetchData()
                        }
                    })
                // this.showEditForm(data);
            } else {
                // this.showDetail(data);
            }
        }
    };

    // 显示详情
    showDetail = (data) => {
        this.setState({
            menu_new: null,
            status: 1,
            selectedData: data
        })
    };
    // 隐藏详情
    hideDetail = () => {
        this.setState({
            status: 0,
            selectedData: null
        })
    };
    // 显示编辑界面
    showEditForm = (data) => {
        this.setState({
            menu_new: null,
            status: 2,
            selectedData: data
        })
    };

    // 显示车牌通报
    showTongBao = () => {
        this.setState({
            menu_new: null,
            status: 3,
        })
    };

    tongBao = (date, ids) => {
        console.log(ids);
        postYuYueTongBao(date, ids)
            .then(res => {
                if (res.code === 0) {
                    message.success("通报成功");
                }
                this.fetchData();
            })
    }

    render_license_plates = (tongbao) => {
        let {date, yuyue} = this.state;
        let list = yuyue.filter(item => moment(item.begin_time).isSame(date, 'day'));
        // console.log(list);
        return list.filter(item => item.tongbao === tongbao);
    };


    render() {
        let {
            paiban, staff, yuyue, status, createItem, date, yuyue_new, step, paiban_staff, width, editItem,
            columns, eventOffset, showMenu, menuTitle, menu_new, mark_new,
            selectedData, license_plate_date
        } = this.state;
        let dates = [];
        let begin = moment(date).add(-3, "d");
        for (let i = 0; i < 21; i++) {
            dates.push(moment(begin).add(i, "d"));
        }

        let weitongbao = [];
        let yitongbao = [];

        let license_plate_config = license_plate_date.find(item => moment(item.license_plate_date).isSame(date, "day"));
        if (license_plate_config && license_plate_config.config) {
            yitongbao = JSON.parse(license_plate_config.config);
        }
        // 计算未通报
        let list = yuyue.filter(item => moment(item.begin_time).isSame(date, 'day') && item.license_plate);
        let license_plates = Array.from(new Set(list.map(item => item.license_plate)));
        weitongbao = license_plates.filter(item => !yitongbao.includes(item));

        // let weitongbao = this.render_license_plates(0);
        // let yitongbao = this.render_license_plates(1);
        return (
            <div className={styles.container}>
                <div className={styles.eventcalendar}>
                    <div className={styles.dateMenu}>
                        <Menu mode="horizontal"
                              selectedKeys={[date]}
                              onSelect={(item) => {
                                  this.handleDateChange(item.key)
                              }}>
                            {dates.map(item =>
                                <Menu.Item key={item.format("YYYY-MM-DD")}
                                           className={item.isSame(moment(), 'd') ? styles.today : ""}>
                                    <div>
                                        {item.format("ddd")}
                                    </div>
                                    <div>
                                        {item.format('M/D')}
                                    </div>
                                </Menu.Item>
                            )}
                            <Menu.Item key={moment().format("YYYY-MM-DD ")}
                                       className={""}>
                                <div>
                                    回到今天
                                </div>
                                {/*<div>*/}
                                {/*{moment().format('M/D')}*/}
                                {/*</div>*/}
                            </Menu.Item>

                        </Menu>

                    </div>
                    <EventCalendar columns={columns}
                                   date={date}
                                   onClick={this.handleTableClick}
                                   onEventClick={this.handleEventClick}
                                   onEventDragEnd={this.handleEventDragEnd}
                                   onPaste={this.handlePaste}
                                   cell_width={100}
                                   show08={this.state.show08}
                        // menu={<Menu>
                        //     <Menu.Item key="1" onClick={() => this.showCreateForm(0, 0, menu_new.key, menu_new.time)}>散客预约</Menu.Item>
                        //     <Menu.Item key="2" onClick={() => this.showCreateForm(1, 0, menu_new.key, menu_new.time)}>会员预约</Menu.Item>
                        //     <Menu.Item key="3" onClick={() => this.showCreateForm(2, 0, menu_new.key, menu_new.time)}>潜在预约</Menu.Item>
                        // </Menu>}
                    />
                </div>
                {/*菜单*/}
                <div className={styles.menuContainer}>
                    <Menu theme="light"
                        // mode="inline"
                          selectedKeys={[]}
                        // defaultSelectedKeys={[props.defaultSelectedKeys]}
                          className={styles.menu}
                          style={{
                              flex: 1
                          }}
                    >
                        {/*<Menu.Item key="/calendar">*/}
                        {/*<Link to='/calendar'>*/}
                        {/*<CalendarOutlined/>*/}
                        {/*<span>排课日历</span>*/}
                        {/*</Link>*/}
                        {/*</Menu.Item>*/}

                        <Menu.Item key="日期">
                            <Popover
                                placement="leftTop"
                                content={
                                    <div style={{
                                        // position: "absolute",
                                        // top: 100,
                                        // right: 100,
                                        width: 260,
                                        // border: "1px solid #f0f0f0"
                                    }}>
                                        <Calendar
                                            value={moment(date)}
                                            fullscreen={false}
                                            onChange={(value) => this.handleDateChange(value)}
                                            headerRender={({value, type, onChange, onTypeChange}) => {
                                                const start = 0;
                                                const end = 12;
                                                const monthOptions = [];

                                                const current = value.clone();
                                                const localeData = value.localeData();
                                                const months = [];
                                                for (let i = 0; i < 12; i++) {
                                                    current.month(i);
                                                    months.push(localeData.monthsShort(current));
                                                }

                                                for (let index = start; index < end; index++) {
                                                    monthOptions.push(
                                                        <Select.Option className="month-item" key={`${index}`}>
                                                            {months[index]}
                                                        </Select.Option>,
                                                    );
                                                }
                                                const month = value.month();

                                                const year = value.year();
                                                const options = [];
                                                for (let i = year - 10; i < year + 10; i += 1) {
                                                    options.push(
                                                        <Select.Option key={i} value={i} className="year-item">
                                                            {i}
                                                        </Select.Option>,
                                                    );
                                                }
                                                return (
                                                    <div style={{padding: 8}}>
                                                        {/*<Typography.Title level={4}>*/}
                                                        {/*Custom header*/}
                                                        {/*</Typography.Title>*/}
                                                        <Row gutter={8}>

                                                            <Col>
                                                                <Select
                                                                    size="small"
                                                                    dropdownMatchSelectWidth={false}
                                                                    className="my-year-select"
                                                                    onChange={newYear => {
                                                                        const now = value.clone().year(newYear);
                                                                        onChange(now);
                                                                    }}
                                                                    value={String(year)}
                                                                >
                                                                    {options}
                                                                </Select>
                                                            </Col>
                                                            <Col>
                                                                <Select
                                                                    size="small"
                                                                    dropdownMatchSelectWidth={false}
                                                                    value={String(month)}
                                                                    onChange={selectedMonth => {
                                                                        const newValue = value.clone();
                                                                        newValue.month(parseInt(selectedMonth, 10));
                                                                        onChange(newValue);
                                                                    }}
                                                                >
                                                                    {monthOptions}
                                                                </Select>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                );
                                            }}
                                        />
                                    </div>} title="日期选择">
                                <CalendarOutlined/>
                                <span>日期</span>
                            </Popover>
                        </Menu.Item>
                        <Menu.Item key="散客预约"
                                   onClick={() => this.showCreateForm(0, 0, 0, moment().format("YYYY-MM-DD HH:mm"))}>
                            <UserOutlined/>
                            <span>散客预约</span>
                        </Menu.Item>
                        <Menu.Item key="会员预约"
                                   onClick={() => this.showCreateForm(1, 0, 0, moment().format("YYYY-MM-DD HH:mm"))}>
                            <CrownOutlined/>
                            <span>会员预约</span>
                        </Menu.Item>
                        <Menu.Item key="潜在预约"
                                   onClick={() => this.showCreateForm(2, 0, 0, moment().format("YYYY-MM-DD HH:mm"))}>
                            <UserOutlined/>
                            <span>潜在预约</span>
                        </Menu.Item>
                        <Menu.Item key="车牌通报"
                                   onClick={() => this.showTongBao()}>
                            <Badge count={weitongbao.length}>
                                <FieldNumberOutlined/>
                                <span>车牌通报</span>
                            </Badge>
                        </Menu.Item>
                        <Menu.Item key="课时确认">
                            <Link to={`/queren`}>
                                <CheckCircleOutlined/>
                                <span>课时确认</span>
                            </Link>
                        </Menu.Item>

                    </Menu>
                    <div style={{textAlign: "left"}}>
                        <div>
                            <Checkbox checked={this.state.show08} onChange={(e) => {
                                store.set("show08", e.target.checked);
                                this.state.show08 = e.target.checked;
                                this.handleDateChange(this.state.date);
                            }}>0-8点</Checkbox>
                        </div>
                        <div>
                            <Checkbox checked={this.state.showgift} onChange={(e) => {
                                store.set("showgift", e.target.checked);
                                this.state.showgift = e.target.checked;
                                this.handleDateChange(this.state.date);
                            }}>送课</Checkbox>
                        </div>
                        <div>
                            <Checkbox checked={this.state.showUnPaiBan} onChange={(e) => {
                                store.set("showUnPaiBan", e.target.checked);
                                this.state.showUnPaiBan = e.target.checked;
                                this.handleDateChange(this.state.date);
                            }}>未出勤</Checkbox>
                        </div>
                    </div>
                </div>
                {menu_new &&
                <Popover
                    content={<div style={{width: 150}}>
                        <Button block
                                onClick={() => this.showCreateForm(0, 0, menu_new.key, menu_new.time)}
                        >散客预约</Button>
                        <Button block
                                onClick={() => this.showCreateForm(1, 0, menu_new.key, menu_new.time)}
                        >会员预约</Button>
                        <Button block
                                onClick={() => this.showCreateForm(2, 0, menu_new.key, menu_new.time)}
                        >潜在预约</Button>
                    </div>}

                    title={`${menu_new.nick_name} ${moment(menu_new.time).format("HH:mm")}`}
                    placement="bottom"
                    defaultVisible={true}
                    // visible={!!menu_new}
                >
                    <span style={{position: "absolute", top: menu_new.y, left: menu_new.x, width: 100}}/>
                </Popover>
                }
                {/*{mark_new &&*/}
                {/*<span style={{position: "absolute", top: mark_new.y, left: mark_new.x, width: 100, height:100, backgroundColor:"red"}}/>*/}
                {/*}*/}

                <Drawer
                    title="预约详情"
                    width={500}
                    onClose={this.hideCreateForm}
                    visible={status === 1}
                    // visible={true}
                    destroyOnClose
                >
                    <YuyueDetail
                        data={selectedData}
                        onEdit={() => this.showEditForm(selectedData)}
                        onOk={() => {
                            this.fetchData();
                        }}
                        onClose={() => {
                            this.fetchData();
                            this.hideCreateForm();
                        }}
                    />
                </Drawer>
                <Drawer
                    title={<span><YuyueTypeName id={selectedData ? selectedData.yuyue_type : 0}/>预约</span>}
                    width={700}
                    onClose={this.hideCreateForm}
                    visible={status === 2}
                    destroyOnClose
                    // bodyStyle={{ paddingBottom: 80 }}
                    // footer={
                    //     <div
                    //         style={{
                    //             textAlign: 'right',
                    //         }}
                    //     >
                    //         <Button onClick={this.onClose} style={{marginRight: 8}}>
                    //             Cancel
                    //         </Button>
                    //         <Button onClick={this.onClose} type="primary" size={"large"}>
                    //             Submit
                    //         </Button>
                    //     </div>
                    // }
                >
                    {selectedData && selectedData.yuyue_type === 0 &&
                    <FormSanke paiban={paiban}
                               yuyue={yuyue}
                               staff={staff}
                        // date={date}
                        // begin_time={this.state.createItem.begin_time}
                        // teacher={this.state.createItem.teacher}
                               data={selectedData}
                               onChange={(data) => this.setState({
                                   yuyue_new: {...data}
                               })}
                               onOk={() => {
                                   this.fetchData();
                                   this.hideCreateForm()
                               }}
                               onClose={this.hideCreateForm}
                    />}
                    {selectedData && selectedData.yuyue_type === 1 &&
                    <FormHuiYuan paiban={paiban}
                                 yuyue={yuyue}
                                 staff={staff}
                        // date={date}
                        // begin_time={this.state.createItem.begin_time}
                        // teacher={this.state.createItem.teacher}
                                 data={selectedData}
                                 onChange={(data) => this.setState({
                                     yuyue_new: {...data}
                                 })}
                                 onOk={() => {
                                     this.fetchData();
                                     this.hideCreateForm()
                                 }}
                                 onClose={this.hideCreateForm}
                    />
                    }
                    {selectedData && selectedData.yuyue_type === 3 &&
                    <FormXiaoZu paiban={paiban}
                                yuyue={yuyue}
                                staff={staff}
                        // date={date}
                        // begin_time={this.state.createItem.begin_time}
                        // teacher={this.state.createItem.teacher}
                                data={selectedData}
                                onChange={(data) => this.setState({
                                    yuyue_new: {...data}
                                })}
                                onOk={() => {
                                    this.fetchData();
                                    this.hideCreateForm()
                                }}
                                onClose={this.hideCreateForm}
                    />
                    }
                    {selectedData && selectedData.yuyue_type === 2 &&
                    <FormQianZai paiban={paiban}
                                 yuyue={yuyue}
                                 staff={staff}
                        // date={date}
                        // begin_time={this.state.createItem.begin_time}
                        // teacher={this.state.createItem.teacher}
                                 data={selectedData}
                                 onChange={(data) => this.setState({
                                     yuyue_new: {...data}
                                 })}
                                 onOk={() => {
                                     this.fetchData();
                                     this.hideCreateForm()
                                 }}
                                 onClose={this.hideCreateForm}
                    />
                    }
                </Drawer>
                <Drawer
                    title="车牌通报"
                    width={300}
                    onClose={this.hideCreateForm}
                    visible={status === 3}
                    // visible={true}
                    destroyOnClose
                >

                    <Tabs defaultActiveKey="1">
                        <Tabs.TabPane tab="未通报" key="1">
                            {weitongbao.map(item => {
                                return <div>{item}</div>
                            })}
                            <Button onClick={() => this.tongBao(date, weitongbao)}
                                    disabled={weitongbao.length <= 0}>全部已通报</Button>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="已通报" key="2">
                            {yitongbao.map(item => {
                                return <div>{item}</div>
                            })}
                        </Tabs.TabPane>
                    </Tabs>
                </Drawer>

            </div>
        )
    }
}

const mapStateToProps = state => ({
    school: state.school,
});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(CalendarList));

