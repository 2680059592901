/**
 * @name: 休眠功能
 * @description:
 * @author: Wood
 * @date: 2021/9/24
 * @update:
 */
import PropTypes from 'prop-types';
import React, {useState, useEffect} from 'react';
import {Modal, Form, InputNumber, Input, DatePicker, message} from 'antd';
import {postMembershipXuFei} from "../../../lib/api_membership";
import moment from 'moment';

const MembershipXiuMianModal = ({data, visible, onOk, onClose}) => {
    const [form] = Form.useForm();
    const [type_id, setTypeID] = useState(0);
    const [type, settype] = useState(null);
    const [begindate, setbegindate] = useState(moment()); // 开始日期
    const [enddate, setenddate] = useState(moment()); // 结束日期
    const [days, setdays] = useState(0);
    const [help, sethelp] = useState("");


    const handleSubmit = () => {
        // form.validateFields()
        //     .then(values => {
        //         values.id = data.id;
        //         console.log(values);
        //         postMembershipXuFei(values)
        //             .then(res => {
        //                 if (res.code === 0) {
        //                     onOk && onOk(res.data);
        //                 }
        //             })
        //     })
    };

    // 设置不可用开始日期
    const disabledDate = (current) => {
        return current && (current.isBefore(begindate))
    };

    // 设置不可用结束日期
    const disabledEndDate = (current)=>{
        return current && (current.isBefore(begindate))
    };

    // 表单值发生改变
    const handleValuesChange = (changedValues, allValues)=>{
        // 计算休卡时长
        let {begin_time, end_time} = allValues;
        if(begin_time && end_time){
            let days = end_time.diff(begin_time, "d");
            if(days<=0){
                sethelp("结束日期不能早于起始日期")
            }else{
                sethelp(`共计休卡${days}天`)
            }
        }else{
            sethelp("")
        }
    };

    useEffect(() => {
        // 计算休卡的时间范围
        let now = moment().startOf("d"); // 今天日期
        let nextmonth = moment().startOf("M").add(1, "M"); // 下个月的起始日期
        if (nextmonth.isSameOrAfter(data.end_time)) {
            message.error("会籍时长不足，无法休眠");
            return;
        }
        setbegindate(nextmonth);
        setenddate(moment(nextmonth).add(3, "M"))
        form.setFieldsValue({
            begin_time: nextmonth
        })
    }, []);

    const formItemLayout = {
        labelCol: {
            xs: {span: 24},
            sm: {span: 6},
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 18},
        },
    };

    return (
        <Modal
            title="休眠"
            visible={true}
            // onOk={form.submit}
            onOk={handleSubmit}
            onCancel={onClose}
            destroyOnClose={true}
            width={800}
        >
            <Form {...formItemLayout}
                  form={form}
                // onSubmit={this.handleSubmit}
                  onValuesChange={handleValuesChange}
                  initialValues={{
                      // begin_time: begindate,
                      // begin_time: moment(data.end_time).add(1, "d")
                  }}
                // ref={this.formRef}
            >

                <Form.Item
                    label="开始日期"
                    name="begin_time"
                    rules={[
                        {
                            required: true,
                            message: '请选择开始日期',
                        },
                    ]}
                >
                    <DatePicker
                        format={"YYYY-MM-DD"}
                        disabledDate={disabledDate}
                    />
                </Form.Item>
                <Form.Item
                    label="结束日期"
                    name="end_time"
                    rules={[
                        {
                            required: true,
                            message: '请选择结束日期',
                        },
                    ]}
                    help={help}
                >
                    <DatePicker
                        format={"YYYY-MM-DD"}
                        disabledDate={disabledEndDate}
                    />
                </Form.Item>
            </Form>
        </Modal>
    )
};

MembershipXiuMianModal.propTypes = {
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
    onOk: PropTypes.func,
};

export default MembershipXiuMianModal;
