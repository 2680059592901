
import axios from './rest-axios';

const getUserCardInfo = (id) => axios.get(`/v2/users_cards/${id}`);
const postUserCardAddTime = (id, days) => axios.post(`/v1/users_cards/${id}/addtime`, {days});
const postUserCardAddKeShi = (id, model) => axios.post(`/v1/users_cards/${id}/addkeshi`, model);
const postUserCardAddFee = (id, model) => axios.post(`/v1/users_cards/${id}/addfee`, model);
const putUserCardStatus = (id, model) => axios.put(`/v2/users_cards/${id}/status`, model);
const postUserCardPayments = (id, model) => axios.post(`/v1/users_cards/${id}/set_payments`, model);


export {
    getUserCardInfo,
    postUserCardAddTime,
    postUserCardAddKeShi,
    postUserCardAddFee,
    putUserCardStatus,
    postUserCardPayments
};
