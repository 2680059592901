import React, {useState} from 'react';
import {Card, Space, Button} from 'antd';
import PageContent from '../../../components/page-content/page-content';
import DataTable from '../../../components/data/data-table';
import RoomAddModal from './store-add-modal';
import RoomEditModal from './store-edit-modal';
import {useTable} from 'table-render';
import {PlusOutlined} from '@ant-design/icons';

const WXAccessToken = ({match}) => {
    const [visible, setVisible] = useState(false);
    const [editdata, setEditData] = useState(null);
    const {refresh} = useTable();

    console.log(match.params.type_id);
    return (
        <PageContent title={"门店管理"}>
            <Card bordered={false}>

                <DataTable
                    tableName={"stores"}
                    pageQuery={match.params}
                    table={{
                        pagination: false,
                        size: "small",
                        columns: [{
                            title: 'id',
                            dataIndex: 'id',
                        }, {
                            title: '名称',
                            dataIndex: 'name',
                        }, {
                            title: '编号',
                            dataIndex: 'number',
                        }, {
                            title: '操作',
                            fixed: 'right',
                            width: 150,
                            render: (record) => (<Space>
                                <a target="_blank" key="1">
                                    <div
                                        onClick={() => {
                                            setEditData(record);
                                        }}
                                    >
                                        修改
                                    </div>
                                </a>
                            </Space>)
                        }],
                        toolbar:[
                            <Button
                                type="primary"
                                icon={<PlusOutlined />}
                                onClick={() => setVisible(true)}
                            >添加</Button>
                        ]
                    }}
                />
            </Card>
            <RoomAddModal
                visible={visible}
                onOk={() => {
                    setVisible(false);
                    refresh()
                }}
                onCancel={() => {
                    setVisible(false)
                }}
                {...match.params}
            />
            {editdata &&
            <RoomEditModal
                visible={!!editdata}
                data={editdata}
                onOk={() => {
                    setEditData(null);
                    refresh()
                }}
                onCancel={() => {
                    setEditData(null);
                }}
                {...match.params}
            />
            }
        </PageContent>
    )
};


export default WXAccessToken;


