import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {postCoursesType} from "../../lib/api_courses_type";
import {Form} from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {Modal, Input, Table, Radio, Card, Row, Col, Tag} from 'antd';
import {getCards} from "../../lib/api_cards";
import {postXuKa} from "../../lib/api_members";
import moment from "moment";
import CardAddModal from './card-add-modal';

const {Column} = Table;
const gridStyle = {
    width: '50%',
    height: 140,
    cursor: "pointer",
    // textAlign: 'center',
};

class MembersCardAddModal extends Component {

    state = {
        list: [],
        selected_id: 0
    };

    componentDidMount() {
        this.loadData();
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    loadData = () => {
        getCards()
            .then(res => {
                if (res.code === 0) {
                    this.setState({list: res.data})
                }
            })
    };

    handleClose = () => {
        this.props.onClose && this.props.onClose();
    };

    handleOK = () => {
        this.props.onOK && this.props.onOK();
    };

    handleSelect = (id) => {
        this.setState({
            selected_id: id
        })
    }

    handleSubmit = e => {
        e.preventDefault();

        postXuKa(this.props.user_id, this.state.selected_id)
            .then(result => {
                console.log(result);
                // 刷新数据
                this.handleOK();
            });

    };


    render() {
        const {list} = this.state;
        return (
            <Modal
                title="添加会员卡"
                visible={!!this.props.user_id}
                // onOk={this.handleSubmit}
                onCancel={this.handleClose}
                destroyOnClose={true}
                width={900}
                footer={false}
            >
                {/*<Row gutter={[16, 16]}>*/}
                {/*{list.map(card => <Col span={12}><Card>*/}
                {/*<Card.Meta*/}
                {/*title={<span><Tag>{card.card_type === 0 && "次卡"}{card.card_type === 1 && "VIP年卡"}</Tag>*/}
                {/*{card.name}</span>}*/}
                {/*description={<div>*/}

                {/*<span style={{marginRight:20}}>{card.keshi}{card.card_type === 0 && "课时"}{card.card_type === 1 && "教练费"}</span>*/}
                {/*<span style={{marginRight:20}}>*/}
                {/*{card.time_type === 0*/}
                {/*? <span>*/}
                {/*开卡后{card.limit_days}天内可使用*/}
                {/*</span>*/}
                {/*: <span>*/}
                {/*固定日期：到{moment(card.expired_date).format("YYYY-MM-DD")}之前可用*/}
                {/*</span>}*/}
                {/*</span>*/}
                {/*<span style={{marginRight:20}}>*/}
                {/*<span>*/}
                {/*{card.holiday_use === 1 && "周末"}*/}
                {/*{card.weekdays_use === 1 && "工作日"}*/}
                {/*</span>*/}
                {/*</span>*/}
                {/*<div>*/}
                {/*{card.courses.map(item => item.name).join()}*/}
                {/*</div>*/}
                {/*</div>}/>*/}
                {/*</Card></Col>)}*/}
                {/*</Row>*/}
                <Card>
                    {list.map(card => <Card.Grid style={gridStyle} onClick={() => this.setState({card: card})}>
                        <Card.Meta
                            title={<span><Tag>{card.card_type === 0 && "次卡"}{card.card_type === 1 && "VIP年卡"}</Tag>
                                {card.name}</span>}
                            description={<div>

                                <span
                                    style={{marginRight: 20}}>{card.keshi}{card.card_type === 0 && "课时"}{card.card_type === 1 && "教练费"}</span>
                                <span style={{marginRight: 20}}>
                                    {card.time_type === 0
                                        ? <span>
                                        开卡后{card.limit_days}天内可使用
                                    </span>
                                        : <span>
                                        固定日期：到{moment(card.expired_date).format("YYYY-MM-DD")}之前可用
                                    </span>}
                                </span>
                                <span style={{marginRight: 20}}>
                                       <span>
                                           {card.holiday_use === 1 && "周末"}
                                           {card.weekdays_use === 1 && "工作日"}
                                           {card.special_use === 1 && "特殊日期"}
                                       </span>
                                </span>
                                <div>
                                    {card.courses.map(item => item.name).join()}
                                </div>
                            </div>}/>
                    </Card.Grid>)}
                </Card>
                {this.state.card &&
                <CardAddModal
                    user_id={this.props.user_id}
                    data={this.state.card}
                    onClose={() => {
                        this.setState({card: null})
                    }}
                    onOK={()=>{
                        this.setState({card: null});
                        this.handleOK()
                    }}
                />
                }

            </Modal>
        )
    }
}

MembersCardAddModal.propTypes = {
    // visible: PropTypes.bool,
    onClose: PropTypes.func,
    onOK: PropTypes.func,
    user_id: PropTypes.number
};

export default Form.create({name: 'courses-type-add'})(MembersCardAddModal);
