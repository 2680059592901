import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {
    Button,
    Table,
    Divider,
    Switch,
    message,
    Popconfirm,
    Row,
    Col,
    DatePicker,
    Card,
    Layout,
    Calendar,
    Space,
    Modal
} from 'antd';
import {getStaff, putStaffFile} from "../../lib/api_staff";
import styles from './paiban-calendar-week.module.css';
import YuYueItem from '../calendar/time-select-yuyue-item';
import {getPaiBan, deletePaiBan} from "../../lib/api_paiban";
import {ContextMenu, MenuItem, ContextMenuTrigger} from "react-contextmenu";
import ChuqinModal from './chuqin-modal';
import ChuqinEditModal from './chuqin-edit-modal';
import moment from 'moment';
import {DeleteOutlined, PlusOutlined, ExclamationCircleOutlined} from '@ant-design/icons';
import {postPaiBanExecAll} from "../../lib/api_paiban_auto";
import AuthComponent from '../../components/auth_component';

const {Column} = Table;
const {confirm} = Modal;


let contextTrigger = null;

const toggleMenu = e => {
    if (contextTrigger) {
        contextTrigger.handleContextClick(e);
    }
};

// @AuthComponent
class PaiBanCalendar extends Component {
    state = {
        list: [],
        tableData: [],
        columns: [],

        // 当前日期
        current_date: moment(moment().format("YYYY-MM-DD")),

        // 显示视图，1 日期 2周 3月
        view: 1,
        showChuqinModal: false,

        createItem: null
    };

    componentDidMount() {
        this.loadData()
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    loadData = () => {
        this.loadPaiBan();
    };

    loadPaiBan = () => {
        let {current_date} = this.state;

        // 获得排班情况
        getPaiBan(moment(current_date.weekday(0)).format("YYYY-MM-DD"), moment(current_date.weekday(6)).add(1, "d").format("YYYY-MM-DD"))
            .then(result => {
                if (result.code === 0) {
                    this.setState({
                        list: result.data
                    }, this.dealData)
                } else {
                    message.error(result.message);
                }
            })
    };

    // 数据处理
    // dealData = () => {
    //     let {list, current_date} = this.state;
    //     let {staff} = this.props;
    //
    //     let tableData = [];
    //     let columns = [{
    //         title: "教练",
    //         dataIndex: "nick_name",
    //         key: "nick_name"
    //     }];
    //
    //     let begin = moment(current_date).weekday(0);
    //     let times = [];
    //     for (let i = 0; i < 7; i++) {
    //         let t = moment(begin).add(i, "d");
    //         times.push(t);
    //         columns.push({
    //             title: t.format("M月D日"),
    //             dataIndex: t.format("M月D日"),
    //             key: t.format("M月D日"),
    //             // onCell: (record) => {
    //             //     return {
    //             //         onMouseEnter: () => {
    //             //             console.log(1)
    //             //             record.selected = true;
    //             //             this.setState({})
    //             //         }
    //             //     }
    //             // },
    //             // render:(key, record)=>{
    //             //     if(key && key.length > 0){
    //             //         return [key, <div>设为休息</div>];
    //             //     }else{
    //             //         return null;
    //             //     }
    //             // }
    //         })
    //     }
    //
    //     staff.forEach(user => {
    //         let model = {...user};
    //         times.forEach(time => {
    //             let items = list.filter(item => item.user_id === user.id &&
    //                 moment(item.begin_time).isBetween(moment(time), moment(time).add(1, "d"), null, "[)")
    //             );
    //
    //             model[time.format("M月D日")] = items.map(item => <div>{moment(item.begin_time).format("HH:mm")}
    //                 - {moment(item.end_time).format("HH:mm")} <a onClick={()=> this.handleDelete(item.begin_time, item.user_id)}>取消</a></div>)
    //         });
    //
    //         tableData.push(model)
    //     })
    //     this.setState({
    //         tableData: tableData,
    //         columns: columns
    //     })
    // };
    dealData = () => {
        let {list, current_date} = this.state;
        let {staff} = this.props;

        let tableData = [];
        let columns = [{
            title: "日期",
            dataIndex: "date",
            key: "日期"
        }];
        console.log(staff);
        staff.filter(item => item.status === 1 && item.is_coach === true).forEach(staf => {
            columns.push({
                title: staf.name,
                dataIndex: "t" + staf.id,
                key: "教练",
                render: (text, record) => {
                    if (record.tdate.isBefore(moment(), "d")) {
                        return text;
                    }
                    return (
                        <div>
                            {text}
                            <PlusOutlined onClick={() => this.handleChuqingClick({
                                user_id: staf.id,
                                teacher_name: staf.name,
                                begin_time: moment(record.tdate).format("YYYY-MM-DD HH:mm"),
                                end_time: moment(record.tdate).add(1, 'h').format("YYYY-MM-DD HH:mm")
                            })}/>

                        </div>
                    );
                }
            })
        });
        let begin = moment(current_date).weekday(0);
        for (let i = 0; i < 7; i++) {
            let t = moment(begin).add(i, "d");
            let model = {date: t.format("M月D日 ddd"), tdate: t};

            staff.forEach(staf => {
                let items = list.filter(item => item.user_id === staf.id &&
                    moment(item.begin_time).isBetween(moment(t), moment(t).add(1, "d"), null, "[)")
                );

                model[`t${staf.id}`] = items.map(item =>
                    <div>{moment(item.begin_time).format("HH:mm")}
                        - {moment(item.end_time).format("HH:mm")}
                        {(!item.is_file && !moment(item.begin_time).isBefore(moment(), "d")) &&
                        <a onClick={() => this.handleDelete(item.begin_time, item.user_id)}><DeleteOutlined/></a>
                        }
                    </div>)

            });

            tableData.push(model);
        }


        // let begin = moment(current_date).weekday(0);
        // let times = [];
        // for (let i = 0; i < 7; i++) {
        //     let t = moment(begin).add(i, "d");
        //     times.push(t);
        //     columns.push({
        //         title: t.format("M月D日"),
        //         dataIndex: t.format("M月D日"),
        //         key: t.format("M月D日"),
        //         // onCell: (record) => {
        //         //     return {
        //         //         onMouseEnter: () => {
        //         //             console.log(1)
        //         //             record.selected = true;
        //         //             this.setState({})
        //         //         }
        //         //     }
        //         // },
        //         // render:(key, record)=>{
        //         //     if(key && key.length > 0){
        //         //         return [key, <div>设为休息</div>];
        //         //     }else{
        //         //         return null;
        //         //     }
        //         // }
        //     })
        // }
        //
        // staff.forEach(user => {
        //     let model = {...user};
        //     times.forEach(time => {
        //         let items = list.filter(item => item.user_id === user.id &&
        //             moment(item.begin_time).isBetween(moment(time), moment(time).add(1, "d"), null, "[)")
        //         );
        //
        //         model[time.format("M月D日")] = items.map(item => <div>{moment(item.begin_time).format("HH:mm")}
        //             - {moment(item.end_time).format("HH:mm")} <a onClick={()=> this.handleDelete(item.begin_time, item.user_id)}>取消</a></div>)
        //     });
        //
        //     tableData.push(model)
        // })
        this.setState({
            tableData: tableData,
            columns: columns
        })
    };

    onChange = (date, dateString) => {
        // console.log(date, dateString);
        this.setState({
            current_date: date
        }, () => {
            this.loadPaiBan()
        });

        // console.log(date.weekday(0))
        // console.log(date.weekday(6))
    };
    handleDelete = (begin_time, user_id) => {
        // if(!this.hasRole([3,4])){
        //     message.error("无权修改");
        //     return;
        // }
        deletePaiBan({begin_time, user_id})
            .then(res => {
                if (res.code === 0) {
                    this.loadData()
                } else {
                    message.error(res.message);
                }
            });
    };


    renderTimeCell = (time, user_id) => {
        let {list} = this.state;

        if (!list) {
            return null;
        }
        let items = list.filter(item => item.user_id === user_id &&
            moment(item.begin_time).isBetween(moment(time), moment(time).add(1, "d"), null, "[)")
        );


        return (
            <div className={styles.timeItem}
                 style={{}}
                // onClick={() => this.handleChuqingClick({
                //     user_id: staf.id,
                //     teacher_name: staf.nick_name,
                //     begin_time: item.format("YYYY-MM-DD HH:mm"),
                //     end_time: moment(item).add(1, 'h').format("YYYY-MM-DD HH:mm")
                // })}
            >
                {items.map(item => <span>{moment(item.begin_time).format("HH:mm")}
                    - {moment(item.end_time).format("HH:mm")}</span>)}
            </div>
        )
    };

    // 加载员工时间表
    renderStaff = (staf, times) => {
        // 获取教练的预约
        let {list, step} = this.state;

        if (!list) {
            return null;
        }
        let items = list.filter(item => item.user_id === staf.id);


        return (
            <Col key={staf.id}>
                <div className={`${styles.timeItem} ${styles.timeItemHead}`}
                     style={{}}>{staf.nick_name}</div>
                <div className={styles.timeItemGroup}>
                    {times.map((item, index) => this.renderTimeCell(item, staf.id))}
                    {items.map((item, index) =>
                        <YuYueItem key={index}
                                   times={times}
                                   {...item}
                                   onClick={() => this.handleChuqingEditClick(staf.nick_name, item)}/>
                    )}

                </div>
            </Col>
        )
    };

    handleDateChange = (value) => {
        console.log(value)
        this.setState({
            current_date: moment(value.format("YYYY-MM-DD"))
        }, () => {
            this.loadPaiBan()
        })
    };

    handleChuqingClick = (model) => {
        this.setState({
            createItem: model
        })

    };

    handleChuqingEditClick = (teacher_name, item) => {
        console.log(teacher_name);
        item.teacher_name = teacher_name;
        this.setState({editItem: item})
    };

    handleZhiXing = () => {
        if (this.state.list.length > 0) {
            confirm({
                title: '覆盖当前周的排班?',
                icon: <ExclamationCircleOutlined/>,
                content: '检测到当前周已存在排班，是否进行覆盖？',
                // okText: 'Yes',
                okType: 'danger',
                // cancelText: 'No',
                onOk: () => {
                    this.execpaiban();
                    console.log('OK');
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        } else {
            this.execpaiban();
        }
    };

    // 执行排班计划
    execpaiban = () => {
        let {current_date, view} = this.state;
        postPaiBanExecAll(moment(current_date).weekday(0).format("YYYY-MM-DD"))
            .then(res => {
                if (res.code === 0) {
                    message.success("执行排班计划完成！")
                    this.loadData()
                }
            })
    };

    renderView1 = () => {
        let {current_date, view} = this.state;
        let {staff} = this.props;

        let begin = moment(current_date).weekday(0);
        let times = [];
        for (let i = 0; i < 7; i++) {
            times.push(moment(begin).add(i, "d"))
        }

        console.log(staff);
        return (
            <div>
                <DatePicker onChange={this.onChange} value={current_date} picker="week"/>
                {/*{times.map(item => <div>{item.format("HH:mm")}</div>)}*/}
                <Row type={"flex"} style={{flexWrap: "nowrap"}}>
                    <Col>
                        <div className={`${styles.timeItem} ${styles.timeItemHead}`}>
                        </div>
                        {times.map((item, index) => (
                            <div key={index} className={`${styles.timeItem} ${styles.timeItemHead}`}>
                                <div>{item.format("MM月DD日")}</div>
                            </div>
                        ))}
                    </Col>
                    {staff.filter(staf => staf.role === 1).map(staf => this.renderStaff(staf, times))}

                </Row>

                <ChuqinModal
                    data={this.state.createItem}
                    onOK={() => {
                        this.loadData();
                        this.setState({createItem: null});
                    }}
                    onClose={() => this.setState({createItem: null})}
                />
                <ChuqinEditModal
                    data={this.state.editItem}
                    onOK={() => {
                        this.loadData();
                        this.setState({editItem: null});
                    }}
                    onClose={() => this.setState({editItem: null})}
                />
            </div>
        )
    };

    render() {
        let {staff, current_date, view, tableData, columns} = this.state;

        return (
            <div>
                <Space style={{marginBottom: 10}}>
                    <DatePicker onChange={this.onChange} value={current_date} picker="week" allowClear={false}/>
                    {/*{this.hasRole([3,4]) && <Button type="primary" onClick={this.handleZhiXing}>执行排班计划</Button>}*/}
                    <Button type="primary" onClick={this.handleZhiXing}>执行排班计划</Button>
                </Space>
                <Table dataSource={tableData} columns={columns} bordered pagination={false} size={"small"}/>
                <ChuqinModal
                    data={this.state.createItem}
                    onOK={() => {
                        this.loadData();
                        this.setState({createItem: null});
                    }}
                    onClose={() => this.setState({createItem: null})}
                />
            </div>
        );
        if (view === 1) {
            return this.renderView1();
        }

        // 日历显示
        let date = [];
        for (let i = 0; i < 14; i++) {
            date.push(moment(current_date).add(i, "d").format("MM-DD"))
        }

        return (
            <div>
                {date.map(item => <div>{item}</div>)}
            </div>
        )
    }
}

PaiBanCalendar.propTypes = {};

const mapStateToProps = state => ({
    staff: state.staff
});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(PaiBanCalendar));


