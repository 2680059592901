import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {getMember, deleteCard} from "../../lib/api_members";
import {Form} from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
    Button,
    Table,
    Tabs,
    PageHeader,
    Descriptions,
    Modal,
    Input,
    DatePicker,
    message,
    Radio,
    Timeline,
    Tag,
    Space,
    Switch,
} from 'antd';
import PageContent from '../../components/page-content/page-content';
import MembersEditModal from './members-edit-modal';
import MembersResetModal from './members-reset-modal';
import MembersCardAddModal from './members-card-add-modal';
import moment from 'moment';
import Box from '../../components/box';
import StaffName from '../../components/staff_name';
import MembersChongZhiModal from './members-chongzhi-modal';
import ResidenceName from '../../components/residence_name';
import TrainTypeName from '../../components/config/train_type_name';
import CardEditModal from './card-edit-modal';
import {ExclamationCircleOutlined} from '@ant-design/icons';
import {getUserCardInfo, putUserCardStatus} from "../../lib/api_users_cards";
import CardTimeFreeModal from './card-time-free-modal';
import CardKeShiFreeModal from './card-keshi-free-modal';
import CardXukaModal from './card-xuka-modal';
import CardAddPaymentsModal from './card-add-payments-modal'

const {Column} = Table;
const {confirm} = Modal;

class MembersDetail extends Component {
    state = {
        data: null,
    };


    handleClose = () => {
        this.props.onClose && this.props.onClose();
    };

    handleOK = () => {
        this.props.onOK && this.props.onOK();
    };


    componentDidMount() {
        this.fetch();
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    // 获取学生信息
    fetch = () => {
        getUserCardInfo(this.props.data.id)
            .then((result) => {
                if (result.code === 0) {
                    this.setState({
                        data: result.data,

                    })
                } else {

                }
            })
    };


    handleStatusChange = (checked) => {
        putUserCardStatus(this.props.data.id, {
            status: checked ? 0 : 1
        })
            .then(res => {
                if (res.code === 0) {
                    message.success("修改成功")
                    this.fetch();
                }
            })
    }

    render() {
        let {data} = this.state;
        if (!data) return null;

        return (

            <Modal
                title="卡片详情"
                visible={!!this.props.data}
                // onOk={this.handleSubmit}
                onCancel={this.handleClose}
                destroyOnClose={true}
                footer={false}
                width={800}
            >
                <Descriptions bordered size={"small"}>
                    <Descriptions.Item label="名称">{data.name}</Descriptions.Item>
                    <Descriptions.Item label="开始时间">{moment(data.begin_time).format("YYYY-MM-DD")}</Descriptions.Item>
                    <Descriptions.Item label="结束时间">{moment(data.end_time).format("YYYY-MM-DD")}</Descriptions.Item>
                    <Descriptions.Item label="正课课时">{data.keshi_original}</Descriptions.Item>
                    <Descriptions.Item label="剩余正课课时">{data.keshi_remaining}</Descriptions.Item>
                    <Descriptions.Item label="赠送课时">{data.keshi_free_original}</Descriptions.Item>
                    <Descriptions.Item label="剩余赠送课时">{data.keshi_free_remaining}</Descriptions.Item>
                    <Descriptions.Item label="支付金额">{data.payments.toFixed(2)}
                        {data.payments === 0 &&
                        <Button type="primary" onClick={() => this.setState({addpayments: data})}>设置</Button>
                        }
                    </Descriptions.Item>
                    <Descriptions.Item label="单节费用">{data.payments_avg.toFixed(2)}</Descriptions.Item>
                    <Descriptions.Item label="剩余金额">{data.payments_remaining.toFixed(2)}</Descriptions.Item>
                    <Descriptions.Item label="状态">
                        <Switch checkedChildren="正常" unCheckedChildren="失效" checked={data.status === 0}
                                onChange={this.handleStatusChange}/>

                    </Descriptions.Item>
                </Descriptions>
                <Tabs defaultActiveKey="1">
                    <Tabs.TabPane tab={`课时记录`} key="1">
                        <Table
                            dataSource={data.keshi_history}
                            rowKey={"id"}
                            size="small"
                            pagination={false}>
                            <Column
                                title="时间"
                                dataIndex="create_on"
                                key="create_on"
                            />
                            <Column
                                title="说明"
                                dataIndex="remark"
                                key="remark"
                            />
                            <Column
                                title="课时"
                                dataIndex="keshi"
                                key="keshi"
                            />
                            <Column
                                title="费用"
                                dataIndex="price"
                                key="price"
                                align="right"
                                render={(price) => price.toFixed(2)}
                            />
                            <Column
                                title="赠课"
                                dataIndex="is_gift"
                                key="is_gift"
                                render={(is_gift) => is_gift === 1 ? "是" : ""}
                            />
                            {/*<Column*/}
                            {/*title="卡片"*/}
                            {/*dataIndex="card_id"*/}
                            {/*key="card_id"*/}
                            {/*render={(card_id) => {*/}
                            {/*let card = student_info.cards.find(item => item.id === card_id);*/}
                            {/*return card ? card.name : ""*/}
                            {/*}}*/}
                            {/*/>*/}
                            <Column
                                title="处理人"
                                dataIndex="create_by"
                                key="处理人"
                                render={(create_by) => {
                                    return <StaffName id={create_by}/>
                                }}
                            />
                            {/*<Column*/}
                            {/*title="操作"*/}
                            {/*dataIndex="id"*/}
                            {/*key="id"*/}
                            {/*render={(id, record) => (*/}
                            {/*<a onClick={() => this.setState({keshi_edit: {...record}})}>修改</a>*/}
                            {/*)}*/}
                            {/*/>*/}
                        </Table>

                    </Tabs.TabPane>

                </Tabs>

                {this.state.addpayments &&
                <CardAddPaymentsModal
                    data={this.state.addpayments}
                    onClose={() => {
                        this.setState({addpayments: null})
                    }}
                    onOK={() => {
                        this.setState({addpayments: null});
                        this.handleOK()
                    }}
                />
                }

            </Modal>
        )
    }
}


MembersDetail.propTypes = {
    data: PropTypes.object.isRequired,
};


const mapStateToProps = state => ({
    courses_type: state.courses_type
});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(MembersDetail));
