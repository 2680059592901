/**
 * @name: 班级详情
 * @description:
 * @author: Wood
 * @date: 2019/3/28
 * @update: 2019/2/24
 */
import PropTypes from 'prop-types';
import React, {useState, useEffect} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import PageContent from '../../../components/page-content/page-content';
import {
    Button,
    Table,
    Tabs,
    PageHeader,
    Descriptions,
    Modal,
    Input,
    DatePicker,
    message,
    Radio,
    Timeline,
    Tag,
    Space,
    Row,
    Col,
    Empty,
    Spin,
} from 'antd';
import Box from '../../../components/box';
import {getMember2, deleteCard} from "../../../lib/api_members";

import ResidenceName from '../../../components/residence_name';
import TrainTypeName from '../../../components/config/train_type_name';
import MemberCards from './member_cards';
import MemberYuyues from './member_yuyue';
import MemberKeshis from './member_keshis';
import StaffName from '../../../components/staff_name';
import MembershipInfo from '../membership/membership-info';
import MembershipAddModal from '../membership/membership_add_modal';
import MembersCardAddModal from './card-add-modal';
import MembersResetModal from '../members-reset-modal';
import MembersEditModal from '../members-edit-modal';
import MembersFileModal from './members-file-modal';
import MembersUnFileModal from './members-unfile-modal';

const MemberDetail = ({id, history}) => {
    const [student_info, setstudent_info] = useState(null);
    const [membershipadd, setmembershipadd] = useState(false);
    const [xuKaModal, setxuKaModal] = useState(false);
    const [resetModal, setresetModal] = useState(false);
    const [editModal, seteditModal] = useState(false);
    const [fileModal, setfileModal] = useState(false);
    const [unfileModal, setunfileModal] = useState(false);


    // 获取学生信息
    const loadStudentInfo = () => {
        getMember2(id)
            .then((result) => {
                if (result.code === 0) {
                    setstudent_info(result.data);
                }
            })
    };
    useEffect(loadStudentInfo, []);

    // 预约操作
    const handleYuYue = () => {
        history.push("/", {user_id: id});
    };

    return (
        <div>
            {student_info
                ? <PageContent title={<span>{student_info.nick_name} {student_info.is_file === 1 ? <Tag>已失效</Tag>: <Tag color="success">正常</Tag>}</span>}
                               routes={[
                                   {
                                       path: '',
                                       breadcrumbName: <><span>首页</span></>,
                                   },
                                   {
                                       path: '//members',
                                       breadcrumbName: '会员管理',
                                   },
                                   {
                                       path: `//${id}`,
                                       breadcrumbName: '会员详情',
                                   },
                               ]}
                               extra={[
                                   <Button key="1" type="primary"
                                           onClick={handleYuYue}>
                                       预约
                                   </Button>,
                                   <Button key="2"
                                           onClick={() => seteditModal(true)}>
                                       修改
                                   </Button>,
                                   <Button key="3"
                                           onClick={() => setresetModal(true)}>
                                       重置密码
                                   </Button>,
                                   <Button key="4"
                                           onClick={() => setxuKaModal(true)}>
                                       添加次卡
                                   </Button>,
                                   <>
                                   {student_info.is_file=== 1
                                       ?<Button key="4"
                                                onClick={() => setunfileModal(true)}>
                                           启用
                                       </Button>
                                       :<Button key="4"
                                                onClick={() => setfileModal(true)}>
                                           失效
                                       </Button>
                                   }
                                   </>,
                               ]}
                >
                    <Box>
                        <Descriptions bordered size={"small"}>
                            <Descriptions.Item label="会员姓名">{student_info.nick_name}</Descriptions.Item>
                            <Descriptions.Item label="昵称">{student_info.other_name}</Descriptions.Item>
                            <Descriptions.Item
                                label="会员编号">{student_info.member_number && student_info.member_number.member_number}</Descriptions.Item>
                            <Descriptions.Item
                                label="性别">{student_info.sex === 1 && "男"}{student_info.sex === 2 && "女"}</Descriptions.Item>
                            <Descriptions.Item label="出生年月">{student_info.birthday}</Descriptions.Item>
                            <Descriptions.Item label="联系电话">{student_info.mobile_number}</Descriptions.Item>
                            <Descriptions.Item label="车牌号码">{student_info.license_plate}</Descriptions.Item>
                            <Descriptions.Item label="专属教练"><StaffName id={student_info.teacher}/></Descriptions.Item>
                            <Descriptions.Item label="课程顾问"><StaffName
                                id={student_info.consultant}/></Descriptions.Item>
                            <Descriptions.Item label="训练类型"><TrainTypeName
                                value={student_info.train_type}/></Descriptions.Item>
                            <Descriptions.Item label="居住地"><ResidenceName
                                data={student_info.residence}/></Descriptions.Item>
                            <Descriptions.Item label="标记1">{student_info.tag1}</Descriptions.Item>
                            <Descriptions.Item label="标记2">{student_info.tag2}</Descriptions.Item>
                            <Descriptions.Item label="首次办卡日期">{student_info.member_begin}</Descriptions.Item>
                            <Descriptions.Item label="有效期">{student_info.member_end}</Descriptions.Item>
                            <Descriptions.Item
                                label="门店">{student_info.store ? student_info.store.name : "-"}</Descriptions.Item>
                            {/*<Descriptions.Item label="开卡时间">{student_info.member_begin}</Descriptions.Item>*/}
                            {/*<Descriptions.Item label="到期时间" span={2}>{student_info.member_end}</Descriptions.Item>*/}
                            <Descriptions.Item label="备注" span={3}>{student_info.remark}</Descriptions.Item>
                        </Descriptions>
                    </Box>
                    <Box title={"VIP会籍"} style={{marginTop: 10}}>
                        {student_info && student_info.membership_id > 0
                            ? <MembershipInfo id={student_info.membership_id}/>
                            : <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE}>
                                <Button type={"primary"} onClick={() => setmembershipadd(true)}>立即创建</Button>
                            </Empty>
                        }


                    </Box>
                    <Box title={"次卡"} style={{marginTop: 10}}>
                        <MemberCards id={id}/>
                    </Box>
                    <Row gutter={[12, 12]} style={{marginTop: 10}}>
                        <Col span={12}>
                            <MemberYuyues id={id}/>
                        </Col>
                        <Col span={12}>
                            <MemberKeshis id={id}/>
                        </Col>
                    </Row>
                    <MembershipAddModal
                        members={[id]}
                        visible={membershipadd}
                        onOk={() => {
                            setmembershipadd(false);
                            loadStudentInfo();
                            // setVisible(false);
                            // loadData()
                        }}
                        onCancel={() => {
                            setmembershipadd(false);
                            // setVisible(false)
                        }}
                        // {...match.params}
                    />
                    {xuKaModal &&
                    <MembersCardAddModal
                        onOk={() => {
                            loadStudentInfo();
                            setxuKaModal(false);
                            // setState({xuKaModal: 0})
                        }}
                        onCancel={() => {
                            setxuKaModal(false);
                            // this.setState({xuKaModal: 0})
                        }}
                        user_id={id}
                    />
                    }
                    {resetModal &&
                    <MembersResetModal
                        onOK={() => {
                            setresetModal(false);
                        }}
                        onClose={() => {
                            setresetModal(false);
                        }}
                        user_info={student_info}
                    />
                    }
                    {editModal &&
                    <MembersEditModal
                        onOK={() => {
                            seteditModal(false);
                            loadStudentInfo();
                        }}
                        onClose={() => {
                            seteditModal(false);
                        }}
                        user_info={student_info}
                    />
                    }
                    {fileModal && <MembersFileModal
                        onOk={() => {
                            setfileModal(false);
                            loadStudentInfo();
                        }}
                        onCancel={() => {
                            setfileModal(false);
                        }}
                        user_info={student_info}
                    /> }
                    {unfileModal && <MembersUnFileModal
                        onOk={() => {
                            setunfileModal(false);
                            loadStudentInfo();
                        }}
                        onCancel={() => {
                            setunfileModal(false);
                        }}
                        user_info={student_info}
                    /> }
                </PageContent>
                : <Spin/>
            }
        </div>
    )
};


MemberDetail.propTypes = {
    id: PropTypes.number.isRequired,
};


const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(MemberDetail));
