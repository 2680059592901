import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {Form} from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
    Modal,
    Input,
    Row,
    Col,
    Radio,
    DatePicker,
    Select,
    Tabs,
    Calendar,
    Affix,
    Button,
    Tag,
    message,
    TimePicker,
    Avatar,
    Card,
    Divider,
    List,
    Checkbox
} from 'antd';
import moment from 'moment';
import {rangeInRange, timeInRange, rangeIntersect} from '../../lib/time-util';
import {postYuYue, postYuYueShangma, postYuYueWancheng, postYuYueQuxiao} from "../../lib/api_yuyue";
import TeacherSelect from '../members/teacher-select';
import {getMember} from "../../lib/api_members";
import {ManOutlined, WomanOutlined, CrownOutlined} from '@ant-design/icons';
import {ExclamationCircleOutlined} from '@ant-design/icons';
import StaffName from '../../components/staff_name';
import MemberCardSelecter from './member-card-selecter';

const {confirm} = Modal;

const timeAfter = [5, 15, 30];

const formItemLayout = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 6},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 18},
    },
};


class FormSanke extends Component {

    state = {
        // yuyue: [],  // 预约情况
        // paiban: [], // 排班情况
        // staff: [],  // 教练
        recommendTeachers: [],   // 推荐教练

        // 是否没有排班
        noPaiBan: true,

        //当前日期
        date: "",

        // 手动设置开始时间
        begin_time: null,
        // 手动设置教师信息
        teacher: 0,

        // 是否为今天
        isToday: false,

        user: null,

        // 可以选择的课程类型
        courses_type: [],

        data: {
            type: 1,    // 课程类型
            duration: 15,   // 时长
            begin_time: null,   // 开始时间
            end_time: null, // 结束时间
            teacher: 0,     // 教练
            user_type: 0,
            yuyue_type: 1,
            nick_name: "",
            mobile_number: "",
            license_plate: "",
            remark: "",
            members: "[]",
            teachers: []
        }
    };

    componentDidMount() {
        let {data} = this.props;
        data.end_time = moment(data.begin_time).add(data.duration, "m").format("YYYY-MM-DD HH:mm");
        console.log(data);
        // let user = null
        let courses_type = this.props.courses_type
            .filter(item => item.yuyue_type && JSON.parse(item.yuyue_type).includes(3));
        if (!data.teachers) {
            data.teachers = [];
        } else if (typeof data.teachers === "string") {
            data.teachers = JSON.parse(data.teachers);
        }

        this.setState({
            data: {...data},
            isEdit: data.user_id > 0,
            courses_type: courses_type
        }, () => {
            if (data.user_id) {
                this.onMemberChange(data.user_id);
            }
            // this.recommendTeacher();
        });
    }


    // 获得空余排班
    getKongYuPaiBan = (paiban, yuyue, duration) => {
        let paiban_new = Array.from(paiban);

        // 如果是编辑页面,先移除一下当前的预约
        if (this.state.data.id > 0) {
            yuyue = yuyue.filter(item => item.id !== this.state.data.id)
            // yuyue.splice(yuyue.findIndex(item => item.id === this.state.data.id), 1)
        }

        yuyue.forEach(yy => {
            let pbmodel = paiban_new.find(pb => moment(yy.begin_time).isBetween(moment(pb.begin_time), moment(pb.end_time), null, '[)')
                && pb.user_id === yy.teacher);
            if (pbmodel) {
                // 存在排班，开始切断时间
                // yy.begin_time    预约开始时间
                // yy.end_time      预约结束时间
                // pbmodel.begin_time   排班开始时间
                // pbmodel.end_time     排班结束时间
                console.log('排班处理');
                // 删除当前排班
                paiban_new.splice(paiban_new.findIndex(item => item === pbmodel), 1)
                // 排班计算
                let pb1 = {
                    begin_time: pbmodel.begin_time,
                    end_time: yy.begin_time,
                    user_id: pbmodel.user_id
                };
                if (moment(pb1.end_time).diff(moment(pb1.begin_time), 'm') >= duration) {
                    paiban_new.push(pb1);
                    console.log("pb1", pb1);
                }

                let pb2 = {
                    begin_time: yy.end_time,
                    end_time: pbmodel.end_time,
                    user_id: pbmodel.user_id
                };
                if (moment(pb2.end_time).diff(moment(pb2.begin_time), 'm') >= duration) {
                    paiban_new.push(pb2);
                    console.log("pb2", pb2);
                }
            }
        });
        // console.log(paiban_new);

        return paiban_new;
    };


    // 初始化数据
    resetValue = (props) => {
        // 初始化数据

        let {data} = props;
        if (!data) data = {};
        // 课程类型
        if (!data.type) data.type = 1;
        // 时长
        if (!data.duration) data.duration = 15;


        // 获得该日期下的所有排班情况和预约情况
        let {date, yuyue, paiban} = props;
        // 所有排班
        paiban = this.getKongYuPaiBan(paiban, yuyue, data.duration)
            .filter(pb => moment(pb.begin_time).format("YYYY-MM-DD") === moment(date).format("YYYY-MM-DD"));
        console.log(paiban);

        // 没有排班情况
        if (paiban.length === 0) {
            this.setState({
                noPaiBan: true
            });
            return;
        }

        // 获得该排班情况下最早的时间
        if (moment().isSame(moment(date), 'day')) {
            // 当天情况下，获取当前时间
            let current_time = this.getTime(5);
            // 从排班中获取离自己最近的排班时间
            // paiban.forEach
        } else {
            // 不是当天的情况，获取最早时间
            data.begin_time = moment(paiban[0].begin_time);
            paiban.forEach(item => {
                if (moment(item.begin_time).isBefore(moment(data.begin_time))) {
                    data.begin_time = item.begin_time;
                }
            })
        }


        data.begin_time = moment(data.begin_time).format("YYYY-MM-DD HH:mm");
        data.end_time = moment(data.begin_time).add(data.duration, "m").format("YYYY-MM-DD HH:mm");
        if (!data.teacher) data.teacher = 0;
        if (!data.user_type) data.user_type = 0;
        if (!data.yuyue_type) data.yuyue_type = 0;
        if (!data.nick_name) data.nick_name = this.state.data.nick_name;
        if (!data.mobile_number) data.mobile_number = this.state.data.mobile_number;
        if (!data.license_plate) data.license_plate = this.state.data.license_plate;
        if (!data.remark) data.remark = this.state.data.remark;
        if (!data.teachers) data.teachers = [];
        // if(typeof data.teachers === "string") data.teachers = JSON.parse(data.teachers)
        this.setState({data: data}, () => {
            this.recommendTeacher()

        });
    };


    // 获取5分钟后的时间，并取整5
    getTime = (minute) => {
        let now = moment(moment().format("YYYY-MM-DD HH:mm"));
        now.add(minute, "m");
        let m = (parseInt(now.minutes() / 5) + 1) * 5;
        now.minutes(m);
        return now;
    };

    // 设置时间
    handleSetTime = (time) => {
        console.log(time);
        let {data} = this.state;
        data.begin_time = time.format("YYYY-MM-DD HH:mm");
        data.end_time = moment(data.begin_time).add(data.duration, "m").format("YYYY-MM-DD HH:mm");
        this.setState({}, () => {
            this.recommendTeacher()
        })
    };

    // 设置时长
    handleSetDuration = (duration) => {
        let {data} = this.state;
        data.duration = duration;
        data.end_time = moment(data.begin_time).add(data.duration, "m").format("YYYY-MM-DD HH:mm");
        this.setState({}, () => {
            this.recommendTeacher();
            this.handleChange();
        })
    };

    // 设置鞍时
    handleSetAnShi = (anshi) => {
        let {data} = this.state;
        data.anshi = anshi;
        const anshi2min = [0, 45, 90, 135, 180];
        data.duration = anshi2min[anshi];
        data.end_time = moment(data.begin_time).add(data.duration, "m").format("YYYY-MM-DD HH:mm");
        this.setState({}, () => {
            this.recommendTeacher();
            // this.resetBeginTime();
            // this.handleChange();
        })
    };
    //
    // // 设置卡片
    // handleSetCard = (card_id) => {
    //     let {data} = this.state;
    //     let {courses_type} = this.props;
    //     data.card_id = card_id;
    //     data.card = this.state.user.cards.find(item => item.id === card_id);
    //     // console.log(data);
    //     // 设置可选择的类型
    //     if (data.card) {
    //         let type_ids = data.card.courses_type.split(',');
    //         // 从类型中进行筛选
    //         let res = courses_type.filter(ct => type_ids.includes(ct.id + ''));
    //         console.log(res);
    //         this.setState({
    //             courses_type: res
    //         }, () => {
    //             if (res.length > 0) {
    //                 if (data.type && res.some(item => item.id === data.type)) {
    //                     this.handleSetType(data.type)
    //                 } else {
    //                     this.handleSetType(res[0].id)
    //                 }
    //             }
    //         })
    //     }
    // };

    // 设置类型
    handleSetType = (type) => {
        let {data} = this.state;
        data.type = type;
        this.setState({}, () => {
            this.handleSetAnShi(data.anshi)
        })
    };
    // 设置教练
    handleSetTeacher = (teacher) => {
        let {data} = this.state;
        data.teacher = teacher;
        this.setState({}, () => {
            this.handleChange();
        })
    };

    // 设置助教
    handleSetTeachers = (teachers) => {
        let {data} = this.state;
        console.log(teachers);
        data.teachers = teachers;
        this.setState({}, () => {
            this.handleChange();
        })
    };

    // 获得推荐教练
    recommendTeacher = () => {
        // 获得教练列表
        let {type, begin_time, end_time, teacher, duration} = this.state.data;
        let {paiban, staff, yuyue} = this.props;


        console.log(yuyue)
        staff.forEach(staf => {
            staf.isRest = false;
            staf.isRecommend = false;
            staf.isBusy = false;
            let paibans_filter = paiban.filter(item => item.user_id === staf.id);
            // 休息
            if (!paibans_filter.some(item => rangeInRange(begin_time, end_time, item.begin_time, item.end_time))) {
                staf.isRest = true;
                return;
            }
            // 忙
            let yuyue_filter = yuyue.filter(item => item.teacher === staf.id);
            if (yuyue_filter.some(item => rangeIntersect(begin_time, end_time, item.begin_time, item.end_time))) {
                staf.isBusy = true;
                return;
            }
            // 推荐
            if (staf.courses_type) {
                let courses_type = JSON.parse(staf.courses_type);
                console.log(courses_type, type);
                if (courses_type.includes(type)) {
                    staf.isRecommend = true;
                    return;
                }
            }

        });
        this.setState({})
    };

    handleChange = () => {
        let {onChange} = this.props;
        // console.log(this.state.data);
        onChange && onChange(this.state.data)
    };

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            // console.log(values.begin_time.format("YYYY-MM-DD HH:mm"))
            console.log(err);
            // console.log(this.state.data);
            if (!err) {
                // console.log('Received values of form: ', values);

                // 检查会员卡情况
                // if (!this.state.data.members) {
                //     message.error("请选择会员");
                //     return;
                // }
                if (!this.state.data.members) this.state.data.members = "[]";
                let members = JSON.parse(this.state.data.members);
                // if (!members || members.length === 0) {
                //     message.error("请选择会员");
                //     return;
                // }
                if (members.some(item => !item.card_id)) {
                    message.error("请选择会员卡");
                    return;
                }
                console.log(members)
                if (members.length > 0) {

                    this.state.data.nick_name = members[0].nick_name;
                    this.state.data.license_plate = members[0].license_plate;
                    this.state.data.mobile_number = members[0].mobile_number;
                    this.state.data.user_id = members[0].user_id;
                    this.state.data.card_id = members[0].card_id;
                }

                console.log(this.state.data);
                postYuYue(this.state.data)
                // postMembers(values)
                    .then(result => {
                        console.log(result);
                        // 刷新数据
                        if (result.code === 0) {
                            message.success("预约成功")
                            this.props.onOk && this.props.onOk();
                        }
                        // this.handleOK();
                    })
            }
        });
    };

    onMemberChange = (id) => {
        // console.log(id)
        let {users_cards} = this.props;
        let {data, isEdit} = this.state;
        let student = this.props.students.find(item => item.id === id);
        if (student) {
            // 获取卡片信息
            student.cards = users_cards.filter(item => item.user_id === student.id);

            if (!isEdit) {
                data.user_id = student.id;
                data.nick_name = student.nick_name;
                data.mobile_number = student.mobile_number;
                data.license_plate = student.license_plate;
                data.anshi = 1;
                if (student.teacher) {
                    data.teacher = student.teacher;
                }
            }
            this.setState({
                user: student
            }, () => {
                if (isEdit) {
                    // this.handleSetCard(data.card_id);
                } else {
                    // if (student.cards.length > 0) {
                    //     this.handleSetCard(student.cards[0].id);
                    //
                    // }
                }
            });
            // console.log(student)
        }

    };

    render() {
        const {getFieldDecorator, setFieldsValue} = this.props.form;
        let {paiban, yuyue, status, createItem, data, recommendTeachers, isToday, user, courses_type, isEdit} = this.state;
        let {staff, students, users_cards} = this.props;
        let sortedstudents = [...students];
        sortedstudents.sort((a, b) => {
            if (moment(a.last_yuyue_time).isBefore(b.last_yuyue_time)) {
                return 1;
            } else {
                return -1;
            }
        });
        sortedstudents.forEach(student => {
            student.cards = users_cards.filter(item => item.user_id === student.id);
        });
        return (
            <Form {...formItemLayout} onSubmit={this.handleSubmit}
                  style={{display: "flex", flexDirection: "column", height: "100%"}}>
                <div style={{flex: 1, overflow: "auto"}}>


                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item label="学员" labelCol={{span: 3}} wrapperCol={{span: 21}}>

                                <MemberCardSelecter
                                    value={data.members}
                                    onChange={(value) => {
                                        data.members = value;
                                        this.recommendTeacher()
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <div>


                                <Form.Item label="课程" labelCol={{span: 3}}>
                                    <Radio.Group buttonStyle="solid"
                                                 value={data.type}
                                                 onChange={(e) => this.handleSetType(e.target.value)}>
                                        {courses_type.map(type => <Radio.Button key={type.id}
                                                                                value={type.id}>{type.name}</Radio.Button>)}

                                    </Radio.Group>
                                </Form.Item>

                                {/*<Form.Item label="时长" labelCol={{span: 3}}>*/}
                                {/*<Radio.Group buttonStyle="solid"*/}
                                {/*value={data.duration}*/}
                                {/*onChange={(e) => this.handleSetDuration(e.target.value)}>*/}
                                {/*<Radio.Button value={15}>15分钟</Radio.Button>*/}
                                {/*<Radio.Button value={30}>30分钟</Radio.Button>*/}
                                {/*<Radio.Button value={45}>45分钟</Radio.Button>*/}
                                {/*</Radio.Group>*/}
                                {/*</Form.Item>*/}
                                <Form.Item label="鞍时" labelCol={{span: 3}}>
                                    <Radio.Group buttonStyle="solid"
                                                 value={data.anshi}
                                                 onChange={(e) => this.handleSetAnShi(e.target.value)}
                                    >
                                        <Radio.Button value={1}>1</Radio.Button>
                                        <Radio.Button value={2}>2</Radio.Button>
                                        {/*<Radio.Button value={3}>3</Radio.Button>*/}
                                        {/*<Radio.Button value={4}>4</Radio.Button>*/}
                                        {/*<Radio value={0}>其他<Input/></Radio>*/}
                                    </Radio.Group>
                                </Form.Item>

                                <Form.Item label="日期" labelCol={{span: 3}}>
                                    <DatePicker format={"YYYY-MM-DD"}
                                        // showTime={{minuteStep: 5, format: "HH:mm"}}
                                                minuteStep={5}
                                        // mode={"time"}
                                                placeholder={"请选择日期"}
                                                value={moment(data.begin_time)}
                                                onChange={(value) => this.handleSetTime(value)}
                                    />
                                </Form.Item>

                                <Form.Item label="开始" labelCol={{span: 3}} wrapperCol={{span: 21}}
                                           help={(
                                               <div>
                                                   {isToday && timeAfter.map(item =>
                                                       <Tag key={item}
                                                            onClick={() => this.handleSetTime(this.getTime(item))}>{item}分钟后
                                                           ({this.getTime(item).format("HH:mm")})</Tag>)}
                                               </div>
                                           )}>
                                    <div style={{display: "block"}}>
                                        <TimePicker format={"HH:mm"}
                                            // showTime={{minuteStep: 5, format: "HH:mm"}}
                                                    minuteStep={5}
                                            // mode={"time"}
                                                    placeholder={"请选择时间"}
                                                    value={moment(data.begin_time)}
                                                    onChange={(value) => this.handleSetTime(value)}
                                        />
                                        结束：
                                        <TimePicker format={"HH:mm"}
                                                    disabled
                                                    value={moment(data.end_time)}
                                        />
                                    </div>
                                </Form.Item>

                                <Form.Item label="主教练" labelCol={{span: 3}}>
                                    {staff.length > 0 ?
                                        <Radio.Group buttonStyle="solid"
                                                     value={data.teacher}
                                                     style={{whiteSpace: "normal"}}
                                                     onChange={(e) => this.handleSetTeacher(e.target.value)}
                                        >
                                            <div>
                                                {staff.filter(item => item.isRecommend).map(item =>
                                                    <Radio.Button key={item.id}
                                                                  value={item.id}>
                                                        {item.nick_name}
                                                        {item.isRest &&
                                                        <span>(<span style={{color: "red"}}>休</span>)</span>}
                                                        {item.isBusy &&
                                                        <span>(<span style={{color: "red"}}>忙</span>)</span>}
                                                        {item.isRecommend &&
                                                        <span>(<span style={{color: "green"}}>荐</span>)</span>}
                                                    </Radio.Button>)}
                                            </div>
                                            <div>
                                                {staff.filter(item => !item.isRecommend).map(item =>
                                                    <Radio.Button key={item.id}
                                                                  value={item.id}>
                                                        {item.nick_name}
                                                        {item.isRest &&
                                                        <span>(<span style={{color: "red"}}>休</span>)</span>}
                                                        {item.isBusy &&
                                                        <span>(<span style={{color: "red"}}>忙</span>)</span>}
                                                        {item.isRecommend &&
                                                        <span>(<span style={{color: "green"}}>荐</span>)</span>}
                                                    </Radio.Button>)}
                                            </div>
                                        </Radio.Group>
                                        : <div style={{color: "red"}}>
                                            无合适教练，请手动设置或<a>设置出勤</a>
                                        </div>
                                    }

                                    {/*{value.nick_name}*/}

                                </Form.Item>

                                <Form.Item label="助教" labelCol={{span: 3}}>
                                    {staff.length > 0 ?
                                        <Checkbox.Group buttonStyle="solid"
                                                        value={data.teachers ? (typeof data.teachers === "string" ? JSON.parse(data.teachers) : data.teachers) : []}
                                                        style={{whiteSpace: "normal"}}
                                                        onChange={(value) => this.handleSetTeachers(value)}
                                        >
                                            {staff.map(item =>
                                                <Checkbox value={item.id}>{item.nick_name}</Checkbox>)}
                                        </Checkbox.Group>
                                        : <div style={{color: "red"}}>
                                            无合适教练，请手动设置或<a>设置出勤</a>
                                        </div>
                                    }

                                    {/*{value.nick_name}*/}

                                </Form.Item>

                            </div>

                        </Col>
                        {/*<Col span={24}>*/}
                        {/*<Form.Item label="姓名" labelCol={{span: 3}} wrapperCol={{span: 9}}>*/}
                        {/*<Input value={data.nick_name} placeholder="姓名" onChange={(e) => {*/}
                        {/*data.nick_name = e.target.value;*/}
                        {/*this.setState({}, this.handleChange)*/}
                        {/*}}/>*/}
                        {/*</Form.Item>*/}
                        {/*</Col>*/}
                        {/*<Col span={24}>*/}
                        {/*<Form.Item label="电话" labelCol={{span: 3}} wrapperCol={{span: 9}}>*/}
                        {/*<Input value={data.mobile_number}*/}
                        {/*onChange={(e) => {*/}
                        {/*data.mobile_number = e.target.value;*/}
                        {/*this.setState({}, this.handleChange)*/}
                        {/*}}/>*/}
                        {/*</Form.Item>*/}
                        {/*</Col>*/}
                        {/*<Col span={24}>*/}
                        {/*<Form.Item label="车牌" labelCol={{span: 3}} wrapperCol={{span: 9}}>*/}
                        {/*<Input value={data.license_plate} onChange={(e) => {*/}
                        {/*data.license_plate = e.target.value;*/}
                        {/*this.setState({})*/}
                        {/*}}/>*/}
                        {/*</Form.Item>*/}
                        {/*</Col>*/}


                        <Col span={24}>
                            <Form.Item label="备注" labelCol={{span: 3}} wrapperCol={{span: 21}}>
                                <Input.TextArea value={data.remark}
                                                onChange={(e) => {
                                                    data.remark = e.target.value;
                                                    this.setState({})
                                                }}/>
                            </Form.Item>
                        </Col>

                    </Row>
                </div>
                <div style={{textAlign: "center"}}>
                    {(data.status === 0 || data.status === 1 || !data.id) &&
                    <Button htmlType={"submit"} type={"primary"} size={"large"} style={{width: 250}}>保存</Button>
                    }

                </div>
            </Form>
        )
    }
}

FormSanke.propTypes = {
    // 员工
    staff: PropTypes.array.isRequired,
    // 预约记录
    yuyue: PropTypes.array.isRequired,
    // 排班情况
    paiban: PropTypes.array.isRequired,
    // 当前日期
    date: PropTypes.string.isRequired,
    // 原始记录
    data: PropTypes.object,
    // 会员id
    user_id: PropTypes.number,
    onChange: PropTypes.func,
    onClose: PropTypes.func,
    onOk: PropTypes.func
};


const mapStateToProps = state => ({
    school: state.school,
    courses_type: state.courses_type,
    students: state.students,
    users_cards: state.users_cards
});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Form.create({name: 'calendar-list'})(FormSanke)));

