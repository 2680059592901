import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Modal, InputNumber, Form, message, Input, Select, Radio} from 'antd';
import {postUserCardAddFee} from "../../lib/api_users_cards";
import moment from 'moment';


class CardTimeFreeModal extends Component {
    formRef = React.createRef();

    state={
        begin_date: moment(),
        end_date: moment(),
        total_month: 12
    };

    handleClose = () => {
        this.props.onClose && this.props.onClose();
    };

    handleOK = () => {
        this.props.onOK && this.props.onOK();
    };

    componentDidMount(){
        this.dealData();
    }


    handleSubmit = e => {
        e.preventDefault();
        this.formRef.current.validateFields()
            .then(values => {
                console.log('Received values of form: ', values);

                postUserCardAddFee(this.props.id, {
                    annual_fee: values.annual_fee,
                    begin_date: this.state.begin_date.format("YYYY-MM-DD"),
                    end_date: this.state.end_date.format("YYYY-MM-DD"),
                    total_month: this.state.total_month,
                    remark: values.remark,
                })
                    .then(result => {
                        console.log(result);
                        // 刷新数据
                        if (result.code === 0) {
                            message.success("操作成功");
                            this.handleOK();
                        }
                    })
            });
    };

    handleYearChange = (e)=>{
        let year = e.target.value;
        let total_month= year*12;
        console.log(total_month);

        this.setState({
            total_month
        }, ()=>this.dealData())
    };

    dealData =()=>{
        let {data} = this.props;
        let  begin_date = moment(data.end_time);
        begin_date.add(1, 'd');
        let end_date = moment(begin_date);
        end_date.add(this.state.total_month, 'M');
        console.log(begin_date.format("YYYY-MM-DD"),end_date.format("YYYY-MM-DD"))
        this.setState({
            begin_date,
            end_date
        })
    };


    render() {

        const layout = {
            labelCol: {span: 8},
            wrapperCol: {span: 16},
        };

        return (
            <Modal
                title="续卡"
                visible={this.props.visible}
                onOk={this.handleSubmit}
                onCancel={this.handleClose}
                destroyOnClose={true}
            >

                <Form
                    {...layout}
                    onSubmit={this.handleSubmit}
                    ref={this.formRef}
                    initialValues={{
                        year: 1,
                        annual_fee:1,
                        remark: ""
                    }}
                >
                    <Form.Item
                        label="续卡时长(年)"
                        rules={[
                            {
                                required: true,
                                message: '请选择',
                            },
                        ]}
                    >
                        <Form.Item
                            name="year"
                            noStyle
                            rules={[
                                {
                                    required: true,
                                    message: '请选择',
                                },
                            ]}
                        >
                            <Radio.Group defaultValue={1} buttonStyle="solid" onChange={this.handleYearChange}>
                                <Radio.Button value={1}>1年</Radio.Button>
                                <Radio.Button value={2}>2年</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                        <div>
                        {this.state.begin_date.format("YYYY-MM-DD")} 至 {this.state.end_date.format("YYYY-MM-DD")}
                        </div>
                    </Form.Item>
                    <Form.Item
                        label="年费实付"
                        name="annual_fee"
                        rules={[
                            {
                                required: true,
                                message: '请输入总共支付年费',
                            },
                        ]}
                    >
                        <InputNumber min={1} precision={0}/>
                    </Form.Item>
                    <Form.Item
                        label="备注"
                        name="remark"
                        rules={[
                            {
                                required: true,
                                message: '请输入备注',
                            },
                        ]}
                    >
                        <Input.TextArea/>
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}

CardTimeFreeModal.propTypes = {
    id: PropTypes.number,
    visible: PropTypes.bool,
    onClose: PropTypes.func,
    onOK: PropTypes.func,
    data:PropTypes.object
};

export default CardTimeFreeModal;
