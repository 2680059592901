import axios from './rest-axios';

const getList = (tableName, params) => axios.get(`/v2/data/${tableName}`, params);
const getSingle = (tableName, where) => axios.get(`/v2/data/${tableName}/single`, where);
const postData = (tableName, data) => axios.post(`/v2/data/${tableName}`, data);
const putData = (tableName, data) => axios.put(`/v2/data/${tableName}`, data);

export {
    getList,
    getSingle,
    postData,
    putData
};
