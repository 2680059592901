import React from 'react';
import Icon from '@ant-design/icons';

const Editor = () => (
        <svg width="1em" height="1em" viewBox="0 0 155 125" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="VIP" fill-rule="nonzero">
                <path
                    d="M77.1396684,124.94898 C72.5478316,124.94898 68.2621174,123.010204 65.098852,119.642857 L4.48660723,53.9285715 C-1.1256377,47.8061225 -1.32971934,38.4183674 4.18048477,32.0918367 L27.1396684,5.66326523 C30.2008928,2.09183672 34.6906887,0.0510203125 39.4866072,0.0510203125 L115.098852,0.0510203125 C119.792729,0.0510203125 124.282525,2.09183672 127.34375,5.66326523 L150.404974,32.0918367 C155.915179,38.4183674 155.813138,47.8061225 150.098852,53.9285715 L89.0784439,119.642857 C86.0172195,123.010204 81.6294643,124.94898 77.1396684,124.94898 L77.1396684,124.94898 Z"
                    id="路径" fill="#F2CB51" />
                <path
                    d="M76.3233418,88.0102041 C75.3029336,88.0102041 74.1804846,87.6020408 73.3641582,86.7857143 L33.5682398,45.9693877 C32.0376275,44.3367346 32.0376275,41.7857143 33.6702807,40.1530611 C35.3029338,38.6224488 37.8539541,38.6224488 39.4866072,40.255102 L76.4253826,78.1122449 L115.302934,40.1530611 C116.935587,38.6224488 119.486607,38.6224488 121.11926,40.255102 C122.649872,41.8877551 122.649872,44.4387754 121.017219,46.0714285 L79.1804848,86.8877551 C78.3641582,87.6020408 77.34375,88.0102041 76.3233418,88.0102041 L76.3233418,88.0102041 Z"
                    id="路径" fill="#FFF7E1"/>
            </g>
        </svg>
    )
;

const IconWrapper = (props) => (
    <Icon component={Editor} {...props}/>
);

export default IconWrapper;