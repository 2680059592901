import React from 'react';
import PropTypes from 'prop-types';
import ConfigCheckbox from './config_checkbox';
import {roles} from '../../config';

const RolesCheckbox = ({value, onChange}) => {
    return (<ConfigCheckbox value={value} onChange={onChange} config={roles}/>)
};

RolesCheckbox.propTypes = {
    value: PropTypes.array,
    onChange: PropTypes.func,
};

export default RolesCheckbox;

