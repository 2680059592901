/**
 * @name: 一级菜单
 * @description:
 * @author: Wood
 * @date: 2021/4/25
 * @update:
 */
import PropTypes from 'prop-types';
import React from 'react';
import {Link} from "react-router-dom";
import {Menu, Dropdown} from 'antd';
import styles from './first-sider.module.css';
// import menuConfig from './menu.config';
import logo from '../../assets/images/logo.png';
// import AccountMenu from '../account-menu/account-menu2';
import {
    CalendarOutlined,
    CrownOutlined,
    UserOutlined,
    BookOutlined,
    CreditCardOutlined,
    ClockCircleOutlined,
    BarChartOutlined,
    SettingOutlined
} from '@ant-design/icons';

const getIcon = (name) => {
    switch (name) {
        case "CalendarOutlined":
            return <CalendarOutlined/>;
        case "CrownOutlined":
            return <CrownOutlined/>;
        case "UserOutlined":
            return <UserOutlined/>;
        case "BookOutlined":
            return <BookOutlined/>;
        case "CreditCardOutlined":
            return <CreditCardOutlined/>;
        case "ClockCircleOutlined":
            return <ClockCircleOutlined/>;
        case "BarChartOutlined":
            return <BarChartOutlined/>;
        case "SettingOutlined":
            return <SettingOutlined/>;
        default:
            return null;
    }
};

/**
 * 头像菜单
 * @type {XML}
 */
const menu = (
    <Menu>
        <Menu.Item>
            <a target="_blank" rel="noopener noreferrer" href="https://www.antgroup.com">
                1st menu item
            </a>
        </Menu.Item>

        <Menu.Item disabled>
            <a target="_blank" rel="noopener noreferrer" href="https://www.luohanacademy.com">
                3rd menu item
            </a>
        </Menu.Item>
        <Menu.Item danger>a danger item</Menu.Item>
    </Menu>
);

/**
 * 一级菜单
 * @param path 页面路径
 * @param onHover 鼠标移入事件
 * @constructor
 */
const FirstSider = ({path, onHover, menus}) => (
    <div className={styles.firstSider}>
        <div className={styles.logo}>
            Blf
        </div>

        <Menu
            mode="inline"
            theme="dark"
            defaultSelectedKeys={[path]}
            style={{
                background: "#273543"
            }}
        >
            {menus.map(menu => {
                // 存在子节点
                if (menu.children.length > 0) {
                    return (
                        <Menu.Item
                            key={menu.path}
                            icon={getIcon(menu.icon)}
                            className={styles.menuItem}
                            onMouseEnter={_ => onHover && onHover(menu)}
                        >
                            <Link to={menu.children[0].path}>
                                {menu.minititle}
                            </Link>
                        </Menu.Item>
                    )
                } else {
                    return (
                        <Menu.Item key={menu.path}
                                   icon={getIcon(menu.icon)}
                                   className={styles.menuItem}>
                            <Link to={menu.path}>
                                {menu.minititle}
                            </Link>
                        </Menu.Item>
                    )
                }
            })}
        </Menu>
    </div>
);

/**
 * 属性
 * @type {{path: "页面路径", onHover: function}}
 */
FirstSider.propTypes = {
    path: PropTypes.string,
    onHover: PropTypes.func,
};


export default FirstSider;