
import axios from './rest-axios';

const getStaff = () => axios.get(`/v2/staff`);
const getStaffDetail = (id) => axios.get(`/v2/staff/${id}`);
const postStaff = (model) => axios.post(`/v1/staff`, model);
const postStaff2 = (model) => axios.post(`/v2/staff`, model);
const putStaff = (model) => axios.put(`/v1/staff/${model.id}`, model);
const putStaff2 = (model) => axios.put(`/v2/staff/${model.id}`, model);
const putStaffFile = (id, is_file) => axios.put(`/v1/staff/${id}/file`, {is_file});
const postStaffFromQYWX = () => axios.post(`/v2/staff/syncfromqxwx`);
const getStaffConfig = () => axios.get(`/v2/staff/extattr`);
const postStaffCoursesFee = (id, ids) => axios.post(`/v2/staff/${id}/courses_fee`, {
    ids: ids
});
const deleteStaffCoursesFee = (user_id, id) => axios.delete(`/v2/staff/${user_id}/courses_fee/${id}`);

const getnewStaffFromQYWX = () => axios.get(`/v2/staff/syncnewfromqxwx`);
const postimportStaffFromQYWX = (userids) => axios.post(`/v2/staff/importfromqxwx`, {userids});

export {
    getStaff,
    postStaff,
    putStaff,
    putStaffFile,
    postStaffFromQYWX,
    getStaffDetail,
    getStaffConfig,
    postStaff2,
    putStaff2,
    postStaffCoursesFee,
    deleteStaffCoursesFee,
    getnewStaffFromQYWX,
    postimportStaffFromQYWX,
};
