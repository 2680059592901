import PropTypes from 'prop-types';
import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';

const MemberCardName = ({card_id, memberships, users_cards, showRemaining}) => {
    const [name, setname] = useState("");

    useEffect(() => {
        if (card_id.startsWith("membership_")) {
            let id = parseInt(card_id.substr(11));
            let membership = memberships.find(_ => _.id === id);
            if (membership) {
                let name = membership.name;
                if (showRemaining) {
                    name = `${membership.name}(余${(parseFloat(membership.fee_remaining) + parseFloat(membership.fee_free_remaining)).toFixed(2)})`
                }
                setname(name)
            } else {
                setname("会籍异常")
            }
        } else if (card_id.startsWith("card_")) {
            let id = parseInt(card_id.substr(5));
            let card = users_cards.find(_ => _.id === id);
            if (card) {
                let name = card.name;
                if (showRemaining) {
                    name = `${card.name}(余${card.keshi_remaining + card.keshi_free_remaining})`
                }
                setname(name)
            } else {
                setname("卡片异常")
            }
        } else {
            setname("支付模式异常")
        }
    }, []);

    return (
        <span>
            {name}
        </span>
    )
};


MemberCardName.propTypes = {
    card_id: PropTypes.string,
    showRemaining: PropTypes.bool
};
MemberCardName.defaultProps = {
    showRemaining: true
};
const mapStateToProps = state => ({
    memberships: state.memberships,
    users_cards: state.users_cards
});

const mapDispatchToProps = dispatch => ({});


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MemberCardName);


