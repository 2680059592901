import PropTypes from 'prop-types';
import React from 'react';
import {Modal, Form, InputNumber, Input} from 'antd';
import {postMembershipType} from "../../../lib/api_membership_type";


const MembershipTypeAddModal = ({visible, onOk, onCancel}) => {
    const [form] = Form.useForm();

    const handleSubmit = () => {
        form.validateFields()
            .then(values => {
                console.log(values)
                postMembershipType(values)
                    .then(res => {
                        if (res.code === 0) {
                            onOk && onOk(res.data);
                        }
                    })
            })
    };

    const formItemLayout = {
        labelCol: {
            xs: {span: 24},
            sm: {span: 6},
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 18},
        },
    };

    return (
        <Modal
            title="添加VIP会籍"
            visible={visible}
            // onOk={form.submit}
            onOk={handleSubmit}
            onCancel={onCancel}
            destroyOnClose={true}
        >
            <Form {...formItemLayout}
                  form={form}
                // onSubmit={this.handleSubmit}
                  initialValues={{
                      price: 0,
                      member_count: 0,
                      limit_days: 0,
                  }}
                // ref={this.formRef}
            >
                <Form.Item
                    label="会籍名称"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: '请输入会籍名称',
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="价格"
                    name="price"
                    rules={[
                        {
                            required: true,
                            message: '请输入价格',
                        },
                    ]}
                >
                    <InputNumber precision={2} min={0}/>
                </Form.Item>
                <Form.Item
                    label="成员数量"
                    name="member_count"
                    rules={[
                        {
                            required: true,
                            message: '请输入成员数量',
                        },
                    ]}
                >
                    <InputNumber min={0} step={1} precision={0}/>
                </Form.Item>
                <Form.Item
                    label="使用天数"
                    name="limit_days"
                    rules={[
                        {
                            required: true,
                            message: '请输入使用天数',
                        },
                    ]}
                >
                    <InputNumber min={0} step={1} precision={0}/>
                </Form.Item>

            </Form>
        </Modal>
    )
};

MembershipTypeAddModal.propTypes = {
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
    onOk: PropTypes.func,
};

export default MembershipTypeAddModal;
