import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {getMember, deleteCard} from "../../lib/api_members";
import {Form} from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
    Button,
    Table,
    Tabs,
    PageHeader,
    Descriptions,
    Modal,
    Input,
    DatePicker,
    message,
    Radio,
    Timeline,
    Tag,
    Space,
} from 'antd';
import PageContent from '../../components/page-content/page-content';
import MembersEditModal from './members-edit-modal';
import MembersResetModal from './members-reset-modal';
import MembersCardAddModal from './members-card-add-modal';
import moment from 'moment';
import Box from '../../components/box';
import StaffName from '../../components/staff_name';
import MembersChongZhiModal from './members-chongzhi-modal';
import ResidenceName from '../../components/residence_name';
import TrainTypeName from '../../components/config/train_type_name';
import CardEditModal from './card-edit-modal';
import {ExclamationCircleOutlined} from '@ant-design/icons';
import {getUserCardInfo} from "../../lib/api_users_cards";
import CardTimeFreeModal from './card-time-free-modal';
import CardKeShiFreeModal from './card-keshi-free-modal';
import CardXukaModal from './card-xuka-modal';

const {Column} = Table;
const {confirm} = Modal;

class MembersDetail extends Component {
    state = {
        data: null,

        showCardTimeFreeModal: false,
        showCardKeShiFreeModal: false,
        showCardXuKaModal: null,
        chongzhiModal: null,
    };


    handleClose = () => {
        this.props.onClose && this.props.onClose();
    };

    handleOK = () => {
        this.props.onOK && this.props.onOK();
    };


    componentDidMount() {
        this.fetch();
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    // 获取学生信息
    fetch = () => {
        getUserCardInfo(this.props.data.id)
            .then((result) => {
                if (result.code === 0) {
                    this.setState({
                        data: result.data,

                    })
                } else {

                }
            })
    };

    expandedRowRender = (record) => {
        console.log(record);
        return <Table dataSource={record.history} pagination={false}>
            <Column
                title="计划处理日期"
                dataIndex="date"
                key="date"
            />
            <Column
                title="计费金额"
                dataIndex="fee"
                key="fee"
            />
            <Column
                title="创建时间"
                dataIndex="create_on"
                key="create_on"
            />
            <Column
                title="处理时间"
                dataIndex="deal_time"
                key="deal_time"
            />
            <Column
                title="状态"
                dataIndex="status"
                key="status"
                render={(status) => {
                    return status === 1 ? "已处理" : "未处理"
                }}
            />
        </Table>;

    }


    render() {
        let {data} = this.state;
        if (!data) return null;

        return (

            <Modal
                title="卡片详情"
                visible={!!this.props.data}
                // onOk={this.handleSubmit}
                onCancel={this.handleClose}
                destroyOnClose={true}
                footer={false}
                width={800}
            >
                <Descriptions bordered size={"small"}
                              extra={[
                                  <Button style={{marginLeft: 12}}
                                          onClick={() => this.setState({showCardTimeFreeModal: true})}>赠送时长</Button>,
                                  <Button style={{marginLeft: 12}}
                                          onClick={() => this.setState({showCardKeShiFreeModal: true})}>赠送教练费</Button>,
                                  <Button type="primary" style={{marginLeft: 12}}
                                          onClick={() => this.setState({showCardXuKaModal: data})}>续卡</Button>,
                                  <Button type="primary" style={{marginLeft: 12}}
                                          onClick={() => this.setState({chongzhiModal: data})}>充值教练费</Button>,]}
                >
                    <Descriptions.Item label="名称">{data.name}</Descriptions.Item>
                    <Descriptions.Item label="开始时间">{moment(data.begin_time).format("YYYY-MM-DD")}</Descriptions.Item>
                    <Descriptions.Item label="结束时间">{moment(data.end_time).format("YYYY-MM-DD")}</Descriptions.Item>
                    <Descriptions.Item label="总教练费">{data.keshi_original.toFixed(2)}</Descriptions.Item>
                    <Descriptions.Item label="剩余教练费">{data.keshi_remaining.toFixed(2)}</Descriptions.Item>
                    <Descriptions.Item label="赠送教练费">{data.keshi_free_original.toFixed(2)}</Descriptions.Item>
                    <Descriptions.Item label="剩余赠送教练费">{data.keshi_free_remaining.toFixed(2)}</Descriptions.Item>
                </Descriptions>
                <Tabs defaultActiveKey="2">
                    <Tabs.TabPane tab={`年费记录`} key="1">
                        <Table
                            dataSource={data.fees}
                            rowKey={"id"}
                            pagination={false}
                            size="small"
                            expandable={{expandedRowRender: this.expandedRowRender}}
                        >
                            <Column
                                title="时间"
                                dataIndex="create_on"
                                key="create_on"
                            />
                            <Column
                                title="说明"
                                dataIndex="remark"
                                key="remark"
                            />
                            <Column
                                title="年费"
                                dataIndex="annual_fee"
                                key="annual_fee"
                            />
                            <Column
                                title="开始日期"
                                dataIndex="begin_date"
                                key="begin_date"
                            />
                            <Column
                                title="结束日期"
                                dataIndex="end_date"
                                key="end_date"
                            />
                            <Column
                                title="扣费周期"
                                dataIndex="total_month"
                                key="total_month"
                                render={(total_month) => `${total_month}个月`}
                            />
                            <Column
                                title="处理人"
                                dataIndex="create_by"
                                key="处理人"
                                render={(create_by) => {
                                    return <StaffName id={create_by}/>
                                }}
                            />
                        </Table>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={`教练费记录`} key="2">
                        <Table
                            dataSource={data.keshi_history}
                            rowKey={"id"}
                            size="small"
                            pagination={false}>
                            <Column
                                title="时间"
                                dataIndex="create_on"
                                key="create_on"
                            />
                            <Column
                                title="说明"
                                dataIndex="remark"
                                key="remark"
                            />
                            <Column
                                title="教练费"
                                dataIndex="keshi"
                                key="keshi"
                            />
                            <Column
                                title="赠送"
                                dataIndex="is_gift"
                                key="is_gift"
                                render={(is_gift) => is_gift === 1 ? "是" : ""}
                            />
                            {/*<Column*/}
                            {/*title="卡片"*/}
                            {/*dataIndex="card_id"*/}
                            {/*key="card_id"*/}
                            {/*render={(card_id) => {*/}
                            {/*let card = student_info.cards.find(item => item.id === card_id);*/}
                            {/*return card ? card.name : ""*/}
                            {/*}}*/}
                            {/*/>*/}
                            <Column
                                title="处理人"
                                dataIndex="create_by"
                                key="处理人"
                                render={(create_by) => {
                                    return <StaffName id={create_by}/>
                                }}
                            />
                            {/*<Column*/}
                            {/*title="操作"*/}
                            {/*dataIndex="id"*/}
                            {/*key="id"*/}
                            {/*render={(id, record) => (*/}
                            {/*<a onClick={() => this.setState({keshi_edit: {...record}})}>修改</a>*/}
                            {/*)}*/}
                            {/*/>*/}
                        </Table>

                    </Tabs.TabPane>

                </Tabs>
                <CardTimeFreeModal
                    id={data.id}
                    visible={this.state.showCardTimeFreeModal}
                    onClose={() => {
                        this.setState({showCardTimeFreeModal: false})
                    }}
                    onOK={() => {
                        this.fetch();
                        this.setState({showCardTimeFreeModal: false});
                    }}
                />
                <CardKeShiFreeModal
                    id={data.id}
                    visible={this.state.showCardKeShiFreeModal}
                    onClose={() => {
                        this.setState({showCardKeShiFreeModal: false})
                    }}
                    onOK={() => {
                        this.fetch();
                        this.setState({showCardKeShiFreeModal: false});
                    }}
                />
                <MembersChongZhiModal
                    onOK={() => {
                        this.fetch();
                        this.setState({chongzhiModal: null})
                    }}
                    onClose={() => {
                        this.setState({chongzhiModal: null})
                    }}
                    card={this.state.chongzhiModal}
                />
                {this.state.showCardXuKaModal &&
                <CardXukaModal
                    id={data.id}
                    data={this.state.showCardXuKaModal}
                    visible={this.state.showCardXuKaModal}
                    onClose={() => {
                        this.setState({showCardXuKaModal: null})
                    }}
                    onOK={() => {
                        this.fetch();
                        this.setState({showCardXuKaModal: null});
                    }}
                />
                }

            </Modal>
        )
    }
}


MembersDetail.propTypes = {
    data: PropTypes.object.isRequired,
};


const mapStateToProps = state => ({
    courses_type: state.courses_type
});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(MembersDetail));
