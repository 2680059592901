import PropTypes from 'prop-types';
import React, {useEffect, useState, useImperativeHandle} from 'react';
import {getList} from "../../lib/api_data";
import {Table, Tooltip, Dropdown, Menu, Checkbox, Space} from 'antd';
import styles from './data-table2.module.css';
import {ReloadOutlined, FullscreenOutlined, ColumnHeightOutlined} from '@ant-design/icons';
import {FullScreen, useFullScreenHandle} from "react-full-screen";


const DataTable = ({
                       table,
                       tableName,
                       pageQuery,
                       ...props
                   }) => {
    const [dataSource, setDataSource] = useState([]);
    const [pagination, setPagination] = useState(props.pagination);
    const [filters, setFilters] = useState(null);
    const [sorter, setSorter] = useState(props.sorter || {});
    const [loading, setLoading] = useState(false);
    const [size, setSize] = useState(props.size || "default");
    const handle = useFullScreenHandle();

    useImperativeHandle(table, () => ({
        refresh: () => {
            fetchData(pagination, filters, sorter)
        }
    }));




    // 获取数据
    const fetchData = (pagination, filters, sorter) => {
        // params = Object.assign({}, pageQuery, params);
        let params = pagination ? {
            current: pagination.current,
            pageSize: pagination.pageSize,
        } : {};

        params.filters = {...pageQuery, ...filters};
        params.sorter = sorter;

        setLoading(true);

        getList(tableName, params)
            .then(res => {
                console.log('response:', res);
                if (res && res.data) {
                    setDataSource(res.data);
                    if (pagination && pagination.current) {
                        setPagination({
                            ...pagination,
                            total: res.count
                        })
                    }
                    setFilters(filters);
                    setSorter(sorter);
                }
            })
            .catch(e => console.log('Oops, error', e))
            .finally(() => {
                setLoading(false)
            })
        ;
    };


    const columns = props.columns || [];
    columns.forEach(column => {
        // 枚举类型
        if (column.enum && !column.render) {
            column.render = (d) => {
                if (d in column.enum) {
                    return column.enum[d]
                }
                return d;
            }
        }
    });

    useEffect(() => {
        console.log(sorter);
        fetchData(pagination, null, sorter)
    }, []);

    const handleTableChange = (pagination, filters, sorter) => {
        console.log(pagination, filters, sorter);
        fetchData(pagination, filters, sorter);
        // params = Object.assign({}, pageQuery, params);
        //
        // return getList(tableName, params)
        //     .then(res => {
        //         console.log('response:', res);
        //         if (res && res.data) {
        //             return {
        //                 rows: res.data,
        //                 total: res.count
        //             }; // 注意一定要返回 rows 和 total
        //         }
        //     })
        //     .catch(e => console.log('Oops, error', e));
    };

    const menu = (
        <Menu>
            <Menu.Item onClick={() => setSize("default")}>
                默认
            </Menu.Item>
            <Menu.Item onClick={() => setSize("middle")}>
                中等
            </Menu.Item>
            <Menu.Item onClick={() => setSize("small")}>
                紧凑
            </Menu.Item>
        </Menu>
    );
    const filter_columns = (
        <Menu>
            <Menu.Item onClick={() => setSize("default")}>
                <Checkbox/>
                默认
            </Menu.Item>
            <Menu.Item onClick={() => setSize("middle")}>
                中等
            </Menu.Item>
            <Menu.Item onClick={() => setSize("small")}>
                紧凑
            </Menu.Item>
        </Menu>
    );

    return (
        <FullScreen handle={handle}>
            <div className={styles.wrapper}>
                <div className={styles.top}>
                    <div className={styles.title}>
                        {props.title}
                    </div>
                    <div className={styles.toolbar}>
                        <Space>
                            <Space>
                                {props.toolbar}
                            </Space>
                            <Space size={14} className={styles.actions}>
                                <Tooltip title="刷新">
                                    <ReloadOutlined onClick={() => fetchData(pagination)}/>
                                </Tooltip>
                                {/*<Dropdown overlay={filter_columns} trigger={['click']}>*/}
                                {/*<Tooltip title="筛选列">*/}
                                {/*<ColumnHeightOutlined/>*/}
                                {/*</Tooltip>*/}
                                {/*</Dropdown>*/}
                                <Dropdown overlay={menu} trigger={['click']}>
                                    <Tooltip title="表格密度">
                                        <ColumnHeightOutlined/>
                                    </Tooltip>
                                </Dropdown>
                                <Tooltip title="全屏" onClick={handle.enter}>
                                    <FullscreenOutlined/>
                                </Tooltip>
                            </Space>
                        </Space>
                    </div>
                </div>
                <Table
                    // headerTitle={headerTitle}
                    columns={columns}
                    rowKey="id"
                    size={size}
                    dataSource={dataSource}
                    pagination={pagination}
                    loading={loading}
                    style={{
                        padding: 0
                    }}
                    onChange={handleTableChange}
                />
            </div>
        </FullScreen>

    );
};

DataTable.propTypes = {
    tableName: PropTypes.string,
    search: PropTypes.object,
    table: PropTypes.object,
    pageQuery: PropTypes.object
};
export default DataTable;
