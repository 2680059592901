import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {postStaff} from "../../../lib/api_staff";
import '@ant-design/compatible/assets/index.css';
import {Modal, Input, Form, DatePicker, InputNumber,message} from 'antd';
import md5 from 'md5';
// import CoursesTypeCheck from './courses_type_check';
import RolesCheckbox from '../../../components/config/roles_checkbox';
import CoachLevelRadio from '../../../components/config/coach_level_radio';
import MemberCardSelecter from '../../calendar/member-card-selecter2';
import {postKeshiBatch} from "../../../lib/api_keshi_batch";

const {TextArea} = Input;

class StaffAddModal extends Component {
    formRef = React.createRef();

    state = {
        is_coach: 0
    };

    handleClose = () => {
        this.props.onClose && this.props.onClose();
    };

    handleOK = () => {
        this.props.onOK && this.props.onOK();
    };

    handleSubmit = e => {
        e.preventDefault();

        this.formRef.current.validateFields()
            .then(values => {
                console.log('Received values of form: ', values);
                values.set_time = values.set_time.format("YYYY-MM-DD HH:mm:00")
                if(!values.users){
                    return message.error("请选择会员");
                }
                let users = JSON.parse(values.users);
                if(!users || users.length === 0){
                    return message.error("请选择会员");
                }
                if(users.some(item=> item.card_id === 0)){
                    return message.error("请选择会员卡");
                }

                postKeshiBatch(values)
                    .then(result => {
                        console.log(result);
                        // 刷新数据
                        if (result.code === 0) {
                            this.handleOK();
                        }
                    })

                // postStaff({...values, password: md5(values.password)})
                //     .then(result => {
                //         console.log(result);
                //         // 刷新数据
                //         if (result.code === 0) {
                //             this.handleOK();
                //
                //         }
                //     })
            });
    };

    handleRolesChange = (values) => {
        // console.log(values)
        this.setState({
            is_coach: (values.includes(1) || values.includes(4)) ? 1 : 0
        })
    };

    render() {
        const {is_coach} = this.state;
        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 6},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 18},
            },
        };

        return (
            <Modal
                title="添加消课"
                visible={this.props.visible}
                onOk={this.handleSubmit}
                onCancel={this.handleClose}
                destroyOnClose={true}
                width={800}
            >
                <Form {...formItemLayout}
                    // onSubmit={this.handleSubmit}
                      initialValues={{
                          password: "123456"
                      }}
                      ref={this.formRef}
                >
                    <Form.Item
                        label="时间"
                        name="set_time"
                        rules={[
                            {
                                required: true,
                                message: '请选择时间',
                            },
                        ]}
                    >
                        <DatePicker format={"YYYY-MM-DD HH:mm"} showTime/>
                    </Form.Item>
                    <Form.Item
                        label="次卡扣除课时"
                        name="keshi"
                        rules={[
                            {
                                required: true,
                                message: '请输入课时',
                            },
                        ]}
                    >
                        <InputNumber/>
                    </Form.Item>
                    <Form.Item
                        label="VIP会籍扣除教练费"
                        name="payments"
                        rules={[
                            {
                                required: true,
                                message: '请输入教练费',
                            },
                        ]}
                    >
                        <InputNumber/>
                    </Form.Item>
                    <Form.Item
                        label="事由"
                        name="remark"
                        rules={[
                            {
                                required: true,
                                message: '请输入事由',
                            },
                        ]}
                    >
                        <TextArea autoSize={{minRows: 2, maxRows: 6}}/>

                    </Form.Item>
                    <Form.Item
                        label="会员卡"
                        name="users"
                        rules={[
                            {
                                required: true,
                                message: '请选择会员卡',
                            },
                        ]}
                    >
                        <MemberCardSelecter/>
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}

StaffAddModal.propTypes = {
    visible: PropTypes.bool,
    onClose: PropTypes.func,
    onOK: PropTypes.func,
};

export default StaffAddModal;
