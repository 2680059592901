import React, {Component} from 'react';
import {Link, withRouter, Switch, Route} from 'react-router-dom';
import PageContent from '../../components/page-content/page-content';
import {Button, Tabs} from 'antd';
import JiaoLian from './jiaolian';
import ZengKe from './zengke';
import ZengKeqd from './zengkeqd';
import HuiYuan from './huiyuan';
import HuiYuan2 from './huiyuan2';
import HuiYuan3 from './huiyuan3';
import StatDate from './day/index';
import StatDateDetail from './day/detail';
import StatMonth from './month/index';
import StatMonthDetail from './month/detail';

const {TabPane} = Tabs;
// @hasAuth
// class Staff extends Component {
//
//     render() {
//
//         return (
//             <PageContent title={"统计"}
//                          routes={[
//                              {
//                                  path: '',
//                                  breadcrumbName: <><span>首页</span></>,
//                              },
//                              {
//                                  path: '//stat',
//                                  breadcrumbName: '统计',
//                              },
//                          ]}>
//
//                 <Tabs tabPosition={"left"} defaultActiveKey="6">
//                     <TabPane tab="课时统计" key="1">
//                         <JiaoLian/>
//                     </TabPane>
//                     <TabPane tab="赠券统计" key="2">
//                         <ZengKe/>
//                     </TabPane>
//                     <TabPane tab="赠券记录" key="3">
//                         <ZengKeqd/>
//                     </TabPane>
//                     <TabPane tab="会员课时记录" key="4">
//                         <HuiYuan/>
//                     </TabPane>
//                     <TabPane tab="会员消费统计" key="5">
//                         <HuiYuan2/>
//                     </TabPane>
//                     <TabPane tab="会员消费统计2" key="6">
//                         <HuiYuan3/>
//                     </TabPane>
//
//                 </Tabs>
//
//
//                 {/*<Link to={"/stat/jiaolian"}>*/}
//                     {/*<Button>教练统计</Button>*/}
//                 {/*</Link>*/}
//                 {/*<Button>教练统计</Button>*/}
//             </PageContent>
//         );
//     }
// }
//
//
// export default withRouter(Staff);


const Router = () => (
    <Switch>
        <Route exact path="/stat/jiaolian"
               component={JiaoLian}/>
        <Route exact path="/stat/zengjuan1"
               component={ZengKe}/>
        <Route exact path="/stat/zengjuanjl"
               component={ZengKeqd}/>
        <Route exact path="/stat/huiyuan1"
               component={HuiYuan}/>
        <Route exact path="/stat/huiyuan2"
               component={HuiYuan2}/>
        <Route exact path="/stat/huiyuan3"
               component={HuiYuan3}/>
        <Route exact path="/stat/date"
               component={StatDate}/>
        <Route exact path="/stat/date/:date"
               component={({match}) => <StatDateDetail date={match.params.date}/>}/>

        <Route exact path="/stat/month"
               component={StatMonth}/>
        <Route exact path="/stat/month/:year/:month"
               component={({match}) => <StatMonthDetail year={match.params.year} month={match.params.month}/>}/>

    </Switch>
);

export default Router;


