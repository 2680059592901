import React from 'react';
import PropTypes from 'prop-types';
import ConfigNames from './config_names';
import {yuyue_types} from '../../config';

const YuYueTypesNames = ({value}) => {
    return <ConfigNames config={yuyue_types} value={value} />;
};

YuYueTypesNames.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array
    ]),
};

export default YuYueTypesNames;

