const SET_MENUS = 'menus/SET_MENUS';

const initialState = [];

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
        case SET_MENUS:
            // console.log("SET_STAFF", {...action.staff});
            return [...action.menus];
        default:
            return state;
    }
};

const setMenus = function (menus) {
    return {
        type: SET_MENUS,
        menus: menus
    }
};


export {
    reducer as default,
    initialState as initialMenusState,
    setMenus,
}