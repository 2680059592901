import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import { Input, Select, InputNumber} from 'antd';
import moment from 'moment';
// import {school_numbers} from '../../config';


class MemberNumber extends Component {
    state = {
        member_number: `HUMG${moment().format("YYYY")}0001`,
        member_school_number: "HUMG",
        member_year: moment().format("YYYY"),
        member_index: "0001",
    };

    componentDidMount() {
        this.setInfo(this.props)

    }

    componentWillReceiveProps(props) {
        console.log(props);
        this.setInfo(props)
    }

    setInfo = ({value}) => {
        if(value){
            this.setState({
                member_number: value.member_number,
                member_school_number: value.member_school_number,
                member_year: value.member_year,
                member_index: value.member_index,
            })
        }else{
            // this.setState({
            //     member_number: this.state.member_school_number + member_year + member_index
            // })
        }

    };

    handleNumberChange = () => {
        const {member_school_number, member_year, member_index} = this.state;
        const {onChange} = this.props;
        this.setState({
            member_number: member_school_number + member_year + member_index
        }, () => {
            onChange && onChange(this.state)
        })
    };

    // handleSchoolChange = (value) => {
    //     console.log(value);
    //     this.setState({
    //         member_school_number: value,
    //     }, this.handleNumberChange)
    // };

    // handleYearChange = (value) => {
    //     console.log(value);
    //     // 计算年份最大值
    //     let {students} = this.props;
    //
    //     let maxindex = students.filter(item=> item.member_year === value).reduce((a , b)=>{
    //         return b > parseInt(a.member_index) ? b : parseInt(a.member_index);
    //     }, 0);
    //     this.setState({
    //         member_year: value,
    //         member_index: (maxindex + 1).toString().padStart(4, "0")
    //     }, this.handleNumberChange)
    // };

    handleIndexChange = (value) => {
        console.log(value);
        this.setState({
            member_index: value.toString().padStart(4, "0"),
        }, this.handleNumberChange)
    };

    render() {
        const {member_number, member_school_number, member_year, member_index} = this.state;
        const {stores} = this.props;
        let years = [];
        for (let i = 2020; i < 2030; i++) {
            years.push(i.toString());
        }
        return (
            <Input.Group compact>
                <Select value={member_school_number}
                        disabled
                        // onChange={this.handleSchoolChange}
                >
                    {stores.map(item=> <Select.Option value={item.number}>{item.number}</Select.Option>)}
                </Select>
                <Select value={member_year}
                        disabled
                        // onChange={this.handleYearChange}
                >
                    {years.map(year => <Select.Option value={year}>{year}</Select.Option>)}
                </Select>
                <InputNumber
                    value={member_index}
                    formatter={value => value.padStart(4, "0")}
                    parser={value => parseInt(value).toString()}
                    min={0}
                    onChange={this.handleIndexChange}
                />
                <div style={{margin:"5px 8px"}}>编号：{member_number}</div>
            </Input.Group>
        )
    }
}


MemberNumber.propTypes = {
    value: PropTypes.object,
    onChange: PropTypes.func,
};

// export default MemberNumber;

const mapStateToProps = state => ({
    students: state.students,
    stores: state.stores
});

const mapDispatchToProps = dispatch => ({
});


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MemberNumber);


