import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Modal, InputNumber, Form,message, Input} from 'antd';
import {postUserCardAddKeShi} from "../../lib/api_users_cards";


class CardTimeFreeModal extends Component {
    formRef = React.createRef();


    handleClose = () => {
        this.props.onClose && this.props.onClose();
    };

    handleOK = () => {
        this.props.onOK && this.props.onOK();
    };


    handleSubmit = e => {
        e.preventDefault();
        this.formRef.current.validateFields()
            .then(values => {
                console.log('Received values of form: ', values);

                postUserCardAddKeShi(this.props.id, values)
                    .then(result => {
                        console.log(result);
                        // 刷新数据
                        if (result.code === 0) {
                            message.success("操作成功");
                            this.handleOK();
                        }
                    })
            });
    };


    render() {

        const layout = {
            labelCol: {span: 8},
            wrapperCol: {span: 16},
        };

        return (
            <Modal
                title="赠送教练费"
                visible={this.props.visible}
                onOk={this.handleSubmit}
                onCancel={this.handleClose}
                destroyOnClose={true}
            >

                <Form
                    {...layout}
                    onSubmit={this.handleSubmit}
                    ref={this.formRef}
                    initialValues={{
                        keshi: 1,
                        remark:""
                    }}
                >
                    <Form.Item
                        label="教练费"
                        name="keshi"
                        rules={[
                            {
                                required: true,
                                message: '请输入教练费',
                            },
                        ]}
                    >
                        <InputNumber min={1} precision={0}/>
                    </Form.Item>
                    <Form.Item
                        label="备注"
                        name="remark"
                        rules={[
                            {
                                required: true,
                                message: '请输入备注',
                            },
                        ]}
                    >
                        <Input.TextArea/>
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}

CardTimeFreeModal.propTypes = {
    id: PropTypes.number,
    visible: PropTypes.bool,
    onClose: PropTypes.func,
    onOK: PropTypes.func,
};

export default CardTimeFreeModal;
