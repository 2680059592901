import React from 'react';
import PropTypes from 'prop-types';
import ConfigCheckbox from './config_checkbox';
import {yuyue_types} from '../../config';

const YuYueTypesCheckbox = ({value, onChange}) => {
    return (<ConfigCheckbox value={value} onChange={onChange} config={yuyue_types}/>)
};

YuYueTypesCheckbox.propTypes = {
    value: PropTypes.array,
    onChange: PropTypes.func,
};

export default YuYueTypesCheckbox;

