import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Checkbox, Row, Col} from 'antd';
// import {courses_type} from "../../config";
// import {getCoursesType} from '../../lib/api_courses_type';

class CoursesTypeCheck extends Component {

    state = {
        list:[]
    };

    componentDidMount() {
        // this.fetchData()
    }

    // fetchData = () => {
    //     getCoursesType()
    //         .then(res=>{
    //             if(res.code===0){
    //                 this.setState({
    //                     list: res.data
    //                 })
    //             }
    //         })
    // };

    handleChange = (e) => {
        // console.log(e);
        this.props.onChange && this.props.onChange(JSON.stringify(e));
    };

    render() {
        let {value, courses_type} = this.props;
        console.log(value);
        if (value) {
            value = JSON.parse(value);
        } else {
            value = [];
        }
        // let {list} = this.state;

        return (
            <Checkbox.Group value={value} onChange={this.handleChange}>
                <Row>
                    {courses_type.map((item, index) =>
                        <Col span={12} key={index}>
                            <Checkbox value={item.id}>{item.name}</Checkbox>
                        </Col>)}
                </Row>
            </Checkbox.Group>
        )
    }
}

CoursesTypeCheck.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
};

const mapStateToProps = state => ({
    courses_type: state.courses_type,
});

const mapDispatchToProps = dispatch => ({

});


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CoursesTypeCheck);


