import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {getMemberYuyues} from "../../../lib/api_members";
import {Table, Tag, Typography, Button, Modal} from 'antd';
import moment from 'moment';
import StaffName from '../../../components/staff_name';
import Box from '../../../components/box/index';
import DictName from '../../../components/dict/dict-name';
import MemberCardName from '../../calendar/member-card-name';


const {Column} = Table;
const {Text} = Typography;

const DataTable = ({list}) => {
    return (
        <Table
            dataSource={list}
            rowKey={"id"}
            pagination={false}
            size="small"
        >
            <Column
                title="日期"
                dataIndex="begin_time"
                key="日期"
                render={(begin_time, item) => <span>
                    <span>{moment(begin_time).format("YYYY-MM-DD ddd")}</span>
                    <span>
                        {moment(item.begin_time).format("HH:mm ")}
                        - {moment(item.end_time).format("HH:mm")}
                    </span>
                    </span>}
            />

            <Column
                title="课程"
                dataIndex="type"
                key="课程"
                render={(type) =>
                    <DictName type_value={"course_type"} value={type}/>
                }
            />
            {/*<Column*/}
            {/*title="手机号"*/}
            {/*dataIndex="mobile_number"*/}
            {/*key="手机号"*/}
            {/*width={120}*/}
            {/*/>*/}
            {/*<Column*/}
            {/*title="车牌"*/}
            {/*dataIndex="license_plate"*/}
            {/*key="车牌"*/}
            {/*/>*/}
            <Column
                title="鞍时"
                dataIndex="anshi"
                width={60}
                key="鞍时"
            />
            <Column
                title="教练"
                key="教练"
                width={80}
                render={(item) => item.teacher ? <StaffName id={item.teacher}/> : ""}
            />

            <Column
                title="状态"
                dataIndex="status"
                key="状态"
                width={80}
                render={(status) => <span>
                    {status === -1 && <Text type={"secondary"}>已取消</Text>}
                    {status === 0 && <Text type={"warning"}>已预约</Text>}
                    {status === 1 && <Text type={"danger"}>上课中</Text>}
                    {status === 2 && <Text type={"success"}>已下课</Text>}
                </span>}
            />
            <Column
                title="扣费"
                dataIndex="card_id"
                key="扣费"
                render={(card_id) => <MemberCardName card_id={card_id} showRemaining={false} />}
            />



        </Table>
    )
}


const MemberYuyue = ({id}) => {
    const [cards, setcards] = useState([]);
    const [more, setmore] = useState(false);

    const fetchData = () => {
        getMemberYuyues(id)
            .then(res => {
                if (res.code === 0) {
                    setcards(res.data)
                }
            })
    };

    useEffect(fetchData, []);


    return (
        <Box title={`预约记录(${cards.length}条)`}
             extra={[
                 <Button type="link" onClick={() => setmore(true)}>查看更多</Button>
             ]}
        >
            <DataTable list={cards.slice(0, 5)}/>
            <Modal
                visible={more}
                title={`预约记录(${cards.length}条)`}
                footer={false}
                onCancel={() => setmore(false)}
                width={700}
            >
                <DataTable list={cards}/>
            </Modal>

        </Box>
    )
};


MemberYuyue.propTypes = {
    id: PropTypes.number.isRequired,
};


const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(MemberYuyue));
