import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {getMemberKeshis} from "../../../lib/api_members";
import {Table, Tag, Button,Modal} from 'antd';
import moment from 'moment';
import StaffName from '../../../components/staff_name';
import Box from '../../../components/box/index';


const {Column} = Table;

const DataTable = ({list}) => {
    return (
        <Table
            dataSource={list}
            rowKey={"id"}
            pagination={false}
            size="small"
        >
            <Column
                title="时间"
                dataIndex="createdAt"
                key="时间"
                width={180}
                render={(create_on) => moment(create_on).format("YYYY-MM-DD ddd HH:mm")}
            />
            <Column
                title="说明"
                dataIndex="remark"
                key="remark"
            />
            <Column
                title="课时"
                dataIndex="keshi"
                key="keshi"
                align={"right"}
                width={100}
            />
            {/*<Column*/}
            {/*title="卡片"*/}
            {/*dataIndex="card_id"*/}
            {/*key="card_id"*/}
            {/*render={(card_id) => {*/}
            {/*let card = student_info.cards.find(item => item.id === card_id);*/}
            {/*return card ? card.name : ""*/}
            {/*}}*/}
            {/*/>*/}
            <Column
                title="处理人"
                dataIndex="create_by"
                key="处理人"
                width={100}
                render={(create_by) => {
                    return <StaffName id={create_by}/>
                }}
            />
        </Table>
    )
}


const MemberYuyue = ({id}) => {
    const [cards, setcards] = useState([]);
    const [more, setmore] = useState(false);

    const fetchData = () => {
        getMemberKeshis(id)
            .then(res => {
                if (res.code === 0) {
                    setcards(res.data)
                }
            })
    };

    useEffect(fetchData, []);


    return (
        <Box title={`课时记录(${cards.length}条)`}
             extra={[
                 <Button type="link" onClick={() => setmore(true)}>查看更多</Button>
             ]}>
            <DataTable list={cards.slice(0, 5)}/>
            <Modal
                visible={more}
                title={`课时记录(${cards.length}条)`}
                footer={false}
                onCancel={() => setmore(false)}
                width={600}
            >
                <DataTable list={cards} />
            </Modal>
        </Box>
    )
};


MemberYuyue.propTypes = {
    id: PropTypes.number.isRequired,
};


const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(MemberYuyue));
