import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {putStaff} from "../../../lib/api_staff";
import '@ant-design/compatible/assets/index.css';
import {Modal, Input, Form, InputNumber, Divider, Radio, DatePicker} from 'antd';
import CoursesTypeCheck from './courses_type_check';
import RolesCheckbox from '../../../components/config/roles_checkbox';
import CoachLevelRadio from '../../../components/config/coach_level_radio';
import moment from 'moment';

const formItemLayout = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 6},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 18},
    },
};

class StaffEditModal extends Component {
    formRef = React.createRef();

    state = {
        is_coach: 0
    };

    componentWillReceiveProps(props) {
        if (props.user_info && this.state.is_coach !== props.user_info.is_coach) {
            this.setState({
                is_coach: props.user_info.is_coach
            })
        }
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    handleClose = () => {
        this.props.onClose && this.props.onClose();
    };

    handleOK = () => {
        this.props.onOK && this.props.onOK();
    };

    handleSubmit = e => {
        e.preventDefault();
        this.formRef.current.validateFields()
            .then(values => {
                console.log('Received values of form: ', values);
                values.id = this.props.user_info.id;
                values.birthday = values.birthday ? values.birthday.format("YYYY-MM-DD") : null;
                putStaff(values)
                    .then(result => {
                        console.log(result);
                        // 刷新数据
                        if (result.code === 0) {
                            this.handleOK();
                        }
                    })
            });
    };

    handleRolesChange = (values) => {
        // console.log(values)
        this.setState({
            is_coach: (values.includes(1) || values.includes(4)) ? 1 : 0
        })
    };

    handleIdChange = (e) => {
        console.log(e.target.value);
        let id = e.target.value;
        if (id.length === 18 || id.length === 15) {
            let birthYearSpan = id.length === 18 ? 4 : 2;
            let year = (birthYearSpan === 2 ? '19' : '') + id.substr(6, birthYearSpan);
            let month = id.substr(6 + birthYearSpan, 2);
            let day = id.substr(8 + birthYearSpan, 2);
            let birthday = year + '-' + month + '-' + day;
            console.log(birthday);
            this.formRef.current.setFieldsValue({
                birthday: moment(birthday)
            })
        }
    }

    render() {
        const {is_coach} = this.state;
        const {user_info} = this.props;


        if (user_info) {
            return (

                <Modal
                    title="修改员工信息"
                    visible={!!this.props.user_info}
                    onOk={this.handleSubmit}
                    onCancel={this.handleClose}
                    destroyOnClose={true}
                    width={600}
                >
                    <Form {...formItemLayout}
                        // onSubmit={this.handleSubmit}
                          initialValues={{
                              nick_name: user_info.nick_name,
                              mobile_number: user_info.mobile_number,
                              roles: user_info.roles || [],
                              courses_type: user_info.courses_type,
                              teacher_level: user_info.teacher_level,
                              remark: user_info.remark,
                              staff_order_by: user_info.staff_order_by,
                              staff_email: user_info.staff_email,
                              staff_card_name: user_info.staff_card_name,
                              staff_card_bank: user_info.staff_card_bank,
                              staff_card_number: user_info.staff_card_number,

                              staff_group: user_info.staff_group,
                              staff_en_name: user_info.staff_en_name,
                              staff_wx_id: user_info.staff_wx_id,
                              sex: user_info.sex,
                              staff_id: user_info.staff_id,
                              birthday: user_info.birthday ? moment(user_info.birthday) : null,
                              staff_huji_address: user_info.staff_huji_address,
                              staff_contact_name: user_info.staff_contact_name,
                              staff_contact_relation: user_info.staff_contact_relation,
                              staff_contact_phone: user_info.staff_contact_phone,
                              staff_address:user_info.staff_address,

                          }}
                          ref={this.formRef}
                    >
                        <Form.Item
                            label="中文名"
                            name="nick_name"
                            rules={[
                                {
                                    required: true,
                                    message: '请输入中文名',
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label="英文名"
                            name="staff_en_name"
                            rules={[
                                {
                                    required: true,
                                    message: '请输入英文名',
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label="职位"
                            name="roles"
                            rules={[
                                {
                                    required: true,
                                    message: '请选择职位',
                                },
                            ]}
                        >
                            <RolesCheckbox onChange={this.handleRolesChange}/>
                        </Form.Item>
                        <Form.Item
                            label="部门"
                            name="staff_group"
                            rules={[
                                {
                                    required: true,
                                    message: '请输入部门',
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label="手机号"
                            name="mobile_number"
                            rules={[
                                {
                                    required: true,
                                    message: '请输入手机号码',
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label="微信号"
                            name="staff_wx_id"
                            rules={[
                                {
                                    required: true,
                                    message: '请输入微信号',
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label="电子邮箱"
                            name="staff_email"
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label="性别"
                            name="sex"
                            rules={[
                                {
                                    required: true,
                                    message: '请选择性别',
                                },
                            ]}
                        >
                            <Radio.Group>
                                <Radio value={1}>男</Radio>
                                <Radio value={2}>女</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            label="身份证号"
                            name="staff_id"
                            rules={[
                                {
                                    required: true,
                                    message: '请输入身份证号',
                                },
                            ]}
                        >
                            <Input onChange={this.handleIdChange}/>
                        </Form.Item>
                        <Form.Item
                            label="生日"
                            name="birthday"
                        >
                            <DatePicker/>
                        </Form.Item>

                        {(is_coach === 1) && <>
                        <Form.Item
                            label="授课类型"
                            name="courses_type"
                        >
                            <CoursesTypeCheck/>
                        </Form.Item>
                        <Form.Item
                            label="教练级别"
                            name="teacher_level"
                        >
                            <CoachLevelRadio/>
                        </Form.Item>

                        </>}
                        <Form.Item
                            label="备注"
                            name="remark"
                        >
                            <Input.TextArea/>
                        </Form.Item>

                        <Form.Item
                            label="职级"
                            name="staff_order_by"
                            rules={[
                                {
                                    required: true,
                                    message: '请输入职级',
                                },
                            ]}
                        >
                            <InputNumber/>
                        </Form.Item>

                        <Form.Item
                            label="户籍地址"
                            name="staff_huji_address"
                            rules={[
                                {
                                    required: true,
                                    message: '请输入户籍地址',
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label="家庭住址"
                            name="staff_address"
                        >
                            <Input/>
                        </Form.Item>
                        <Divider orientation="left">紧急联系人</Divider>

                        <Form.Item
                            label="姓名"
                            name="staff_contact_name"
                            rules={[
                                {
                                    required: true,
                                    message: '请输入姓名',
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            label="关系"
                            name="staff_contact_relation"
                            rules={[
                                {
                                    required: true,
                                    message: '请输入关系',
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            label="电话"
                            name="staff_contact_phone"
                            rules={[
                                {
                                    required: true,
                                    message: '请输入电话',
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>


                        <Divider orientation="left">银行卡信息</Divider>
                        <Form.Item
                            label="户名"
                            name="staff_card_name"
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label="开户行"
                            name="staff_card_bank"
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label="账号"
                            name="staff_card_number"
                        >
                            <Input/>
                        </Form.Item>
                    </Form>
                </Modal>
            )
        } else {
            return null;
        }
    }
}

StaffEditModal.propTypes = {
    onClose: PropTypes.func,
    onOK: PropTypes.func,
    user_info: PropTypes.object
};

export default StaffEditModal;
