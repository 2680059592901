import PropTypes from 'prop-types';
import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {Checkbox, Row, Col, Table, Select, Input} from 'antd';
import MemberCardName from './member-card-name';
// import {courses_type} from "../../config";
// import {getCoursesType} from '../../lib/api_courses_type';
const {Column} = Table;

const MemberCardSelecter = ({students, value, onChange}) => {
    const [list, setlist] = useState([]);

    const onMemberChange = (id) => {
        // console.log(id);
        if (list.some(item => item.user_id === id)) {
            return;
        }
        let student = students.find(item => item.id === id);
        if (student) {
            // console.log(student);
            let cards = [];
            // 会籍
            if (student.membership && student.membership.status === 0) {
                // console.log(parseFloat(student.membership.fee_remaining));
                // console.log(parseFloat(student.membership.fee_free_remaining));
                // console.log((parseFloat(student.membership.fee_remaining) + parseFloat(student.membership.fee_free_remaining)).toFixed(2));
                cards.push({
                    value: `membership_${student.membership.id}`,
                    text: `${student.membership.name} (余${(parseFloat(student.membership.fee_remaining) + parseFloat(student.membership.fee_free_remaining)).toFixed(2)})`
                })
            }
            if (student.users_cards && student.users_cards.length > 0) {
                for (let i in student.users_cards) {
                    let card = student.users_cards[i];
                    if(card.status === 0){
                        cards.push({
                            value: `card_${card.id}`,
                            text: `${card.name} (余${card.keshi_remaining + card.keshi_free_remaining})`
                        })
                    }
                }

            }
            let card_id = cards.length > 0 ? cards[0].value : "";
            list.push({
                user_id: student.id,
                nick_name: student.nick_name,
                card_id: card_id,
                cards: cards,
                mobile_number: student.mobile_number,
                license_plate: student.license_plate,
            });
            // setlist([...list]);
            handleChange(list)
        }
    };



    const handleDelete = (id) => {
        let list2 = list.filter(item => item.user_id !== id);
        // setlist([...list2]);
        handleChange(list2)
    };

    const handleChange = (list)=>{
        onChange && onChange(JSON.stringify(list));
    };


    const handleCardChange = (record, id) =>{
        record.card_id = id;
        handleChange(list)
    };

    // useEffect(() => {
    //     onChange && onChange(JSON.stringify(list))
    // }, [list]);

    useEffect(()=>{
        if(value){
            // let list = JSON.parse(value);
            // list.forEach(item=>{
            //     onMemberChange(item.user_id)
            // });
            console.log(value);
            setlist(JSON.parse(value))
        }
    }, [value]);

    return (
        <div>
            <Select
                showSearch
                placeholder="搜索会员"
                optionFilterProp="children"
                onChange={onMemberChange}
            >
                {students.map(item =>
                    <Select.Option value={item.id} key={item.id}>
                        {item.nick_name} {item.mobile_number} {item.py}
                    </Select.Option>
                )}

            </Select>
            <Table
                dataSource={list}
                rowKey={"user_id"}
                pagination={false}
                size={"small"}
                bordered
            >
                <Column
                    title="姓名"
                    dataIndex="nick_name"
                    key="姓名"
                    width={60}
                />
                <Column
                    title="支付方式"
                    dataIndex="card_id"
                    key="支付方式"
                    render={(card_id, record) => {
                        if (record.cards.length === 0) {
                            return <span style={{color: "red"}}>无会员卡</span>
                        }
                        return <Select
                            value={card_id}
                            onChange={(id) => handleCardChange(record, id)}
                        >
                            {record.cards.map(card =>
                                <Select.Option value={card.value} key={card.value}>
                                    <MemberCardName card_id={card.value}/>
                                    {/*{card.text}*/}
                                </Select.Option>
                            )}
                        </Select>
                    }}
                />
                <Column
                    title="电话"
                    dataIndex="mobile_number"
                    key="电话"
                    width={150}
                    render={(mobile_number, record) => {
                        return <Input
                            value={mobile_number}
                            onChange={(e) => {
                                record.mobile_number = e.target.value;
                                handleChange(list);
                                // setlist([...list]);
                            }}
                        />
                    }}
                />
                <Column
                    title="车牌"
                    dataIndex="license_plate"
                    key="车牌"
                    width={120}
                    render={(license_plate, record) => {
                        return <Input
                            value={license_plate}
                            onChange={(e) => {
                                record.license_plate = e.target.value;
                                handleChange(list);
                                // setlist([...list]);
                            }}
                        />
                    }}
                />
                <Column
                    title="操作"
                    key="操作"
                    width={50}
                    render={(record) => {
                        return (<a onClick={() => handleDelete(record.user_id)}>删除</a>)
                    }}
                />
            </Table>
        </div>
    )
};


MemberCardSelecter.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
};

const mapStateToProps = state => ({
    students: state.students,
});

const mapDispatchToProps = dispatch => ({});


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MemberCardSelecter);


