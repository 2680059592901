import React from 'react';
import PropTypes from 'prop-types';
import {coach_level} from '../../config'
import ConfigRadio from './config_radio';

const CoachLevelRadio = ({value, onChange}) => {
    return (
        <ConfigRadio config={coach_level} value={value} onChange={onChange}/>
    )
};

CoachLevelRadio.propTypes = {
    value: PropTypes.number,
    onChange: PropTypes.func,
};

export default CoachLevelRadio;

