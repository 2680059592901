import React, {Component} from 'react';
import {withRouter, Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {PlusOutlined, ExclamationCircleOutlined} from '@ant-design/icons';
import {Button, Table, Divider, Switch, message, Modal} from 'antd';
import PageContent from '../../../components/page-content/page-content';
import KeshiBatchAddModal from './keshi_batch-add-modal';
// import StaffEditModal from './staff-edit-modal';
// import StaffResetModal from './staff-reset-modal';
import {getStaff, putStaffFile} from "../../../lib/api_staff";
import Box from '../../../components/box/index';
import RolesNames from '../../../components/config/roles_names';
import ConfigNames from '../../../components/config/config_names';
import CoachLevelName from '../../../components/config/coach_level_name';
import {setStaff} from "../../../reducers/staff";
import {hasAuth} from '../../../hasAuthentication';
import AuthComponent from '../../../components/auth_component';
import {getKeshiBatch,deleteKeshiBatch} from "../../../lib/api_keshi_batch";
import moment from 'moment';

const {Column} = Table;
const {confirm} = Modal;

// @hasAuth
@AuthComponent
class Staff extends Component {
    state = {
        list: [],

        showAddModal: false,
        editUser: null,
        resetUser: null
    };

    componentDidMount() {
        // console.log(this);
        // this.hasRole();
        this.loadData()
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    loadData = () => {
        getKeshiBatch()
            .then((result) => {
                if (result.code === 0) {
                    this.setState({
                        list: result.data
                    });
                } else {

                }
            })
    };

    showAddModal = () => {
        this.setState({
            showAddModal: true
        })
    };
    hideAddModal = () => {
        this.setState({
            showAddModal: false
        })
    };

    handleDelete = (item)=>{
        confirm({
            title: '警告！',
            icon: <ExclamationCircleOutlined />,
            content: '是否撤销该消课记录，相关课时将会恢复。',
            okText: '确定撤销',
            okType: 'danger',
            cancelText: '关闭',
            onOk:()=> {
                deleteKeshiBatch(item.id)
                    .then(res=>{
                        if(res.code ===0){
                            this.loadData();
                        }
                    })
            },
            onCancel:()=> {
                console.log('Cancel');
            },
        });
    };


    render() {
        let {list} = this.state;
        let {courses_type} = this.props;

        return (
            <PageContent title={"手动消课"}
                         routes={[
                             {
                                 path: '',
                                 breadcrumbName: <><span>首页</span></>,
                             },
                             {
                                 path: '//keshi_batch',
                                 breadcrumbName: '手动消课',
                             },
                         ]}>
                <Box>
                    <Button type='primary' icon={<PlusOutlined/>} onClick={this.showAddModal}
                            style={{marginBottom: 16}}>添加消课</Button>
                    <Table
                        dataSource={list}
                        rowKey={"id"}
                        pagination={false}
                        size={"small"}
                    >
                        <Column
                            title="编号"
                            dataIndex="id"
                            key="编号"
                            width={50}
                        />

                        <Column
                            title="时间"
                            dataIndex="set_time"
                            key="时间"
                            width={150}
                            render={(set_time) => moment(set_time).format("YYYY-MM-DD HH:mm")}
                        />
                        <Column
                            title="课时"
                            dataIndex="keshi"
                            key="课时"
                            width={50}
                        />
                        <Column
                            title="教练费"
                            dataIndex="payments"
                            key="教练费"
                            width={80}
                        />

                        <Column
                            title="事由"
                            dataIndex="remark"
                            key="事由"
                            ellipsis={true}
                        />
                        <Column
                            title="会员卡"
                            dataIndex="users"
                            key="会员卡"
                            render={(users) => {
                                if (!users) return "";
                                let list = JSON.parse(users);
                                if (!list || list.length === 0) {
                                    return "";
                                }
                                return list.map(mc=>{
                                    let student = this.props.students.find(item=> item.id === mc.user_id);
                                    let cards =  this.props.users_cards.filter(item => item.user_id === student.id);
                                    let card = cards.find(item=> item.id===mc.card_id);
                                    if(!card){
                                        return "111"
                                    }
                                    return <div><Link to={`/members/${student.id}`}>{student.nick_name}</Link>: {card.name}</div>
                                })
                            }}
                        />
                        <Column
                            title="创建时间"
                            dataIndex="create_on"
                            width={170}
                            key="创建时间"
                        />
                        <Column
                            title="创建人"
                            dataIndex="create_by"
                            key="创建人"
                        />

                        <Column
                            title="操作"
                            key="action"
                            align="center"
                            width={50}
                            render={(text, record) => (
                                <div>
                                    <Button type="link" size="small" onClick={() => {
                                        this.handleDelete(record)
                                    }}>撤销</Button>
                                </div>
                            )}
                        />
                    </Table>
                    {/*<StaffResetModal*/}
                    {/*onOK={() => this.setState({resetUser: null})}*/}
                    {/*onClose={() => this.setState({resetUser: null})}*/}
                    {/*user_info={this.state.resetUser}/>*/}
                    <KeshiBatchAddModal
                        onOK={() => {
                            this.loadData();
                            this.hideAddModal()
                        }}
                        onClose={this.hideAddModal}
                        visible={this.state.showAddModal}
                    />

                    {/*<StaffEditModal*/}
                    {/*onOK={() => {*/}
                    {/*this.loadData();*/}
                    {/*this.setState({editUser: null})*/}
                    {/*}}*/}
                    {/*onClose={() => {*/}
                    {/*this.setState({editUser: null})*/}
                    {/*}}*/}
                    {/*user_info={this.state.editUser}*/}
                    {/*/>*/}
                </Box>
            </PageContent>
        );
    }
}

Staff.propTypes = {};

const mapStateToProps = state => ({
    courses_type: state.courses_type,
    students: state.students,
    users_cards: state.users_cards,
});

const mapDispatchToProps = dispatch => ({
    setStaff: (staff) => dispatch(setStaff(staff)),
});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Staff));



