import axios from './rest-axios';

const getPaiBan = (begin_time, end_time) => axios.get(`/v1/paiban`, {begin_time, end_time});
const postPaiBan = (model) => axios.post(`/v1/paiban`, model);
const deletePaiBan = (model) => axios.delete(`/v1/paiban`, model);


export {
    getPaiBan,
    postPaiBan,
    deletePaiBan
};
