import PropTypes from 'prop-types';
import React, {Component, useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {Modal, Input, message, Form, Descriptions} from 'antd';
import {getMembershipInfo} from "../../../lib/api_membership";
import moment from 'moment';

const MembershipInfo = ({id}) => {
    const [data, setdata] = useState({});

    useEffect(()=>{
        getMembershipInfo(id)
            .then(res=>{
                if(res.code === 0){
                    setdata(res.data)
                }
            })
    }, []);


    return (
        <Descriptions bordered size={"small"}>
            <Descriptions.Item label="会籍名称"><Link to={`/member/member_ship/${id}`}>{data.name}</Link></Descriptions.Item>
            {/*<Descriptions.Item label="指导价格">{data.price}</Descriptions.Item>*/}
            <Descriptions.Item
                label="开始日期">{data.begin_time ? moment(data.begin_time).format("YYYY-MM-DD") : ""}
            </Descriptions.Item>
            <Descriptions.Item label="剩余教练费">{data.fee_remaining}</Descriptions.Item>
            <Descriptions.Item label="成员数量">{data.member_count}</Descriptions.Item>
            <Descriptions.Item
                label="结束日期">{data.end_time ? moment(data.end_time).format("YYYY-MM-DD") : ""}
            </Descriptions.Item>
            {/*<Descriptions.Item label="实际支付年费">{data.payments}</Descriptions.Item>*/}
            <Descriptions.Item label="剩余赠送教练费">{data.fee_free_remaining}</Descriptions.Item>

            {/*<Descriptions.Item label="剩余年费">{data.payments_remaining}</Descriptions.Item>*/}
            <Descriptions.Item label="状态">{data.status === 0 ? "正常": "失效"}</Descriptions.Item>

            <Descriptions.Item label="剩余天数">{data.limit_days}</Descriptions.Item>
        </Descriptions>
    )

};


MembershipInfo.propTypes = {
    id: PropTypes.number
};

export default MembershipInfo;
