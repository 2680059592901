import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {putCard} from "../../lib/api_members.js";
import {Modal, Input, Row, Col, Radio, DatePicker, Form, Cascader, InputNumber} from 'antd';
import moment from 'moment';
import TeacherSelect from './teacher-select';
import MemberNumber from './member-number-edit';
import options from '../../lib/cascader-address-options';


class CardEditModal extends Component {
    formRef = React.createRef();

    state = {};

    handleClose = () => {
        this.props.onClose && this.props.onClose();
    };

    handleOK = () => {
        this.props.onOK && this.props.onOK();
    };

    componentDidMount() {
        // this.Jisuan();
        if (this.props.data) {
            this.setState({
                avg: (this.props.data.payments / this.props.data.keshi_original).toFixed(2)
            })
        }
    }

    componentWillReceiveProps(props) {
        // this.Jisuan()
        if (props.data) {
            this.setState({
                avg: (props.data.payments / props.data.keshi_original).toFixed(2)
            })
        }
    }


    Jisuan = () => {
        if (this.formRef.current) {
            let payments = this.formRef.current.getFieldValue("payments");
            let keshi_original = this.formRef.current.getFieldValue("keshi_original");
            this.setState({
                avg: (payments / keshi_original).toFixed(2)
            })
        }
    };

    handleSubmit = e => {
        e.preventDefault();
        let {data} = this.props;
        this.formRef.current.validateFields()
            .then(values => {
                console.log('Received values of form: ', values);
                // console.log(this.props.user_info.id)
                values.begin_time = values.begin_time.format("YYYY-MM-DD");
                values.end_time = values.end_time.format("YYYY-MM-DD");
                putCard({...data, ...values})
                    .then(result => {
                        console.log(result);
                        // 刷新数据
                        if (result.code === 0) {
                            this.handleOK();
                        } else {

                        }
                    })
            });
    };

    renderCiKa = () => {
        const layout = {
            labelCol: {span: 8},
            wrapperCol: {span: 16},
        };

        const {data} = this.props;
        console.log(data);

        if (!this.props.data) {
            return null;
        }

        return (
            <Form
                {...layout}
                // className="ant-advanced-search-form"
                // onSubmit={this.handleSubmit}
                ref={this.formRef}
                initialValues={{
                    begin_time: moment(data.begin_time),
                    end_time: moment(data.end_time),
                    keshi_original: data.keshi_original,
                    status: data.status,
                    payments: data.payments
                }}
            >

                <Form.Item
                    label="开始日期"
                    name="begin_time"
                    rules={[
                        {
                            required: true,
                            message: '请选择开始日期',
                        },
                    ]}
                >
                    <DatePicker/>
                </Form.Item>

                <Form.Item
                    label="结束日期"
                    name="end_time"
                    rules={[
                        {
                            required: true,
                            message: '请选择结束日期',
                        },
                    ]}
                >
                    <DatePicker/>
                </Form.Item>

                <Form.Item
                    label="总课时"
                    name="keshi_original"
                    rules={[
                        {
                            required: true,
                            message: '请输入总课时',
                        },
                    ]}
                >
                    <InputNumber onChange={this.Jisuan}/>
                </Form.Item>

                <Form.Item
                    label="状　　态"
                    name="status"
                >
                    <Radio.Group>
                        <Radio value={0}>有效</Radio>
                        <Radio value={1}>失效</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    label="支付金额"
                    rules={[
                        {
                            required: true,
                            message: '请输入支付金额',
                        },
                    ]}>
                    <Form.Item
                        // label="支付金额"
                        name="payments"
                        noStyle
                        rules={[
                            {
                                required: true,
                                message: '请输入支付金额',
                            },
                        ]}
                    >
                        <InputNumber onChange={this.Jisuan}/>

                    </Form.Item>
                    <div>
                        每课时：{this.state.avg}元
                    </div>
                </Form.Item>

            </Form>

        )
    };

    renderNianKa = () => {
        const layout = {
            labelCol: {span: 8},
            wrapperCol: {span: 16},
        };

        const {data} = this.props;
        console.log(data);

        if (!this.props.data) {
            return null;
        }

        return (
            <Form
                {...layout}
                // className="ant-advanced-search-form"
                // onSubmit={this.handleSubmit}
                ref={this.formRef}
                initialValues={{
                    begin_time: moment(data.begin_time),
                    end_time: moment(data.end_time),
                    keshi_original: data.keshi_original,
                    status: data.status,
                    payments: data.payments,
                    annual_fee: data.annual_fee,
                }}
            >

                <Form.Item
                    label="开始日期"
                    name="begin_time"
                    rules={[
                        {
                            required: true,
                            message: '请选择开始日期',
                        },
                    ]}
                >
                    <DatePicker/>
                </Form.Item>

                <Form.Item
                    label="结束日期"
                    name="end_time"
                    rules={[
                        {
                            required: true,
                            message: '请选择结束日期',
                        },
                    ]}
                >
                    <DatePicker/>
                </Form.Item>

                <Form.Item
                    label="教练费"
                    name="keshi_original"
                    rules={[
                        {
                            required: true,
                            message: '请输入总课时',
                        },
                    ]}
                >
                    <InputNumber onChange={this.Jisuan}/>
                </Form.Item>

                <Form.Item
                    label="状　　态"
                    name="status"
                >
                    <Radio.Group>
                        <Radio value={0}>有效</Radio>
                        <Radio value={1}>失效</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    // label="支付金额"
                    label="年费"
                    name="annual_fee"
                    rules={[
                        {
                            required: true,
                            message: '请输入年费',
                        },
                    ]}
                >
                    <InputNumber onChange={this.Jisuan}/>
                </Form.Item>
                {/*<div>*/}
                {/*每课时：{this.state.avg}元*/}
                {/*</div>*/}
            </Form>

        )
    };


    render() {

        const layout = {
            labelCol: {span: 8},
            wrapperCol: {span: 16},
        };

        const {data} = this.props;
        console.log(data);

        if (!this.props.data) {
            return null;
        }
        return (
            <Modal
                title="修改会员卡信息"
                visible={!!this.props.data}
                onOk={this.handleSubmit}
                onCancel={this.handleClose}
                // confirmLoading={this.state.editModalConfirmLoading}
                // okText="确认"
                // cancelText="取消"
                // footer={null}
                // width={800}
                destroyOnClose={true}
            >
                {data.card_type === 1 ? this.renderNianKa() : this.renderCiKa()}
            </Modal>

        )

    }

}


CardEditModal.propTypes = {
    onClose: PropTypes.func,
    onOK: PropTypes.func,
    data: PropTypes.object,
};

export default CardEditModal;
