/**
 * @name: 学生相关接口
 * @description:
 * @author: Wood
 * @date: 2019/3/26
 * @update:
 */
import axios from './rest-axios';

const getMembers = (type) => axios.get(`/v1/members/?type=${type}`);
const getMember = (id) => axios.get(`/v1/members/${id}`);
const getMemberCards = (id) => axios.get(`/v2/members/${id}/cards`);
const getMemberYuyues = (id) => axios.get(`/v2/members/${id}/yuyues`);
const getMemberKeshis = (id) => axios.get(`/v2/members/${id}/keshis`);
const getMember2 = (id) => axios.get(`/v2/members/${id}`);
const putMember = (model) => axios.put(`/v1/members/${model.id}`, model);
const postStudents = (classes_id, content) => axios.post(`/v1/students/${classes_id}`, {classes_id, content});
const postMembers = (model) => axios.post(`/v1/members/`, model);
const resetPassword = (student_id, password) => axios.post(`/v2/members/${student_id}/resetpassword`, {password});
const searchUser = (school_id, key) => axios.post(`/v1/students/${school_id}/search/`, {key});
const postStudentsInSchool = (classes_id, ids) => axios.post(`/v1/students/${classes_id}/toclasses`, {
    classes_id,
    ids
});
const deleteStudentFromClasses = (classes_id, user_id) => axios.delete(`/v1/students/`, {
    classes_id,
    user_id
});

const getReports = (student_id, label_id) => axios.get(`/v1/students/${student_id}/reports?label_id=${label_id}`);
const postReports = (student_id, data) => axios.post(`/v1/students/${student_id}/reports`, {...data});
const deleteReport = (id) => axios.delete(`/v1/reports/${id}`);
const getReportsLabels = (student_id) => axios.get(`/v1/students/${student_id}/reports/labels`);
const postReportFile = (student_id, name) => axios.post(`/v1/students/${student_id}/reports/file`, {name});
const postXuKa = (student_id, card_id) => axios.post(`/v1/members/${student_id}/xuka`, {card_id});

const deleteCard = (id) => axios.delete(`/v2/members/${id}/deletecard`, {id});
const putCard = (model) => axios.put(`/v1/members/${model.user_id}/card`, model);
const postCard = (model) => axios.post(`/v2/members/card`, model);

const getMembersV2 = (ids) => axios.post(`/v2/members/list`, {ids});

const postMemberFile = (id, remark) => axios.post(`/v2/members/${id}/file`, {remark});
const postMemberUnFile = (id, remark) => axios.post(`/v2/members/${id}/unfile`, {remark});


export {
    getMembers,
    getMember,
    getMember2,
    getMemberCards,
    getMemberYuyues,
    getMemberKeshis,
    putMember,
    postStudents,
    postMembers,
    resetPassword,
    searchUser,
    postStudentsInSchool,
    deleteStudentFromClasses,
    getReports,
    postReports,
    deleteReport,
    getReportsLabels,
    postReportFile,
    postXuKa,
    deleteCard,
    putCard,
    postCard,
    getMembersV2,
    postMemberFile,
    postMemberUnFile
};
