/**
 * @name: reducer集合
 * @description:
 * @author: Wood
 * @date: 2019/3/26
 * @update:
 */
import {combineReducers} from 'redux';
import accountReducer, {initialAccountState} from "./account.js";
import loginModalReducer, {initialLoginModalState} from "./login-modal.jsx";
import staffReducer, {initialStaffState} from "./staff";
import coursesTypeReducer, {initialCoursesTypeState} from "./courses_type";
import passwordModalReducer, {initialPasswordModalState} from "./password-modal.js";
import studentsReducer, {initialStudentsState} from "./students";
import usersCardsReducer, {initialUsersCardsState} from "./users_cards";
import storesReducer, {initialStoresState} from "./stores";
import menusReducer, {initialMenusState} from "./menus";
import membershipsReducer, {initialMembershipsState} from "./memberships";

const initialState = {
    account: initialAccountState,
    // school: initialSchoolState,
    loginModal: initialLoginModalState,
    staff: initialStaffState,
    courses_type: initialCoursesTypeState,
    passwordModal: initialPasswordModalState,
    students: initialStudentsState,
    users_cards: initialUsersCardsState,
    stores: initialStoresState,
    menus: initialMenusState,
    memberships: initialMembershipsState,
    // schoolModal: initialSchoolModalState,
};

const reducer = combineReducers({
    account: accountReducer,
    // school: schoolReducer,
    loginModal: loginModalReducer,
    staff: staffReducer,
    courses_type: coursesTypeReducer,
    passwordModal: passwordModalReducer,
    students: studentsReducer,
    users_cards: usersCardsReducer,
    stores: storesReducer,
    menus: menusReducer,
    memberships: membershipsReducer,
    // schoolModal: schoolModalReducer,
});


export {
    reducer as default,
    initialState,
}