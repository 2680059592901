
import axios from './rest-axios';

const getMembership = () => axios.get(`/v2/membership`);
const getMembershipInfo = (id) => axios.get(`/v2/membership/${id}`);
const getMembershipHistory = (id) => axios.get(`/v2/membership/${id}/history`);
const postMembership = (model) => axios.post(`/v2/membership`, model);
const postMembershipXuFei = (model) => axios.post(`/v2/membership/${model.id}/xufei`, model);
// const putMembershipType = (model) => axios.put(`/v2/membership_type/${model.id}`, model);
const postChongzhi = (id, fee, remark) => axios.post(`/v2/membership/${id}/chongzhi`, {id, fee, remark});
const postZengsong = (id, fee, remark) => axios.post(`/v2/membership/${id}/zengsong`, {id, fee, remark});
const getMembershipFees = (id) => axios.get(`/v2/membership/${id}/fees`, {id});
const getMembershipDatesHistory = (id) => axios.get(`/v2/membership/${id}/dates_history`, {id});

export {
    getMembership,
    getMembershipInfo,
    getMembershipHistory,
    postMembership,
    // putMembershipType,
    postChongzhi,
    postZengsong,
    postMembershipXuFei,
    getMembershipFees,
    getMembershipDatesHistory,
};
