import React, {useEffect, useState} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {Checkbox} from 'antd';


const StaffCheck = ({value, onChange, staff}) => {
    const [ids, setids] = useState([]);

    // 更改事件
    const handleChange = (value) => {
        onChange && onChange(JSON.stringify(value))
    };

    useEffect(() => {
        let ids = [];
        if (value) {
            ids = JSON.parse(value);
        }
        setids(ids);
    }, [value]);

    return (
        <Checkbox.Group
            // buttonStyle="solid"
            value={ids}
            style={{whiteSpace: "normal"}}
            onChange={(value) => handleChange(value)}
        >
            {staff.filter(staf=>staf.is_coach && staf.enable === 1).map(item =>
                <Checkbox value={item.id} key={item.id}>{item.name}</Checkbox>
            )}
        </Checkbox.Group>
    )
};


const mapStateToProps = state => ({
    staff: state.staff
});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(StaffCheck));

// export default StaffSelect;