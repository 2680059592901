import PropTypes from 'prop-types';
import React, {Component, useState, useEffect} from 'react';
import {putCard} from "../../../lib/api_members.js";
import {Modal, Input, Row, Col, Radio, DatePicker, Form, Cascader, InputNumber, Typography, Select} from 'antd';
import moment from 'moment';
// import TeacherSelect from './teacher-select';
// import MemberNumber from './member-number-edit';
// import options from '../../lib/cascader-address-options';
import {postCard} from "../../../lib/api_members";
import {getCards} from "../../../lib/api_cards";

const {Text, Link} = Typography;
const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};


const CardAddModal = ({user_id, onOk, onCancel}) => {
    const [form] = Form.useForm();
    const [cards, setcards] = useState([]);
    const [card, setcard] = useState(null);
    const [avg, setavg] = useState(0);

    const fetchCards = () => {
        getCards()
            .then(res => {
                if (res.code === 0) {
                    setcards(res.data)
                }
            })
    };

    useEffect(() => {
        fetchCards()
    }, []);

    const Jisuan = () => {
        let payments = form.getFieldValue("payments");
        let keshi_original = form.getFieldValue("keshi_original");
        // let avg = (payments / keshi_original).toFixed(2);
        // let error = "";
        // if (payments % keshi_original !== 0) {
        //     error = "单节课费用必须为整数";
        //     // this.formRef.current.setFields([{
        //     //     name: "payments",
        //     //     errors: ["无法整除"]
        //     // }])
        // }
        setavg(Math.ceil(payments / keshi_original))
    };

    const handleCardChange = (value) => {
        let card = cards.find(_ => _.id === value);
        if (card) {
            setcard(card);
            let begin_time = moment().startOf('day');
            let end_time = moment(begin_time).add(card.limit_days - 1, 'd');
            form.setFieldsValue({
                begin_time: begin_time,
                end_time: end_time,
                keshi_original: card.keshi,
                payments: 0,
                retail_price: card.retail_price
            })
            Jisuan()
        }
    };

    const handleBeginTimeChange = (value) => {
        let begin_time = moment(value).startOf('day');
        let end_time = moment(begin_time).add(card.limit_days - 1, 'd');
        form.setFieldsValue({
            begin_time: begin_time,
            end_time: end_time,
        })
    };

    const handleSubmit = () => {
        form.validateFields()
            .then(values => {
                values.user_id = user_id;
                postCard(values)
                    .then(result => {
                        console.log(result);
                        // 刷新数据
                        if (result.code === 0) {
                            onOk && onOk()
                        }
                    })
            })
    };

    return (
        <Modal
            title={`添加次卡`}
            visible={true}
            onOk={handleSubmit}
            onCancel={onCancel}
            // confirmLoading={this.state.editModalConfirmLoading}
            // okText="确认"
            // cancelText="取消"
            // footer={null}
            // width={800}
            destroyOnClose={true}
        >
            <Form
                {...layout}
                // className="ant-advanced-search-form"
                // onSubmit={this.handleSubmit}
                form={form}
                initialValues={{
                    // begin_time: moment().startOf('day'),
                    // end_time: moment().startOf('day'),
                    // keshi_original: data.keshi,
                    // status: data.status,
                    // payments: data.payments,
                    annual_fee: 0,
                    // retail_price: data.retail_price,
                    keshi_free_original: 0
                }}
            >
                <Form.Item
                    label="会员卡"
                    name="card_id"
                    rules={[
                        {
                            required: true,
                            message: '请选择会员卡',
                        },
                    ]}
                >
                    <Select onChange={handleCardChange}>
                        {cards.map(card => <Select.Option value={card.id}>{card.name}</Select.Option>)}
                    </Select>
                </Form.Item>
                {card && <>
                <Form.Item
                    label="开始日期"
                    name="begin_time"
                    rules={[
                        {
                            required: true,
                            message: '请选择开始日期',
                        },
                    ]}
                >
                    <DatePicker onChange={handleBeginTimeChange}/>
                </Form.Item>

                <Form.Item
                    label="结束日期"
                    name="end_time"
                    rules={[
                        {
                            required: true,
                            message: '请选择结束日期',
                        },
                    ]}
                >
                    <DatePicker/>
                </Form.Item>

                <Form.Item
                    label="正课"
                    name="keshi_original"
                    rules={[
                        {
                            required: true,
                            message: '请输入正课课时',
                        },
                    ]}
                >
                    <InputNumber onChange={Jisuan}/>
                </Form.Item>
                <Form.Item
                    label="赠课"
                    name="keshi_free_original"
                    rules={[
                        {
                            required: true,
                            message: '请输入赠课课时',
                        },
                    ]}
                >
                    <InputNumber/>
                </Form.Item>

                <Form.Item
                    label="门市价"
                    name="retail_price"
                >
                    <InputNumber readOnly precision={2}/>
                </Form.Item>

                <Form.Item
                    label="实际支付总金额"
                    rules={[
                        {
                            required: true,
                            message: '请输入支付金额',
                        },
                    ]}>
                    <Form.Item
                        // label="支付金额"
                        name="payments"
                        noStyle
                        rules={[
                            {
                                required: true,
                                message: '请输入支付金额',
                            },
                        ]}
                    >
                        <InputNumber onChange={Jisuan}/>

                    </Form.Item>
                    <div>
                        每课时：{avg}元<br/>
                        {/*{this.state.error ? <Text type="danger">{this.state.error}</Text> :""}*/}
                        {/*{payments % keshi_original !== 0}*/}
                    </div>
                </Form.Item>


                </>}

            </Form>
        </Modal>
    )
};


CardAddModal.propTypes = {
    onCancel: PropTypes.func,
    onOk: PropTypes.func,
    user_id: PropTypes.number,
};

export default CardAddModal;
