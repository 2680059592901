import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Checkbox, Row, Col, Input, TimePicker, message} from 'antd';
import {courses_type} from "../../config";
import moment from 'moment';

const inputStyle = {
    width: 80,
    marginLeft: 5,
    marginRight: 5,
}

class TimeInput extends Component {

    state = {
        begin_time: null,
        end_time: null
    };

    componentDidMount() {
        this.initValue(this.props)

    }

    componentWillReceiveProps(props) {
        this.initValue(props)
    }

    initValue = (props) => {
        if (props.value) {
            let list = JSON.parse(props.value);
            if (list.length === 2) {
                this.setState({
                    begin_time: moment(list[0], "HH:mm"),
                    end_time: moment(list[1], "HH:mm")
                })
            }
        }
    }

    handleChange = () => {
        // console.log(e);
        let {begin_time, end_time} = this.state;
        if (begin_time && end_time) {
            let time = [begin_time.format("HH:mm"), end_time.format("HH:mm")];
            this.props.onChange && this.props.onChange(JSON.stringify(time));
        }
    };

    handleBeginTimeChange = (time) => {
        let {begin_time, end_time} = this.state;
        if (!time) {
            this.setState({
                begin_time: time
            })
        } else {
            if (end_time && time.isAfter(end_time)) {
                message.error("开始时间不能晚于结束时间")
            } else {
                this.setState({
                    begin_time: time
                }, () => {
                    this.handleChange()
                })
            }
        }

    };
    handleEndTimeChange = (time) => {
        let {begin_time, end_time} = this.state;
        if (!time) {
            this.setState({
                end_time: time
            })
        } else {
            if (begin_time && time.isBefore(begin_time)) {
                message.error("结束时间不能早于开始时间")
            } else {
                this.setState({
                    end_time: time
                }, () => {
                    this.handleChange()
                })
            }
        }
    };

    render() {
        let {begin_time, end_time} = this.state;
        return (
            <div>
                <TimePicker format={"HH:mm"} minuteStep={5} value={begin_time} onChange={this.handleBeginTimeChange}/>
                <span style={{marginLeft: 10, marginRight: 10}}>-</span>
                <TimePicker format={"HH:mm"} minuteStep={5} value={end_time} onChange={this.handleEndTimeChange}/>
            </div>
        )
    }
}

TimeInput.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
};

export default TimeInput;