/**
 * @name: 班级详情
 * @description:
 * @author: Wood
 * @date: 2019/3/28
 * @update: 2019/2/24
 */
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {getMember, deleteCard} from "../../lib/api_members";
import {Form} from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
    Button,
    Table,
    Tabs,
    PageHeader,
    Descriptions,
    Modal,
    Input,
    DatePicker,
    message,
    Radio,
    Timeline,
    Tag,
    Space,
} from 'antd';
import PageContent from '../../components/page-content/page-content';
import MembersEditModal from './members-edit-modal';
import MembersResetModal from './members-reset-modal';
import MembersCardAddModal from './members-card-add-modal';
import moment from 'moment';
import Box from '../../components/box';
import StaffName from '../../components/staff_name';
import MembersChongZhiModal from './members-chongzhi-modal';
import ResidenceName from '../../components/residence_name';
import TrainTypeName from '../../components/config/train_type_name';
import CardEditModal from './card-edit-modal';
import {ExclamationCircleOutlined} from '@ant-design/icons';
import CardDetailModal from './card-detail-modal';
import CardDetailCiKaModal from './card-detail-cika-modal';

const {Column} = Table;
const {confirm} = Modal;

class MembersDetail extends Component {
    state = {
        student_info: {
            teams: [],
            courses: [],
            keshis: [],
            cards: [],
            yuyue: []
        },
        editStudentInfo: null,
        courses_users_edit: null,
        xu_fei_modal: false,
        xu_fei: {
            create_on: moment().format("YYYY-MM-DD HH:mm"),
            keshi: 0,
            is_gift: 0
        },

        // 重置密码
        resetModal: null,
        xuKaModal: 0,

        chongzhiModal: null,
        cardEditModal: null,
    };

    componentDidMount() {
        this.loadStudentInfo();
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    // 获取学生信息
    loadStudentInfo = () => {
        getMember(this.props.id)
            .then((result) => {
                if (result.code === 0) {
                    this.setState({
                        student_info: result.data,

                    })
                } else {

                }
            })
    };

    // 预约操作
    handleYuYue = () => {
        this.props.history.push("/", {user_id: this.state.student_info.id});
    };

    renderTimeline = (item) => {
        let color = "";
        switch (item.status) {
            case -1:
                color = "gray";
                break;
            case 0:
                color = "blue";
                break;
            case 1:
                color = "red";
                break;
            case 2:
                color = "green";
                break;
        }
        return (
            <Timeline.Item key={item.id} color={color} label={`${moment(item.begin_time).format("YYYY-MM-DD ddd")}`}>
                <p style={{margin: 0}}>
                    {item.status === -1 && <Tag>已取消</Tag>}
                    {item.status === 0 && <Tag color={"blue"}>已预约</Tag>}
                    {item.status === 1 && <Tag color={"red"}>上课中</Tag>}
                    {item.status === 2 && <Tag color={"green"}>已下课</Tag>}
                    {moment(item.begin_time).format("HH:mm")}
                    - {moment(item.end_time).format("HH:mm")}
                </p>
                <p style={{margin: 0}}>手机号：{item.mobile_number}
                    车牌：{item.license_plate} {item.teacher ? <span>教练：<StaffName id={item.teacher}/></span> : ""}</p>
                <p style={{margin: 0}}>备注：{item.remark}</p>
            </Timeline.Item>
        )
    };

    handleDelete = (record) => {
        // let {id} = this.props.data;
        confirm({
            title: '确定要删除这条记录吗？',
            icon: <ExclamationCircleOutlined/>,
            content: '删除后数据无法恢复，请谨慎操作！',
            okText: '确定删除',
            okType: 'danger',
            cancelText: '不删除',
            onOk: () => {
                deleteCard(record.id)
                    .then(res => {
                        if (res.code === 0) {
                            this.loadStudentInfo()
                            // this.setState({})
                            // this.props.onClose && this.props.onClose();
                        } else {
                            // message.error(res.message);
                        }
                    })

            },
            onCancel() {
                console.log('Cancel');
            },
        });

    };

    render() {
        let {student_info} = this.state;
        let keshi_pay = 0;
        let keshi_gift = 0;
        student_info.keshis.forEach(item => {
            if (item.is_gift === 0) {
                keshi_pay += item.keshi;
            }
            if (item.is_gift === 1) {
                keshi_gift += item.keshi;
            }
        });
        let cards_nianka =student_info.cards.filter(item=> item.card_type === 1);
        let cards_cika =student_info.cards.filter(item=> item.card_type === 0);
        return (
            <PageContent title={student_info.nick_name}
                         routes={[
                             {
                                 path: '',
                                 breadcrumbName: <><span>首页</span></>,
                             },
                             {
                                 path: '//members',
                                 breadcrumbName: '会员管理',
                             },
                             {
                                 path: `//${this.props.id}`,
                                 breadcrumbName: '会员详情',
                             },
                         ]}
                         extra={[
                             <Button key="1" type="primary"
                                     onClick={this.handleYuYue}>
                                 预约
                             </Button>,
                             <Button key="2" type="primary"
                                     onClick={() => this.setState({editStudentInfo: {...student_info}})}>
                                 修改
                             </Button>,
                             <Button key="3" type="primary"
                                     onClick={() => this.setState({resetModal: {...student_info}})}>
                                 重置密码
                             </Button>,
                             <Button key="4" type="primary"
                                     onClick={() => this.setState({xuKaModal: student_info.id})}>
                                 添加会员卡
                             </Button>,
                         ]}
            >
                <Box>
                    <PageHeader
                        // onBack={() => window.history.back()}
                        // title={student_info.nick_name/**/}
                        style={{padding: 0}}
                        // extra={[
                        //     <Button key="1" type="primary"
                        //             onClick={() => this.setState({editStudentInfo: {...student_info}})}>
                        //         修改
                        //     </Button>,
                        //     <Button key="1" type="primary"
                        //             onClick={() => this.setState({resetModal: {...student_info}})}>
                        //         重置密码
                        //     </Button>,
                        //     <Button key="1" type="primary"
                        //             onClick={() => this.setState({editStudentInfo: {...student_info}})}>
                        //         课时调整
                        //     </Button>,
                        //     <Button key="1" type="primary"
                        //             onClick={() => this.setState({xuKaModal: student_info.id})}>
                        //         续卡
                        //     </Button>,
                        //     <Button key="1" type="primary"
                        //             onClick={() => this.setState({editStudentInfo: {...student_info}})}>
                        //         退费
                        //     </Button>,
                        // ]}
                    >
                        <Descriptions bordered size={"small"}>
                            <Descriptions.Item label="会员姓名">{student_info.nick_name}</Descriptions.Item>
                            <Descriptions.Item label="昵称">{student_info.other_name}</Descriptions.Item>
                            <Descriptions.Item
                                label="会员编号">{student_info.member_number && student_info.member_number.member_number}</Descriptions.Item>
                            <Descriptions.Item
                                label="性别">{student_info.sex === 1 && "男"}{student_info.sex === 2 && "女"}</Descriptions.Item>
                            <Descriptions.Item label="出生年月">{student_info.birthday}</Descriptions.Item>
                            <Descriptions.Item label="联系电话">{student_info.mobile_number}</Descriptions.Item>
                            <Descriptions.Item label="车牌号码">{student_info.license_plate}</Descriptions.Item>
                            <Descriptions.Item label="专属教练">{student_info.teacher_name}</Descriptions.Item>
                            <Descriptions.Item label="课程顾问">{student_info.consultant_name}</Descriptions.Item>
                            <Descriptions.Item label="训练类型"><TrainTypeName
                                value={student_info.train_type}/></Descriptions.Item>
                            <Descriptions.Item label="居住地"><ResidenceName
                                data={student_info.residence}/></Descriptions.Item>
                            <Descriptions.Item label="标记1">{student_info.tag1}</Descriptions.Item>
                            <Descriptions.Item label="标记2">{student_info.tag2}</Descriptions.Item>
                            <Descriptions.Item label="首次办卡日期">{student_info.member_begin}</Descriptions.Item>
                            <Descriptions.Item label="有效期">{student_info.member_end}</Descriptions.Item>
                            <Descriptions.Item label="门店">{student_info.store ? student_info.store.name: "-"}</Descriptions.Item>
                            {/*<Descriptions.Item label="开卡时间">{student_info.member_begin}</Descriptions.Item>*/}
                            {/*<Descriptions.Item label="到期时间" span={2}>{student_info.member_end}</Descriptions.Item>*/}
                            <Descriptions.Item label="备注" span={3}>{student_info.remark}</Descriptions.Item>
                        </Descriptions>
                    </PageHeader>

                    <Tabs defaultActiveKey="1">
                        <Tabs.TabPane tab={`会员卡(${student_info.cards.length})`} key="1">
                            <Table title={()=>"年卡"} dataSource={cards_nianka} rowKey={"id"} pagination={false} size="small">
                                <Column
                                    title="编号"
                                    dataIndex="id"
                                    key="id"
                                    width={50}
                                />
                                <Column
                                    title="状态"
                                    dataIndex="status"
                                    key="status"
                                    width={50}
                                    render={(status) => {
                                        return status === 0 ? "正常" : "失效"
                                    }}
                                />
                                <Column
                                    title="名称"
                                    dataIndex="name"
                                    key="name"
                                />
                                <Column
                                    title="购买时间"
                                    dataIndex="begin_time"
                                    key="begin_time"
                                    width={120}
                                    render={(begin_time) => {
                                        return moment(begin_time).format("YYYY-MM-DD")
                                    }}
                                />
                                <Column
                                    title="到期时间"
                                    dataIndex="end_time"
                                    width={120}
                                    key="end_time"
                                    render={(end_time) => {
                                        return moment(end_time).format("YYYY-MM-DD")
                                    }}
                                />
                                <Column
                                    title="充值教练费"
                                    key="充值教练费"
                                    width={100}
                                    align="right"
                                    render={(record) => {
                                        return `${record.keshi_remaining}/${record.keshi_original}`;
                                    }}
                                />
                                <Column
                                    title="赠送教练费"
                                    key="赠送教练费"
                                    width={100}
                                    align="right"
                                    render={(record) => {
                                        return `${record.keshi_free_remaining}/${record.keshi_free_original}`;
                                    }}
                                />
                                <Column
                                    title="相关课程"
                                    dataIndex="courses_type"
                                    key="courses_type"
                                    render={(courses_type) => {
                                        return this.props.courses_type.filter(item => courses_type.split(',').includes(item.id + '')).map(item => item.name).join(',')
                                    }}
                                />
                                <Column
                                    title="操作"
                                    key="操作"
                                    width={150}
                                    render={(record) => {
                                        // cardEditModal
                                        let arr = [];

                                        if(record.card_type === 0) {
                                            // arr.push(<Button key="1"
                                            //                  onClick={() => this.setState({cardEditModal: record})}>修改</Button>);
                                            arr.push(<Button key="1"
                                                             onClick={() => this.setState({cardDetailcikaModal: record})}>详情</Button>);
                                        } else {
                                            arr.push(<Button key="1"
                                                             onClick={() => this.setState({cardDetailModal: record})}>详情</Button>);
                                        }


                                        // if (record.card_type === 1) {
                                        //     arr.push(
                                        //         <Button key="2" type="primary"
                                        //                 onClick={() => this.setState({chongzhiModal: record})}>
                                        //             充值
                                        //         </Button>
                                        //     );
                                        // }
                                        // console.log(record);
                                        if (record.keshi_original === record.keshi_remaining) {
                                            arr.push(<Button key="3"
                                                             onClick={() => this.handleDelete(record)}>删除</Button>);
                                        }

                                        return <Space>{arr}</Space>;
                                    }}
                                />

                            </Table>
                            <Table  title={()=>"次卡"} dataSource={cards_cika} rowKey={"id"} pagination={false} size="small">
                                <Column
                                    title="编号"
                                    dataIndex="id"
                                    key="id"
                                    width={50}
                                />
                                <Column
                                    title="状态"
                                    dataIndex="status"
                                    key="status"
                                    width={50}
                                    render={(status) => {
                                        return status === 0 ? "正常" : "失效"
                                    }}
                                />
                                <Column
                                    title="名称"
                                    dataIndex="name"
                                    key="name"
                                />
                                <Column
                                    title="购买时间"
                                    dataIndex="begin_time"
                                    key="begin_time"
                                    width={120}
                                    render={(begin_time) => {
                                        return moment(begin_time).format("YYYY-MM-DD")
                                    }}
                                />
                                <Column
                                    title="到期时间"
                                    dataIndex="end_time"
                                    width={120}
                                    key="end_time"
                                    render={(end_time) => {
                                        return moment(end_time).format("YYYY-MM-DD")
                                    }}
                                />
                                <Column
                                    title="正课"
                                    key="正课"
                                    width={80}
                                    align="right"
                                    render={(record) => {
                                        return `${record.keshi_remaining}/${record.keshi_original}`;
                                    }}
                                />
                                <Column
                                    title="赠课"
                                    key="赠课"
                                    width={80}
                                    align="right"
                                    render={(record) => {
                                        return `${record.keshi_free_remaining}/${record.keshi_free_original}`;
                                    }}
                                />

                                <Column
                                    title="总支付"
                                    dataIndex="payments"
                                    key="总支付"
                                    width={80}
                                    align="right"
                                    render={(payments, record) => {
                                        return payments.toFixed(2);
                                    }}
                                />
                                <Column
                                    title="剩余金额"
                                    dataIndex="payments_remaining"
                                    key="剩余金额"
                                    width={80}
                                    align="right"
                                    render={(payments_remaining, record) => {
                                        return payments_remaining.toFixed(2);
                                    }}
                                />
                                <Column
                                    title="均价"
                                    dataIndex="payments_avg"
                                    key="均价"
                                    width={80}
                                    align="right"
                                    render={(payments_avg, record) => {
                                        return payments_avg.toFixed(2);
                                    }}
                                />
                                {/*<Column*/}
                                    {/*title="相关课程"*/}
                                    {/*dataIndex="courses_type"*/}
                                    {/*key="courses_type"*/}
                                    {/*render={(courses_type) => {*/}
                                        {/*return this.props.courses_type.filter(item => courses_type.split(',').includes(item.id + '')).map(item => item.name).join(',')*/}
                                    {/*}}*/}
                                {/*/>*/}
                                <Column
                                    title="操作"
                                    key="操作"
                                    width={150}
                                    render={(record) => {
                                        // cardEditModal
                                        let arr = [];

                                        if(record.card_type === 0) {
                                            // arr.push(<Button key="1"
                                            //                  onClick={() => this.setState({cardEditModal: record})}>修改</Button>);
                                            arr.push(<Button key="1"
                                                             onClick={() => this.setState({cardDetailcikaModal: record})}>详情</Button>);
                                        } else {
                                            arr.push(<Button key="1"
                                                             onClick={() => this.setState({cardDetailModal: record})}>详情</Button>);
                                        }


                                        // if (record.card_type === 1) {
                                        //     arr.push(
                                        //         <Button key="2" type="primary"
                                        //                 onClick={() => this.setState({chongzhiModal: record})}>
                                        //             充值
                                        //         </Button>
                                        //     );
                                        // }
                                        // console.log(record);
                                        if (record.keshi_original === record.keshi_remaining) {
                                            arr.push(<Button key="3"
                                                             onClick={() => this.handleDelete(record)}>删除</Button>);
                                        }

                                        return <Space>{arr}</Space>;
                                    }}
                                />

                            </Table>

                        </Tabs.TabPane>
                        <Tabs.TabPane tab={`预约记录(${student_info.yuyue.length})`} key="2">
                            <Timeline mode={"left"}>
                                {student_info.yuyue.map(item => this.renderTimeline(item))}
                            </Timeline>
                        </Tabs.TabPane>

                        {/*<Tabs.TabPane tab={`预约记录(${student_info.courses.length})`} key="2">*/}
                        {/*<Table dataSource={student_info.courses} rowKey={"courses_id"} pagination={false}>*/}
                        {/*<Column*/}
                        {/*title="上课时间"*/}
                        {/*dataIndex="create_on"*/}
                        {/*key="create_on"*/}
                        {/*/>*/}
                        {/*<Column*/}
                        {/*title="课程名称"*/}
                        {/*dataIndex="name"*/}
                        {/*key="name"*/}
                        {/*/>*/}
                        {/*<Column*/}
                        {/*title="班级"*/}
                        {/*dataIndex="team_name"*/}
                        {/*key="team_name"*/}
                        {/*/>*/}
                        {/*<Column*/}
                        {/*title="讲师"*/}
                        {/*dataIndex="teacher_name"*/}
                        {/*key="teacher_name"*/}
                        {/*/>*/}
                        {/*<Column*/}
                        {/*title="课时"*/}
                        {/*dataIndex="keshi"*/}
                        {/*key="keshi"*/}
                        {/*/>*/}
                        {/*<Column*/}
                        {/*title="备注"*/}
                        {/*dataIndex="remark"*/}
                        {/*key="remark"*/}
                        {/*/>*/}
                        {/*<Column*/}
                        {/*title="操作"*/}
                        {/*dataIndex="id"*/}
                        {/*key="id"*/}
                        {/*render={(id, record) => (*/}
                        {/*<a onClick={() => this.setState({courses_users_edit: {...record}})}>修改</a>*/}
                        {/*)}*/}
                        {/*/>*/}
                        {/*</Table>*/}
                        {/*</Tabs.TabPane>*/}
                        <Tabs.TabPane tab={`课时记录(${student_info.keshis.length})`} key="3">
                            <Table dataSource={student_info.keshis} rowKey={"id"} pagination={false}>
                                <Column
                                    title="时间"
                                    dataIndex="create_on"
                                    key="create_on"
                                />
                                <Column
                                    title="说明"
                                    dataIndex="remark"
                                    key="remark"
                                />
                                <Column
                                    title="课时"
                                    dataIndex="keshi"
                                    key="keshi"
                                />
                                <Column
                                    title="卡片"
                                    dataIndex="card_id"
                                    key="card_id"
                                    render={(card_id) => {
                                        let card = student_info.cards.find(item => item.id === card_id);
                                        return card ? card.name : ""
                                    }}
                                />
                                <Column
                                    title="处理人"
                                    dataIndex="create_by"
                                    key="处理人"
                                    render={(create_by) => {
                                        return <StaffName id={create_by}/>
                                    }}
                                />
                                {/*<Column*/}
                                {/*title="操作"*/}
                                {/*dataIndex="id"*/}
                                {/*key="id"*/}
                                {/*render={(id, record) => (*/}
                                {/*<a onClick={() => this.setState({keshi_edit: {...record}})}>修改</a>*/}
                                {/*)}*/}
                                {/*/>*/}
                            </Table>
                        </Tabs.TabPane>
                    </Tabs>
                    <MembersEditModal
                        onOK={() => {
                            this.loadStudentInfo();
                            this.setState({editStudentInfo: null})
                        }}
                        onClose={() => {
                            this.setState({editStudentInfo: null})
                        }}
                        user_info={this.state.editStudentInfo}
                    />
                    <MembersResetModal
                        onOK={() => {
                            this.setState({resetModal: null})
                        }}
                        onClose={() => {
                            this.setState({resetModal: null})
                        }}
                        user_info={this.state.resetModal}
                    />
                    <MembersCardAddModal
                        onOK={() => {
                            this.loadStudentInfo();
                            this.setState({xuKaModal: 0})
                        }}
                        onClose={() => {
                            this.setState({xuKaModal: 0})
                        }}
                        user_id={this.state.xuKaModal}
                    />

                    <MembersChongZhiModal
                        onOK={() => {
                            this.loadStudentInfo();
                            this.setState({chongzhiModal: null})
                        }}
                        onClose={() => {
                            this.setState({chongzhiModal: null})
                        }}
                        card={this.state.chongzhiModal}
                    />
                    <CardEditModal
                        onOK={() => {
                            this.loadStudentInfo();
                            this.setState({cardEditModal: null})
                        }}
                        onClose={() => {
                            this.setState({cardEditModal: null})
                        }}
                        data={this.state.cardEditModal}
                    />
                    {
                        this.state.cardDetailModal &&
                        <CardDetailModal
                            onOK={() => {
                                this.loadStudentInfo();
                                this.setState({cardDetailModal: null})
                            }}
                            onClose={() => {
                                this.setState({cardDetailModal: null})
                            }}
                            data={this.state.cardDetailModal}
                        />
                    }

                    {
                        this.state.cardDetailcikaModal &&
                        <CardDetailCiKaModal
                            onOK={() => {
                                this.loadStudentInfo();
                                this.setState({cardDetailcikaModal: null})
                            }}
                            onClose={() => {
                                this.setState({cardDetailcikaModal: null})
                            }}
                            data={this.state.cardDetailcikaModal}
                        />
                    }


                </Box>
            </PageContent>
        )
    }
}


MembersDetail.propTypes = {
    id: PropTypes.number.isRequired,
};


const mapStateToProps = state => ({
    courses_type: state.courses_type
});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(MembersDetail));
