
import axios from './rest-axios';

const getCoursesFee = () => axios.get(`/v2/courses_fee`);
const postCoursesFee = (model) => axios.post(`/v2/courses_fee`, model);
const putCoursesFee = (model) => axios.put(`/v2/courses_fee/${model.id}`, model);
const deleteCoursesFee = (id) => axios.delete(`/v2/courses_fee/${id}`);

export {
    getCoursesFee,
    postCoursesFee,
    putCoursesFee,
    deleteCoursesFee,
};
