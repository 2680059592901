import React from 'react';
import PropTypes from 'prop-types';
import {Checkbox} from 'antd';

const ConfigCheckbox = ({config, value, onChange}) => {
    return (
        <Checkbox.Group onChange={onChange} value={value}>
            {config.map(item=><Checkbox value={item.id} key={item.id}>{item.name}</Checkbox>)}
        </Checkbox.Group>
    )
};

ConfigCheckbox.propTypes = {
    value: PropTypes.array,
    onChange: PropTypes.func,
    config: PropTypes.array,
};

export default ConfigCheckbox;

