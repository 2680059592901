import React, {Component} from 'react';
import {connect} from 'react-redux';
import {PlusOutlined} from '@ant-design/icons';
import {Button, Table, message} from 'antd';
import PageContent from '../../../components/page-content/page-content';
import CoursesTypeAddModal from './courses-type-add-modal';
import CoursesTypeEditModal from './courses-type-edit-modal';
import {getCoursesType} from "../../../lib/api_courses_type";
import {setCoursesType} from "../../../reducers/courses_type";
import Box from '../../../components/box/index';
import YuYueTypesNames from '../../../components/config/yuyue_types_names';
import CoursesTypeCategoryName from '../../../components/config/courses_type_categorys_name';
import AuthComponent from '../../../components/auth_component';
import DictName from '../../../components/dict/dict-name';

const {Column} = Table;

@AuthComponent
class CoursesType extends Component {
    state = {
        list: [],

        showAddModal: false,
        editUser: null,
        resetUser: null
    };

    componentDidMount() {
        this.loadData()
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    loadData = () => {
        getCoursesType()
            .then((result) => {
                if (result.code === 0) {
                    this.setState({
                        list: result.data
                    });
                    this.props.setCoursesType(result.data);
                } else {

                }
            })
    };

    showAddModal = () => {
        this.setState({
            showAddModal: true
        })
    };
    hideAddModal = () => {
        this.setState({
            showAddModal: false
        })
    };

    render() {
        let {list} = this.state;

        return (
            <PageContent title={"课程管理"}>
                <Box>
                    {this.hasRole(3) &&
                    <Button type='primary' icon={<PlusOutlined/>} onClick={this.showAddModal}
                            style={{marginBottom: 16}}>添加课程</Button>
                    }

                    <Table
                        dataSource={list}
                        rowKey={"id"}
                        pagination={false}
                        size={"small"}
                        style={{width: 500}}
                        bordered
                    >
                        <Column
                            title="优先级"
                            dataIndex="order_index"
                            key="order_index"
                            width={80}
                        />
                        <Column
                            title="课程名称"
                            dataIndex="name"
                            key="name"
                        />

                        <Column
                            title="分类"
                            dataIndex="category"
                            key="category"
                            render={(category)=><DictName type_value={"courses_category"} value={category} />}
                        />

                        <Column
                            title="预约类型"
                            dataIndex="yuyue_type"
                            key="yuyue_type"
                            render={(yuyue_type)=><YuYueTypesNames value={yuyue_type} />}
                        />

                        {this.hasRole(3) &&
                        <Column
                            title="操作"
                            key="action"
                            width={60}
                            align="center"
                            render={(text, record) => (
                                <div>
                                    <Button type="link" size="small" onClick={() => {
                                        this.setState({editUser: record})
                                    }}>修改</Button>
                                </div>
                            )}
                        />
                        }
                    </Table>

                    <CoursesTypeAddModal
                        onOK={() => {
                            this.loadData();
                            this.hideAddModal()
                        }}
                        onClose={this.hideAddModal}
                        visible={this.state.showAddModal}
                    />

                    <CoursesTypeEditModal
                        onOK={() => {
                            this.loadData();
                            this.setState({editUser: null})
                        }}
                        onClose={() => {
                            this.setState({editUser: null})
                        }}
                        data={this.state.editUser}
                    />
                </Box>
            </PageContent>
        );
    }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
    setCoursesType: (arr) => dispatch(setCoursesType(arr)),
});


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CoursesType);


