import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Checkbox, Row, Col} from 'antd';

class JiaoLianCheck extends Component {

    handleChange = (e) => {
        console.log(e);
        this.props.onChange && this.props.onChange(e.join(","));
    };

    render() {
        let {value, staff} = this.props;
        if (value) {
            value = value.split(',').map(item => parseInt(item))
        } else {
            value = []
        }

        return (
            <Checkbox.Group value={value} onChange={this.handleChange}>
                <Row>
                    {staff.filter(item => item.is_coach === true && item.status === 1).map(item =>
                        <Col key={item.id} span={6}>
                            <Checkbox value={item.id}>{item.name}</Checkbox>
                        </Col>
                    )}
                </Row>
            </Checkbox.Group>
        )
    }
}

JiaoLianCheck.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
};

const mapStateToProps = state => ({
    staff: state.staff
});

const mapDispatchToProps = dispatch => ({});


export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(JiaoLianCheck);


