import React from 'react';
import PropTypes from 'prop-types';
import {Radio} from 'antd';

const ConfigRadio = ({config, value, onChange}) => {
    return (
        <Radio.Group onChange={onChange} value={value}>
            {config.map(item=><Radio value={item.id} key={item.id}>{item.name}</Radio>)}
        </Radio.Group>
    )
};

ConfigRadio.propTypes = {
    value: PropTypes.number,
    onChange: PropTypes.func,
    config: PropTypes.array,
};

export default ConfigRadio;

