import PropTypes from 'prop-types';
import React, {useEffect,useState} from 'react';
import {postStaff} from "../../../lib/api_staff";
import '@ant-design/compatible/assets/index.css';
import {Modal, Input, Form, Table} from 'antd';
import {getnewStaffFromQYWX, postimportStaffFromQYWX} from "../../../lib/api_staff";


const StaffImportModal = ({visible, onOk, onCancel}) => {
    const [list, setlist] = useState([]);
    const [selectedRowKeys, setselectedRowKeys] = useState([]);

    const fetchData = ()=>{
        getnewStaffFromQYWX()
            .then(res=>{
                if(res.code === 0){
                    setlist(res.data)
                }
            })
    };

    useEffect(fetchData, []);

    const handleOk = ()=>{
        if(selectedRowKeys.length > 0){
            // 保存数据
            postimportStaffFromQYWX(selectedRowKeys)
                .then(res=>{
                    if(res.code === 0){
                        onOk && onOk()
                    }
                })
        }
    };

    const onSelectChange = (ids) => {
        console.log(ids)
        setselectedRowKeys(ids);
    };

    return (
        <Modal
            title="导入新员工"
            visible={visible}
            onOk={handleOk}
            onCancel={onCancel}
            destroyOnClose={true}
            width={800}
        >
            <Table
                // title="课程设置"
                size="small"
                pagination={false}
                dataSource={list}
                rowKey={"userid"}
                columns={[{
                    title: '姓名',
                    dataIndex: 'name',
                },{
                    title: '账号',
                    dataIndex: 'userid',
                },{
                    title: '别名',
                    dataIndex: 'alias',
                },{
                    title: '职务',
                    dataIndex: 'position',
                },{
                    title: '手机',
                    dataIndex: 'mobile',
                }]}
                rowSelection={{
                    selectedRowKeys,
                    onChange: onSelectChange,
                }}
            />
        </Modal>
    )
}

StaffImportModal.propTypes = {
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
    onOk: PropTypes.func,
};

export default StaffImportModal;
