import React, {useState, useEffect} from 'react';
import {Table} from 'antd';
import {getMembershipType} from "../../../lib/api_membership_type";


const MembershipTypeSelect = ({type_id, value, onChange}) => {
    const [list, setList] = useState([]);

    const loadData = () => {
        getMembershipType(type_id)
            .then(res => {
                if (res.code === 0) {
                    setList(res.data);
                }
            })
    };
    useEffect(loadData, []);

    return (
        <Table
            // title="课程设置"
            size="small"
            pagination={false}
            dataSource={list}
            rowKey={"id"}
            rowSelection={{
                type: "radio",
                defaultSelectedRowKeys: [value],
                onChange: (selectedRowKeys, selectedRows) => {
                    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                    onChange && onChange(selectedRowKeys[0], selectedRows[0]);
                },
            }}
            columns={[{
                title: 'VIP会籍名称',
                dataIndex: 'name',
                key: "VIP会籍名称"
            }, {
                title: '价格',
                dataIndex: 'price',
                key: "价格"
            }, {
                title: '成员数量',
                dataIndex: 'member_count',
                key: "成员数量"
            }, {
                title: '使用天数',
                dataIndex: 'limit_days',
                key: "使用天数"
            }]}/>
    )
};


export default MembershipTypeSelect;


