import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Checkbox, Row, Col} from 'antd';
import {courses_type} from "../../../config";
import {getCoursesType} from "../../../lib/api_courses_type";

class CoursesTypeCheck extends Component {

    state={
        list:[]
    };

    componentDidMount(){
        getCoursesType()
            .then(res=>{
                if(res.code === 0){
                    this.setState({
                        list:res.data
                    })
                }
            })
    }

    handleChange=(e)=>{
        console.log("e", e);
        this.props.onChange && this.props.onChange(e.join(','));
    };
    render() {
        let {value} = this.props;
        console.log(value);
        if(value){
            value = value.split(',').map(item=>parseInt(item));
        }else{
            value=[];
        }

        return (
            <Checkbox.Group value={value} onChange={this.handleChange}>
                <Row>
                    {this.state.list.map(item =>
                        <Col span={12} key={item.id}>
                            <Checkbox value={item.id}>{item.name}</Checkbox>
                        </Col>)}
                </Row>
            </Checkbox.Group>
        )
    }
}

CoursesTypeCheck.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
};

export default CoursesTypeCheck;