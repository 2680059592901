import PropTypes from 'prop-types';
import {connect} from 'react-redux';

const CoursesTypeName = ({id, courses_type}) => {
    let model = courses_type.find(item => item.id === id);
    if (model) {
        return model.name
    } else {
        return "";
    }
};
CoursesTypeName.propTypes = {
    id: PropTypes.number,
};


const mapStateToProps = state => ({
    courses_type: state.courses_type,
});

const mapDispatchToProps = dispatch => ({});


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CoursesTypeName);

