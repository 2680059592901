import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {Button, Table, Divider, Switch, message, Popconfirm, Row, Col, DatePicker, Card, Layout, Calendar} from 'antd';
import {getStaff, putStaffFile} from "../../lib/api_staff";
import styles from '../calendar/time-select.module.css';
import YuYueItem from '../calendar/time-select-yuyue-item';
import {getPaiBan} from "../../lib/api_paiban";
import {ContextMenu, MenuItem, ContextMenuTrigger} from "react-contextmenu";
import ChuqinModal from './chuqin-modal';
import ChuqinEditModal from './chuqin-edit-modal';
import moment from 'moment';

const {Column} = Table;


let contextTrigger = null;

const toggleMenu = e => {
    if (contextTrigger) {
        contextTrigger.handleContextClick(e);
    }
};

class PaiBanCalendar extends Component {
    state = {
        list: [],

        // 当前日期
        current_date: moment(moment().format("YYYY-MM-DD")),

        // 显示视图，1 日期 2周 3月
        view: 1,
        showChuqinModal: false,

        createItem: null
    };

    componentDidMount() {
        this.loadData()
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    loadData = () => {
        this.loadPaiBan();
    };

    loadPaiBan = () => {
        let {current_date} = this.state
        // 获得排班情况
        getPaiBan(current_date.format("YYYY-MM-DD"), moment(current_date).add(1, "d").format("YYYY-MM-DD"))
            .then(result => {
                if (result.code === 0) {
                    this.setState({
                        list: result.data
                    })
                } else {
                    message.error(result.message);
                }
            })
    }

    // 加载员工时间表
    renderStaff = (staf, times) => {
        // 获取教练的预约
        let {list, step} = this.state;

        if (!list) {
            return null;
        }
        let items = list.filter(item => item.user_id === staf.id);
        // // 显示位置
        // if (this.state.teacher === staf.id) {
        //     items.push({
        //         id: -1,
        //         nick_name: "★",
        //         begin_time: this.state.begin_time,
        //         end_time: this.state.end_time,
        //     })
        // }
        items.forEach(item => {
            item.teacher_name = staf.name;
            item.nick_name = "出勤";
            item.id = 1;
            item.step = 60
        });


        return (
            <Col key={staf.id}>
                <div className={`${styles.timeItem} ${styles.timeItemHead}`}
                     style={{}}>{staf.name}</div>
                <div className={styles.timeItemGroup}>
                    {times.map((item, index) => (
                        <div key={index} className={styles.timeItem}
                             style={{}}
                             onClick={() => this.handleChuqingClick({
                                 user_id: staf.id,
                                 teacher_name: staf.name,
                                 begin_time: item.format("YYYY-MM-DD HH:mm"),
                                 end_time: moment(item).add(1, 'h').format("YYYY-MM-DD HH:mm")
                             })}
                        >
                        </div>
                    ))}
                    {items.map((item, index) =>
                        <YuYueItem key={index}
                                   times={times}
                                   {...item}
                                    yuyue_type={2}
                                   onClick={() => this.handleChuqingEditClick(staf.nick_name, item)}/>
                    )}

                </div>
            </Col>
        )
    }

    handleDateChange = (value) => {
        console.log(value)
        this.setState({
            current_date: moment(value.format("YYYY-MM-DD"))
        }, () => {
            this.loadPaiBan()
        })
    };

    handleChuqingClick = (model) => {
        if(!moment(model.begin_time).isBefore(moment(), "d")){
            this.setState({
                createItem: model
            })
        }
    };

    handleChuqingEditClick = (teacher_name, item) => {
        console.log(teacher_name);
        item.teacher_name = teacher_name;
        this.setState({editItem: item})
    };

    renderView1 = () => {
        let {current_date, view} = this.state;
        let {staff} = this.props;

        let begin = this.state.current_date.hour(0);
        let times = [];
        for (let i = 0; i < 1440; i = i + 60) {
            times.push(moment(begin).add(i, "m"))
        }

        console.log(staff);
        return (
            <div>
                <Row gutter={16}>
                    <Col flex="200px">
                        <Calendar fullscreen={false} onChange={this.handleDateChange}/>

                    </Col>
                    <Col flex="auto">

                        {/*{times.map(item => <div>{item.format("HH:mm")}</div>)}*/}
                        <Row type={"flex"} style={{flexWrap: "nowrap"}}>
                            <Col>
                                <div className={`${styles.timeItem} ${styles.timeItemHead}`}>
                                </div>
                                {times.map((item, index) => (
                                    <div key={index} className={`${styles.timeItem} ${styles.timeItemHead}`}>
                                        <div>{item.format("HH:mm")}</div>
                                    </div>
                                ))}
                            </Col>
                            {staff.filter(staf => staf.is_coach === true && staf.status === 1).map(staf => this.renderStaff(staf, times))}

                        </Row>
                    </Col>
                </Row>

                <ChuqinModal
                    data={this.state.createItem}
                    onOK={() => {
                        this.loadData();
                        this.setState({createItem: null});
                    }}
                    onClose={() => this.setState({createItem: null})}
                />
                <ChuqinEditModal
                    data={this.state.editItem}
                    onOK={() => {
                        this.loadData();
                        this.setState({editItem: null});
                    }}
                    onClose={() => this.setState({editItem: null})}
                />
            </div>
        )
    };

    render() {
        let {staff, current_date, view} = this.state;

        if (view === 1) {
            return this.renderView1();
        }

        // 日历显示
        let date = [];
        for (let i = 0; i < 14; i++) {
            date.push(moment(current_date).add(i, "d").format("MM-DD"))
        }

        return (
            <div>
                {date.map(item => <div>{item}</div>)}
            </div>
        )
    }
}

PaiBanCalendar.propTypes = {};

const mapStateToProps = state => ({
    staff: state.staff
});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(PaiBanCalendar));


