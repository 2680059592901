import React, {Component} from 'react';
import {connect} from 'react-redux';
import {PlusOutlined} from '@ant-design/icons';
import {Button, Table, message, DatePicker} from 'antd';
import PageContent from '../../components/page-content/page-content';
import Box from '../../components/box';
import {getYuYueStat, postYuYueStat} from "../../lib/api_yuyue";
import moment from 'moment';
import QuerenEditModal from './queren-edit-modal';
import {getUnConfirmYuYueStat} from "../../lib/api_yuyue_stat";

const {Column} = Table;

class CoursesType extends Component {
    state = {
        date: moment().format("YYYY-MM-DD"),
        list: [],
        yuyue_stat_list: [],

        showAddModal: false,
        editUser: null,
        resetUser: null,
        editItem: null
    };

    componentDidMount() {
        this.loadData()
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    loadData = () => {
        getYuYueStat(this.state.date)
            .then((result) => {
                if (result.code === 0) {
                    this.setState({
                        list: result.data
                    });
                } else {

                }
            })
        getUnConfirmYuYueStat()
            .then((result) => {
                if (result.code === 0) {
                    this.setState({
                        yuyue_stat_list: result.data
                    });
                } else {

                }
            })
    };

    handleDateChange = (date, dateString) => {
        this.setState({
            date: dateString
        }, this.loadData);
        // console.log(date, dateString);
    };

    // 提交课时确认
    handleSubmit = () => {
        postYuYueStat(this.state.list)
            .then((result) => {
                if (result.code === 0) {
                    message.success("发送成功");
                    this.loadData();
                } else {

                }
            })
    };

    render() {
        let {list, yuyue_stat_list} = this.state;

        let cansend = list.some(item => item.status !== -1);

        return (
            <PageContent title={"课时统计确认"}>
                <Box>
                    <DatePicker
                        allowClear={false}
                        onChange={this.handleDateChange}
                        value={moment(this.state.date)}
                        dateRender={current => {
                            const style = {};
                            if(yuyue_stat_list.some(_=>_.date === current.format("YYYY-MM-DD"))){
                                style.border = '1px solid rgb(255 24 24)';
                                style.borderRadius = '50%';
                            }
                            // if (current.date() === 1) {
                            //     style.border = '1px solid #1890ff';
                            //     style.borderRadius = '50%';
                            // }
                            return (
                                <div className="ant-picker-cell-inner" style={style}>
                                    {current.date()}
                                </div>
                            );
                        }}
                    />


                    <Table
                        dataSource={list}
                        rowKey={"id"}
                        pagination={false}
                        size={"small"}
                        bordered
                        style={{width: 1130}}
                    >
                        <Column
                            title="教练"
                            dataIndex="nick_name"
                            key="教练"
                            // width={100}
                        />
                        <Column
                            title="1v1时长统计"
                            key="1v1时长统计"
                            children={[
                                <Column
                                    title="15分钟"
                                    dataIndex="d15"
                                    key="15分钟"
                                    width={70}
                                    align="center"
                                />,
                                <Column
                                    title="30分钟"
                                    dataIndex="d30"
                                    key="30分钟"
                                    width={70}
                                    align="center"
                                />,
                                <Column
                                    title="45分钟"
                                    dataIndex="d45"
                                    key="45分钟"
                                    width={70}
                                    align="center"
                                />,
                                <Column
                                    title="合计"
                                    dataIndex="dtotal"
                                    key="合计"
                                    width={70}
                                    align="center"
                                />
                            ]}
                        />
                        <Column
                            title="小组课时长统计"
                            key="小组课时长统计"
                            children={[
                                <Column
                                    title="主教练"
                                    dataIndex="xiaozu_zhu3"
                                    key="主教练"
                                    width={70}
                                    align="center"
                                />,
                                <Column
                                    title="助教"
                                    dataIndex="xiaozu_zhu4"
                                    key="助教"
                                    width={70}
                                    align="center"
                                />
                            ]}
                        />
                        <Column
                            title="人次统计"
                            key="人次统计"
                            children={[
                                <Column
                                    title="散客15分钟"
                                    dataIndex="sanke15"
                                    key="散客15分钟"
                                    width={70}
                                    align="center"
                                />,
                                <Column
                                    title="散客30分钟"
                                    dataIndex="sanke30"
                                    key="散客30分钟"
                                    width={70}
                                    align="center"
                                />,
                                <Column
                                    title="散客45分钟"
                                    dataIndex="sanke45"
                                    key="散客45分钟"
                                    width={70}
                                    align="center"
                                />,
                                <Column
                                    title="会员"
                                    dataIndex="huiyuan"
                                    key="会员"
                                    width={70}
                                    align="center"
                                />,
                                <Column
                                    title="潜在"
                                    dataIndex="qianzai"
                                    key="潜在"
                                    width={70}
                                    align="center"
                                />,
                                <Column
                                    title="合计"
                                    dataIndex="ptotal"
                                    key="合计"
                                    width={70}
                                    align="center"
                                />
                            ]}
                        />



                        <Column
                            title="状态"
                            dataIndex="status"
                            key="状态"
                            width={80}
                            align="center"
                            render={(status) => {
                                switch (status) {
                                    case -1:
                                        return "未发送";
                                    case 0:
                                        return "已发送";
                                    case 1:
                                        return "已确认";
                                    default:
                                        return "-"
                                }
                            }}
                        />
                        <Column
                            title="操作"
                            key="操作"
                            width={80}
                            align="center"
                            render={(record) => {
                                if(record.status === 0){
                                    return <a onClick={()=>this.setState({editItem: record})}>修改</a>
                                }
                            }}
                        />

                    </Table>

                    <div style={{textAlign: "center", marginTop: 20}}>
                        <Button type="primary" size="large"
                                disabled={cansend}
                                onClick={this.handleSubmit}>确认并发送</Button>
                    </div>
                </Box>
                <QuerenEditModal
                    data={this.state.editItem}
                    onOK={()=>{
                        this.setState({editItem: null});
                        this.loadData();
                    }}
                    onClose={()=>{
                        this.setState({editItem: null});

                    }}
                />
            </PageContent>
        );
    }
}

const mapStateToProps = state => ({
    // yuyue
});

const mapDispatchToProps = dispatch => ({});


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CoursesType);


