import React, {useState} from 'react';
import {Table, Typography} from 'antd';
import moment from 'moment';
import MembershipDatesHistory from './membership_dates_history';

const {Column} = Table;
const {Text} = Typography;

const MembershipDatesList = ({list}) => {
    const [history, sethistory] = useState(0);
    return (
        <div>
            <Table
                size={"small"}
                dataSource={list}
                rowKey={"id"}
                pagination={false}
            >
                <Column
                    title="开始日期"
                    dataIndex="begin_time"
                    key="开始时间"
                    width={120}
                />
                <Column
                    title="结束日期"
                    dataIndex="end_time"
                    key="结束时间"
                    width={120}
                />
                <Column
                    title="指导价格"
                    dataIndex="price"
                    key="指导价格"
                    width={100}
                    align={"right"}
                />
                <Column
                    title="实际支付年费"
                    dataIndex="payments"
                    key="实际支付年费"
                    width={100}
                    align={"right"}
                />
                <Column
                    title="剩余年费"
                    dataIndex="payments_remaining"
                    key="剩余年费"
                    width={100}
                    align={"right"}
                />
                <Column
                    title="每日扣除"
                    dataIndex="fee_avg"
                    key="每日扣除"
                    width={100}
                    align={"right"}
                />
                <Column
                    title="剩余天数"
                    dataIndex="limit_days_remaining"
                    key="剩余天数"
                    width={100}
                    align={"right"}
                />

                <Column
                    title="创建时间"
                    dataIndex="createdAt"
                    key="创建时间"
                    render={(createdAt) => moment(createdAt).format("YYYY-MM-DD HH:mm")}
                    width={150}
                />
                <Column
                    title="状态"
                    dataIndex="status"
                    key="状态"
                    render={(status) => {
                        return status === 1 ? <Text type={"secondary"}>失效</Text> : "正常";
                    }}
                    width={60}
                />

                <Column
                    title="操作"
                    // dataIndex="createdAt"
                    key="操作"
                    render={(record) => {
                        return (
                            <span><a onClick={() => sethistory(record.id)}>年费记录</a></span>
                        )
                    }}
                />
            </Table>
            {history > 0 &&
            <MembershipDatesHistory
                id={history}
                visible={!!history}
                onCancel={() => {
                    sethistory(0)
                }}
            />
            }

        </div>
    )
};
export default MembershipDatesList;


