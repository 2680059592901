
import axios from './rest-axios';

const getStat1 = (begin_time, end_time) => axios.post(`/v1/stat/1`, {begin_time, end_time});
const getStat2 = (begin_time, end_time, nick_name) => axios.post(`/v1/stat/2`, {begin_time, end_time,nick_name});
const getStat3 = (begin_time, end_time) => axios.post(`/v1/stat/3`, {begin_time, end_time});
const getStat4 = (begin_time, end_time) => axios.post(`/v1/stat/4`, {begin_time, end_time});
const getStat5 = (end_time, store_id) => axios.post(`/v1/stat/5`, {end_time, store_id});
const getStat6 = (year) => axios.post(`/v1/stat/6`, {year});
const getStat7 = (year, store_id) => axios.post(`/v1/stat/7`, {year, store_id});
const getStatDate = (month) => axios.get(`/v2/stat_date`, {month});
const getStatDateDetail = (date) => axios.get(`/v2/stat_date/${date}`);
const getStatMonth = (year) => axios.get(`/v2/stat_month`, {year});
const getStatMonthDetail = (year, month) => axios.get(`/v2/stat_month/${year}/${month}`);


export {
    getStat1,
    getStat2,
    getStat3,
    getStat4,
    getStat5,
    getStat6,
    getStat7,
    getStatDate,
    getStatDateDetail,
    getStatMonth,
    getStatMonthDetail,
};
