/**
 * @name: 课程管理路由
 * @description:
 * @author: Wood
 * @date: 2021/4/25
 * @update:
 */
import React from 'react';
import {Switch, Route} from "react-router-dom";
import Membership from './membership/memberships';
import MembershipDetail from './membership/membership_detail';
import Members from './members';
import MembersDetail from './members-detail';
import MembersDetail2 from './members/member_detail';
import KeshiBatch from './keshi_batch/keshi_batch';

const Router = () => (
    <Switch>
        <Route exact path="/member/members"
               component={Members}/>
        {/*<Route exact path="/member/members/:members_id"*/}
               {/*component={({match}) =>*/}
                   {/*<MembersDetail id={parseInt(match.params.members_id)}/>}/>*/}
        <Route exact path="/member/members/:members_id"
               component={({match}) =>
                   <MembersDetail2 id={parseInt(match.params.members_id)}/>}/>
        <Route exact path="/member/member_ship"
               component={Membership}/>
        <Route exact path="/member/member_ship/:id"
               component={({match}) => <MembershipDetail id={parseInt(match.params.id)}/>}/>

        <Route exact path="/member/keshi_batch"
               component={KeshiBatch}/>
    </Switch>
);

export default Router;
