const set_courses_type = 'courses_type/set_courses_type';

const initialState = [];

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
        case set_courses_type:
            // console.log("SET_STAFF", {...action.staff});
            return [...action.courses_type];
        default:
            return state;
    }
};

const setCoursesType = function (courses_type) {
    return {
        type: set_courses_type,
        courses_type: courses_type
    }
};


export {
    reducer as default,
    initialState as initialCoursesTypeState,
    setCoursesType,
}