import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {putCoursesType} from "../../../lib/api_courses_type";
import {Modal, Input, Form, InputNumber} from 'antd';
import YuYueTypesCheckbox from '../../../components/config/yuyue_types_checkbox';
import CoursesTypeCategoryRadio from '../../../components/config/courses_type_category_radio';
import DictRadioGroup from '../../../components/dict/dict-radio-group';

const formItemLayout = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 6},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 18},
    },
};

class CoursesTypeEditModal extends Component {
    formRef = React.createRef();

    handleClose = () => {
        this.props.onClose && this.props.onClose();
    };

    handleOK = () => {
        this.props.onOK && this.props.onOK();
    };

    handleSubmit = e => {
        e.preventDefault();
        this.formRef.current.validateFields()
            .then(values => {
                    console.log('Received values of form: ', values);
                    values.id = this.props.data.id;
                    putCoursesType(values)
                        .then(result => {
                            console.log(result);
                            // 刷新数据
                            this.handleOK();
                        })
                }
            );
    };

    render() {
        const {data} = this.props;

        if (data) {
            let yuyue_type = data.yuyue_type ? JSON.parse(data.yuyue_type) : [];
            return (

                <Modal
                    title="修改课程信息"
                    visible={!!data}
                    onOk={this.handleSubmit}
                    onCancel={this.handleClose}
                    destroyOnClose={true}
                >
                    <Form {...formItemLayout}
                        // onSubmit={this.handleSubmit}
                          initialValues={{
                              name: data.name,
                              yuyue_type: yuyue_type,
                              category: data.category,
                              order_index: data.order_index,
                          }}
                          ref={this.formRef}
                    >
                        <Form.Item
                            label="课程名称"
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: '请输入课程名称',
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label="预约类型"
                            name="yuyue_type"
                        >
                            <YuYueTypesCheckbox/>
                        </Form.Item>
                        <Form.Item
                            label="分类"
                            name="category"
                            rules={[
                                {
                                    required: true,
                                    message: '请选择分类',
                                },
                            ]}
                        >
                            <DictRadioGroup type_value={"courses_category"} />
                            {/*<CoursesTypeCategoryRadio/>*/}
                        </Form.Item>
                        <Form.Item
                            label="优先级"
                            name="order_index"
                            rules={[
                                {
                                    required: true,
                                    message: '请设置优先级',
                                },
                            ]}
                        >
                            <InputNumber min={0}/>
                        </Form.Item>

                    </Form>
                </Modal>
            )
        } else {
            return null;
        }
    }
}

CoursesTypeEditModal.propTypes = {
    onClose: PropTypes.func,
    onOK: PropTypes.func,
    data: PropTypes.object
};

export default CoursesTypeEditModal;
