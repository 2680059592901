import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Modal, Button, message, Form} from 'antd';
import {deletePaiBan} from "../../lib/api_paiban";
import moment from 'moment';
import AuthComponent from '../../components/auth_component';

const {confirm} = Modal;

const formItemLayout = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 4},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 20},
    },
};

// @AuthComponent
class ChuqinEditModal extends Component {

    state = {
        disabled: false
    };

    componentDidMount() {
        if (this.props.data) {
            if(this.props.data.is_file===1){
                this.setState({disabled: true})
                return;
            }
            if (moment(this.props.data.begin_time).isBefore(moment(), "d")) {
                this.setState({disabled: true})
            } else {
                this.setState({disabled: false})
            }
        }
    }

    componentWillReceiveProps(props) {
        if (props.data) {

            if(props.data.is_file===1){
                this.setState({disabled: true})
                return;
            }
            if (moment(props.data.begin_time).isBefore(moment(), "d")) {
                this.setState({disabled: true})
            } else {
                this.setState({disabled: false})
            }
        }
    }

    handleClose = () => {
        this.props.onClose && this.props.onClose();
    };

    handleOK = () => {
        this.props.onOK && this.props.onOK();
    };

    handleDelete = () => {
        // if(!this.hasRole([3,4])){
        //     message.error("无权修改");
        //     return;
        // }

        confirm({
            title: '是否要删除该条出勤设置',
            content: '删除后该教练将无法被排课，删除前请先确认该教练是否已排课！',
            okText: '删除',
            okType: 'danger',
            cancelText: '不删除',
            onOk: () => {
                return new Promise((resolve, reject) => {
                    deletePaiBan(this.props.data)
                        .then(res => {
                            if (res.code === 0) {
                                resolve();
                                this.handleOK();
                            }else{
                                reject(res.message);
                            }
                        })
                        .catch(e => {
                            reject(e)
                        });
                }).catch((e) => {
                    console.log(e.message)
                });
            },
            onCancel: () => {
            },
        });
    };

    render() {
        const {data} = this.props;
        const {disabled} = this.state;


        return (
            <Modal
                title="设置出勤"
                visible={!!data}
                onOk={this.handleSubmit}
                onCancel={this.handleClose}
                destroyOnClose={true}
                // width={800}
                footer={[
                    <Button key="back" onClick={this.handleClose}>
                        取消操作
                    </Button>,
                    <Button key="delete" type="danger"
                            ghost
                            onClick={this.handleDelete}
                            disabled={disabled}
                    >
                        取消出勤
                    </Button>,
                ]}
            >
                {data &&
                <Form {...formItemLayout}>
                    <Form.Item label="教练">
                        {data.teacher_name}
                    </Form.Item>
                    <Form.Item label="开始时间">
                        {data.begin_time}
                    </Form.Item>
                    <Form.Item label="结束时间">
                        {data.end_time}
                    </Form.Item>
                </Form>
                }
            </Modal>
        )
    }
}

ChuqinEditModal.propTypes = {
    data: PropTypes.object,
    onClose: PropTypes.func,
    onOK: PropTypes.func,
};

export default ChuqinEditModal;
