import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {putMember} from "../../lib/api_members.js";
import { Modal, Input, Row, Col, Radio, DatePicker, Form,Cascader} from 'antd';
import moment from 'moment';
import TeacherSelect from './teacher-select';
import MemberNumber from './member-number-edit';
import options from '../../lib/cascader-address-options';
import StoreSelect from './store-select';
import DictRadioGroup from '../../components/dict/dict-radio-group';


function filter(inputValue, path) {
    return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
}

class MembersEditModal extends Component {
    formRef = React.createRef();

    state = {
        classes_id: this.props.classes_id || 0,
        classes: [],
        list: [],

        editItem: null,
        editModalConfirmLoading: false,

        // 添加对话框
        showAddModal: false,
        usernames: '',

        // 搜索用户
        selectedKeys: [],

        // 新用户的资料
        user_info: {
            nick_name: '',
            sex: 1,
            birthday: null,
            parent_name: '',
            parent_phone: '',
            course_name: '',
            sign_up_time: moment(new Date()).format("YYYY-MM-DD"),
            keshi_pay: 0,
            keshi_gift: 0,
            remark: ''
        }
    };

    handleClose = () => {
        this.props.onClose && this.props.onClose();
    };

    handleOK = () => {
        this.props.onOK && this.props.onOK();
    };


    handleSubmit = e => {
        e.preventDefault();
        this.formRef.current.validateFields()
            .then(values => {
                console.log('Received values of form: ', values);
                // console.log(this.props.user_info.id)
                putMember({...values, id: this.props.user_info.id})
                    .then(result => {
                        console.log(result);
                        // 刷新数据
                        if(result.code === 0){
                            this.handleOK();
                        }else{

                        }
                    })
            });
    };

    render() {

        const layout = {
            labelCol: {span: 8},
            wrapperCol: {span: 16},
        };

        const {user_info} = this.props;


        if (this.props.user_info) {
            return (
                <Modal
                    title="修改会员信息"
                    visible={!!this.props.user_info}
                    onOk={this.handleSubmit}
                    onCancel={this.handleClose}
                    // confirmLoading={this.state.editModalConfirmLoading}
                    // okText="确认"
                    // cancelText="取消"
                    // footer={null}
                    width={800}
                    destroyOnClose={true}
                >
                    <Form
                        {...layout}
                        className="ant-advanced-search-form"
                        // onSubmit={this.handleSubmit}
                        ref={this.formRef}
                        initialValues={{
                            nick_name: user_info.nick_name,
                            sex: user_info.sex,
                            birthday: user_info.birthday ? moment(user_info.birthday) : null,
                            mobile_number: user_info.mobile_number,
                            license_plate: user_info.license_plate,
                            teacher: user_info.teacher,
                            consultant: user_info.consultant,
                            member_number: user_info.member_number,
                            remark: user_info.remark,
                            // is_file: user_info.is_file,
                            residence: user_info.residence ? JSON.parse(user_info.residence) : [],
                            train_type: user_info.train_type,
                            tag1: user_info.tag1,
                            tag2: user_info.tag2,
                            other_name: user_info.other_name,
                            store_id: user_info.store_id,
                        }}
                    >

                        <Row gutter={0}>
                            <Col span={8}>
                                <Form.Item
                                    label="会员姓名"
                                    name="nick_name"
                                    rules={[
                                        {
                                            required: true,
                                            message: '请输入姓名',
                                        },
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>

                            <Col span={8}>
                                <Form.Item
                                    label="会员昵称"
                                    name="other_name"
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={0}>
                            <Col span={8}>
                                <Form.Item
                                    label="会员性别"
                                    name="sex"
                                    rules={[
                                        {
                                            required: true,
                                            message: '请选择性别',
                                        },
                                    ]}
                                >
                                    <Radio.Group>
                                        <Radio value={1}>男</Radio>
                                        <Radio value={2}>女</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="出生年月"
                                    name="birthday"
                                >
                                    <DatePicker/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={0}>
                            <Col span={8}>
                                <Form.Item
                                    label="联系电话"
                                    name="mobile_number"
                                    rules={[
                                        {
                                            required: true,
                                            message: '请输入联系电话',
                                        },
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="车牌号码"
                                    name="license_plate"
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={0}>
                            <Col span={8}>
                                <Form.Item
                                    label="专属教练"
                                    name="teacher"
                                    rules={[
                                        {
                                            required: true,
                                            message: '请选择教练',
                                        },
                                    ]}
                                >
                                    <TeacherSelect/>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="课程顾问"
                                    name="consultant"
                                    rules={[
                                        {
                                            required: true,
                                            message: '请选择课程顾问',
                                        },
                                    ]}
                                >
                                    <TeacherSelect/>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    label="会员编号"
                                    labelCol={{flex: "83px"}}
                                    name="member_number"
                                >
                                    <MemberNumber/>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="备　　注"
                                    labelCol={{flex: "83px"}}
                                    name="remark"
                                >
                                    <Input.TextArea autosize={{minRows: 2}}/>

                                </Form.Item>
                            </Col>
                        </Row>

                        {/*<Row gutter={24}>*/}
                            {/*<Col span={24}>*/}
                                {/*<Form.Item*/}
                                    {/*label="状　　态"*/}
                                    {/*labelCol={{flex: "83px"}}*/}
                                    {/*name="is_file"*/}
                                {/*>*/}
                                    {/*<Radio.Group>*/}
                                        {/*<Radio value={0}>有效</Radio>*/}
                                        {/*<Radio value={1}>失效</Radio>*/}
                                    {/*</Radio.Group>*/}
                                {/*</Form.Item>*/}
                            {/*</Col>*/}
                        {/*</Row>*/}
                        <Form.Item
                            label="居 住 地"
                            labelCol={{flex: "83px"}}
                            name="residence"
                        >
                            <Cascader
                                expandTrigger="hover"
                                options={options}
                                showSearch={{filter}}
                                placeholder="请选择居住地"
                                style={{width: 300}}
                                changeOnSelect
                            />

                        </Form.Item>
                        <Form.Item
                            label="训练类型"
                            labelCol={{flex: "83px"}}
                            name="train_type"
                            rules={[
                                {
                                    required: true,
                                    message: '请选择训练类型',
                                },
                            ]}
                        >
                            <DictRadioGroup type_value={"train_type"} />
                        </Form.Item>

                        <Form.Item
                            label="标记1"
                            labelCol={{flex: "83px"}}
                            name="tag1"
                        >
                            <DictRadioGroup type_value={"member-tag1"} />
                            {/*<Radio.Group>*/}
                                {/*<Radio value={""}>无</Radio>*/}
                                {/*<Radio value={"*"}>*</Radio>*/}
                                {/*<Radio value={"**"}>**</Radio>*/}
                            {/*</Radio.Group>*/}
                        </Form.Item>
                        <Form.Item
                            label="标记2"
                            labelCol={{flex: "83px"}}
                            name="tag2"
                        >
                            <DictRadioGroup type_value={"member-tag2"} />
                            {/*<Radio.Group>*/}
                                {/*<Radio value={""}>无</Radio>*/}
                                {/*<Radio value={"^"}>^</Radio>*/}
                                {/*<Radio value={"^^"}>^^</Radio>*/}
                            {/*</Radio.Group>*/}
                        </Form.Item>

                        <Form.Item
                            label="门店"
                            labelCol={{flex: "83px"}}
                            name="store_id"
                        >
                            <StoreSelect />
                        </Form.Item>

                    </Form>

                </Modal>

            )
        } else {
            return null;
        }

    }

}


MembersEditModal.propTypes = {
    onClose: PropTypes.func,
    onOK: PropTypes.func,
    user_info: PropTypes.object,
};

export default MembersEditModal;
