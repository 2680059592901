/**
 * @name: 课程管理路由
 * @description:
 * @author: Wood
 * @date: 2021/4/25
 * @update:
 */
import React from 'react';
import {Switch, Route} from "react-router-dom";
import SysDictType from './sys_dict_type/sys_dict_type';
import SysDict from './sys_dict_type/sys_dict2';
import Stores from './stores/stores';
import CoursesType from './courses-type/courses-type';
import Cards from './cards/cards';
import Staff from './staff/staff2';
import Roles from './roles/roles';
import RoleDetail from './roles/detail';
import StaffDetail from './staff/detail';
import StaffConfig from './staff-config/staff-config';
import CoursesFee from './courses-fee/courses-fee';
import MembershipType from './membership_type/membership_type';
import Membership from '../members/membership/memberships';
import MembershipDetail from '../members/membership/membership_detail';

const Router = () => (
    <Switch>
        <Route exact path="/settings/sys_dict_type"
               component={SysDictType}/>
        <Route exact path="/settings/sys_dict_type/:type_id"
               component={SysDict}/>
        <Route exact path="/settings/stores"
               component={Stores}/>
        <Route exact path="/settings/courses_type"
               component={CoursesType}/>
        <Route exact path="/settings/cards"
               component={Cards}/>
        <Route exact path="/settings/staff"
               component={Staff}/>
        <Route exact path="/settings/roles"
               component={Roles}/>
        <Route exact path="/settings/roles/:id"
               component={({match}) => <RoleDetail id={parseInt(match.params.id)}/>}/>
        <Route exact path="/settings/staff/:id"
               component={({match}) => <StaffDetail id={parseInt(match.params.id)}/>}/>
        <Route exact path="/settings/s_config"
               component={StaffConfig}/>
        <Route exact path="/settings/courses_fee"
               component={CoursesFee}/>
        <Route exact path="/settings/membership_type"
               component={MembershipType}/>
        <Route exact path="/settings/membership"
               component={Membership}/>
        <Route exact path="/settings/membership/:id"
               component={({match}) => <MembershipDetail id={parseInt(match.params.id)}/>}/>
    </Switch>
);

export default Router;
