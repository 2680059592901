import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Select} from 'antd';
import {getList} from "../../lib/api_data";

class MemberSelect extends Component {
    state = {
        list: [],
    };

    componentDidMount() {
        this.fetchData();
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    fetchData = () => {
        getList("stores")
            .then((result) => {
                if (result.code === 0) {
                    this.setState({
                        list: result.data
                    })
                } else {

                }
            })
    };

    onChange = (value) => {
        console.log(value);
        this.props.onChange && this.props.onChange(value)
    };

    render() {
        let {list} = this.state;
        return (
            <Select
                value={this.props.value}
                onChange={this.onChange}
                style={this.props.style}
            >
                {list.map(item => (
                    <Select.Option value={item.id} key={item.id}>{item.name}</Select.Option>
                ))}
            </Select>

        )
    }
}

MemberSelect.propTypes = {
    value: PropTypes.number,
    onChange: PropTypes.func
};

export default MemberSelect;
