import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {Modal, Form, InputNumber, Input, DatePicker} from 'antd';
import {postMembershipXuFei} from "../../../lib/api_membership";
import MembershipTypeSelect from '../../settings/membership_type/membership_type_select';
import moment from 'moment';

const MembershipXufeiModal = ({data, visible, onOk, onClose}) => {
    const [form] = Form.useForm();
    const [type_id, setTypeID] = useState(0);
    const [type, settype] = useState(null);

    const handleSubmit = () => {
        form.validateFields()
            .then(values => {
                values.id = data.id;
                console.log(values);
                postMembershipXuFei(values)
                    .then(res => {
                        if (res.code === 0) {
                            onOk && onOk(res.data);
                        }
                    })
            })
    };

    const handleTypeChange = (id, item) => {
        console.log(id, item);
        setTypeID(id);
        // 设置开始时间
        // 默认当前日期开始
        let begin_time = moment();
        if (begin_time.isBefore(data.end_time)) {
            begin_time = moment(data.end_time).add(1, "d");
        }
        let end_time = moment(begin_time).add(item.limit_days, "d");
        // if(begin_time.)

        settype(item);

        form.setFieldsValue({
            begin_time: begin_time,
            end_time: end_time,
            payments: item.price
        })
    };

    const handleTimeChange = (value) => {
        let begin_time = value;
        let end_time = moment(begin_time).add(type.limit_days-1, "d");
        form.setFieldsValue({
            begin_time: begin_time,
            end_time: end_time,
        })
    };

    const disabledDate= (current)=>{
        return current && (current < moment().startOf('day') || current >  moment().add(30, "d").startOf('day'));

    }

    const formItemLayout = {
        labelCol: {
            xs: {span: 24},
            sm: {span: 6},
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 18},
        },
    };

    return (
        <Modal
            title="vip会籍续费"
            visible={true}
            // onOk={form.submit}
            onOk={handleSubmit}
            onCancel={onClose}
            destroyOnClose={true}
            width={800}
        >
            <Form {...formItemLayout}
                  form={form}
                // onSubmit={this.handleSubmit}
                  initialValues={{
                      price: 0,
                      member_count: 0,
                      limit_days: 0,
                      // begin_time: moment(data.end_time).add(1, "d")
                  }}
                // ref={this.formRef}
            >
                <Form.Item
                    label="会籍类型"
                    name="type_id"
                    rules={[
                        {
                            required: true,
                            message: '请选择类型',
                        },
                    ]}
                >
                    <MembershipTypeSelect type_id={data.type_id} onChange={handleTypeChange}/>
                </Form.Item>

                {type_id > 0 ? <>
                <Form.Item
                    label="开始日期"
                    name="begin_time"
                    rules={[
                        {
                            required: true,
                            message: '请选择开始日期',
                        },
                    ]}
                >
                    <DatePicker
                        format={"YYYY-MM-DD"}
                        disabledDate={disabledDate}
                        onChange={handleTimeChange}
                    />
                </Form.Item>
                <Form.Item
                    label="结束日期"
                    name="end_time"
                    rules={[
                        {
                            required: true,
                            message: '请选择结束日期',
                        },
                    ]}
                >
                    <DatePicker format={"YYYY-MM-DD"} disabled/>
                </Form.Item>
                <Form.Item
                    label="实际支付年费"
                    name="payments"
                    rules={[
                        {
                            required: true,
                            message: '请输入实际支付年费',
                        },
                    ]}
                >
                    <InputNumber precision={2} min={0}/>
                </Form.Item>

                </> : null}


            </Form>
        </Modal>
    )
};

MembershipXufeiModal.propTypes = {
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
    onOk: PropTypes.func,
};

export default MembershipXufeiModal;
