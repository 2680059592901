import React, {useState, useEffect} from 'react';
import {Table, Button, Select} from 'antd';
import {getMembersV2} from "../../lib/api_members";
import {getMembers} from "../../lib/api_members.js";


const MemberSelect = ({value, onChange}) => {
    const [list, setList] = useState([]);
    const [members, setMembers] = useState([]);


    const loadData = () => {
        getMembers()
            .then(res => {
                if (res.code === 0) {
                    setMembers(res.data);
                    // setData()
                    if (res.data.length > 0) {
                        console.log(value);
                        if(value){
                            console.log(res.data.filter(item=>value.includes(item.id)));
                            setList(res.data.filter(item=>value.includes(item.id)))
                        }
                    }
                }
            })
    };

    // const loadData = () => {
    //     if (value) {
    //         getMembersV2(value)
    //             .then(res => {
    //                 if (res.code === 0) {
    //                     setList(res.data);
    //                 }
    //             })
    //     } else {
    //         setList([]);
    //     }
    // };
    useEffect(loadData, []);

    const setData = () => {
        if (members.length > 0) {
            console.log(value);
            console.log(members.filter(item=>value.includes(item.id)));
            setList(members.filter(item=>value.includes(item.id)))
        }
    };

    useEffect(setData, [value]);

    const handleAppend = (e) => {
        console.log(e);
        if(!value){
            value = [e];
            onChange && onChange(value)
            // setData()
        }
        if (!value.includes(e)) {
            value = [...value, e];
            onChange && onChange(value)
        }
    };

    const handleDelete = (id)=>{
        setList(list.filter(_ => _.id !== id));
        value = value.filter(_ => _ !== id);
        onChange && onChange(value)
    };

    return (
        <div>
            <Select
                showSearch
                // value={this.props.value}
                optionFilterProp="children"
                onChange={handleAppend}
                filterOption={(input, option) => {
                    return option.props.children.join("").toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                }
            >
                {members.map(item => (
                    <Select.Option value={item.id}
                                   key={item.id}>{item.nick_name} {item.user_name} {item.mobile_number}</Select.Option>
                ))}
            </Select>
            <Table
                // title="课程设置"
                size="small"
                pagination={false}
                dataSource={list}
                rowKey={"id"}
                columns={[{
                    title: '姓名',
                    dataIndex: 'nick_name',
                    key: "姓名"
                }, {
                    title: '手机',
                    dataIndex: 'mobile_number',
                    key: "手机"
                }, {
                    title: '操作',
                    key: "操作",
                    render: (record) => <div>
                        <a onClick={()=>{
                            handleDelete(record.id)
                        }}>移除</a>
                    </div>
                }]}/>
        </div>
    )
};


export default MemberSelect;


