import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Modal, Input, message, Form, InputNumber} from 'antd';
import md5 from 'md5';
import {resetPassword} from "../../lib/api_members";
// var nzhcn = require("nzh/cn");
import nzhcn from 'nzh/cn';
import {postChongzhi} from "../../lib/api_keshi";

class MembersChongZhiModal extends Component {
    formRef = React.createRef();

    state = {
        price: 0,
    };

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    handleClose = () => {
        this.props.onClose && this.props.onClose();
    };

    handleOK = () => {
        this.props.onOK && this.props.onOK();
    };

    handleSubmit = e => {
        let {card} = this.props;
        e.preventDefault();
        this.formRef.current.validateFields()
            .then(values => {
                console.log('Received values of form: ', values);
                postChongzhi({
                    user_id: card.user_id,
                    keshi: values.price,
                    remark: values.remark,
                    card_id: card.id
                })
                    .then(result => {
                        if (result.code === 0) {
                            message.success("充值成功！");
                            this.handleOK();
                        } else {
                            message.error(result.message);
                        }
                    })
                    .catch(err => {
                        message.error(err);
                    });
                // resetPassword(this.props.user_info.id, md5(values.password))
                //     .then(result => {
                //         if (result.code === 0) {
                //             message.success("重置密码成功！");
                //             this.handleOK();
                //         } else {
                //             message.error(result.message);
                //         }
                //     })
                //     .catch(err => {
                //         message.error(err);
                //     });
            });
    };

    onPriceChange = (value) => {
        // console.log(value);
        this.setState({
            price: value
        })
    }

    render() {
        let {card} = this.props;
        const formItemLayout = {
            labelCol: {
                xs: {span: 6},
            },
            wrapperCol: {
                xs: {span: 18},
            },
        };

        return (
            <Modal
                title="充值"
                visible={!!card}
                onOk={this.handleSubmit}
                onCancel={this.handleClose}
                destroyOnClose={true}
            >
                <Form {...formItemLayout}
                      onSubmit={this.handleSubmit}
                      ref={this.formRef}
                >
                    <Form.Item
                        label="充值金额"
                    >
                        <Form.Item
                            name="price"
                            rules={[
                                {
                                    required: true,
                                    message: '请输入充值金额',
                                },
                            ]}
                        >
                            <InputNumber
                                // defaultValue={1000}
                                formatter={value => `¥ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => value.replace(/¥\s?|(,*)/g, '')}
                                style={{width: 100}}
                                onChange={this.onPriceChange}
                                min={0}
                            />

                        </Form.Item>
                        {nzhcn.toMoney(this.state.price || 0)}
                    </Form.Item>
                    <Form.Item
                        label="备注"
                        name="remark"
                    >
                        <Input.TextArea/>
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}

MembersChongZhiModal.propTypes = {
    onClose: PropTypes.func,
    onOK: PropTypes.func,
    card: PropTypes.object
};

export default MembersChongZhiModal;
