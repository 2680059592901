
import axios from './rest-axios';

const getCoursesType = () => axios.get(`/v1/courses_type`);
const postCoursesType = (model) => axios.post(`/v1/courses_type`, model);
const putCoursesType = (model) => axios.put(`/v1/courses_type/${model.id}`, model);


export {
    getCoursesType,
    postCoursesType,
    putCoursesType,
};
