import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {putCard} from "../../lib/api_members.js";
import {Modal, Input, Row, Col, Radio, DatePicker, Form, Cascader, InputNumber,Typography} from 'antd';
import moment from 'moment';
import TeacherSelect from './teacher-select';
import MemberNumber from './member-number-edit';
import options from '../../lib/cascader-address-options';
import {postCard} from "../../lib/api_members";

const { Text, Link } = Typography;

class CardEditModal extends Component {
    formRef = React.createRef();

    state = {
        begin_time: moment(),
        end_time: moment(),
    };

    handleClose = () => {
        this.props.onClose && this.props.onClose();
    };

    handleOK = () => {
        this.props.onOK && this.props.onOK();
    };

    componentWillMount() {
        // let {data} = this.props;
        // let begin_time = moment().startOf('day');
        // let end_time = moment(begin_time).add(data.limit_days, 'D');
        // this.state.begin_time=begin_time;
        // this.state.end_time=end_time;
        // this.setState({
        //     begin_time,
        //     end_time
        // });
    }

    componentDidMount() {
        let {data} = this.props;
        let begin_time = moment().startOf('day');
        console.log(data)
        let end_time = moment(begin_time).add(data.limit_days, 'd');
        // this.formRef.current.setFieldsValue({
        //     begin_time:begin_time,
        //     end_time:end_time
        // })
        this.setState({
            begin_time,
            end_time
        }, ()=>{
            this.handleBeginTimeChange(begin_time);
        });
    }

    componentWillReceiveProps(props) {
        // this.Jisuan()
        if (props.data) {
            this.setState({
                avg: Math.ceil(props.data.payments / props.data.keshi_original)
            })
        }
    }


    Jisuan = () => {
        if (this.formRef.current) {
            let payments = this.formRef.current.getFieldValue("payments");
            let keshi_original = this.formRef.current.getFieldValue("keshi_original");
            // let avg = (payments / keshi_original).toFixed(2);
            // let error = "";
            // if (payments % keshi_original !== 0) {
            //     error = "单节课费用必须为整数";
            //     // this.formRef.current.setFields([{
            //     //     name: "payments",
            //     //     errors: ["无法整除"]
            //     // }])
            // }
            this.setState({
                avg: Math.ceil(payments / keshi_original),
                // error : error
            })
        }
    };

    handleBeginTimeChange=(e)=>{
        console.log(e)
        let {data} = this.props;
        this.formRef.current.setFieldsValue({
            end_time: moment(e).add(data.limit_days, 'd')
        })
    }

    handleSubmit = e => {
        e.preventDefault();
        let {data} = this.props;
        // if(this.state.error && data.card_type === 0) return;
        this.formRef.current.validateFields()
            .then(values => {
                console.log('Received values of form: ', values);
                // console.log(this.props.user_info.id)
                // values.begin_time = values.begin_time.format("YYYY-MM-DD");
                // values.end_time = values.end_time.format("YYYY-MM-DD");
                postCard({
                    card_id: data.id,
                    begin_time: values.begin_time.format("YYYY-MM-DD"),
                    end_time: values.end_time.format("YYYY-MM-DD"),
                    keshi_original: values.keshi_original,
                    payments: values.payments,
                    annual_fee: values.annual_fee,
                    user_id: this.props.user_id,
                    keshi_free_original: values.keshi_free_original,
                })
                    .then(result => {
                        console.log(result);
                        // 刷新数据
                        if (result.code === 0) {
                            this.handleOK();
                        } else {

                        }
                    })
            });
    };

    renderCiKa = () => {
        const layout = {
            labelCol: {span: 8},
            wrapperCol: {span: 16},
        };

        const {data} = this.props;
        console.log(data);

        if (!this.props.data) {
            return null;
        }

        return (
            <Form
                {...layout}
                // className="ant-advanced-search-form"
                // onSubmit={this.handleSubmit}
                ref={this.formRef}
                initialValues={{
                    begin_time: this.state.begin_time,
                    end_time: this.state.end_time,
                    keshi_original: data.keshi,
                    status: data.status,
                    payments: data.payments,
                    annual_fee: 0,
                    retail_price: data.retail_price,
                    keshi_free_original: 0
                }}
            >

                <Form.Item
                    label="开始日期"
                    name="begin_time"
                    rules={[
                        {
                            required: true,
                            message: '请选择开始日期',
                        },
                    ]}
                >
                    <DatePicker onChange={this.handleBeginTimeChange}/>
                </Form.Item>

                <Form.Item
                    label="结束日期"
                    name="end_time"
                    rules={[
                        {
                            required: true,
                            message: '请选择结束日期',
                        },
                    ]}
                >
                    <DatePicker/>
                </Form.Item>

                <Form.Item
                    label="正课"
                    name="keshi_original"
                    rules={[
                        {
                            required: true,
                            message: '请输入正课课时',
                        },
                    ]}
                >
                    <InputNumber onChange={this.Jisuan}/>
                </Form.Item>
                <Form.Item
                    label="赠课"
                    name="keshi_free_original"
                    rules={[
                        {
                            required: true,
                            message: '请输入赠课课时',
                        },
                    ]}
                >
                    <InputNumber/>
                </Form.Item>

                <Form.Item
                    label="门市价"
                    name="retail_price"
                >
                    <InputNumber readOnly precision={2}/>
                </Form.Item>

                <Form.Item
                    label="实际支付总金额"
                    rules={[
                        {
                            required: true,
                            message: '请输入支付金额',
                        },
                    ]}>
                    <Form.Item
                        // label="支付金额"
                        name="payments"
                        noStyle
                        rules={[
                            {
                                required: true,
                                message: '请输入支付金额',
                            },
                        ]}
                    >
                        <InputNumber onChange={this.Jisuan}/>

                    </Form.Item>
                    <div>
                        每课时：{this.state.avg}元<br />
                        {/*{this.state.error ? <Text type="danger">{this.state.error}</Text> :""}*/}
                        {/*{payments % keshi_original !== 0}*/}
                    </div>
                </Form.Item>

            </Form>

        )
    };

    renderNianKa = () => {
        const layout = {
            labelCol: {span: 8},
            wrapperCol: {span: 16},
        };

        const {data} = this.props;
        console.log(data);

        if (!this.props.data) {
            return null;
        }

        return (
            <Form
                {...layout}
                // className="ant-advanced-search-form"
                // onSubmit={this.handleSubmit}
                ref={this.formRef}
                initialValues={{
                    begin_time: this.state.begin_time,
                    end_time: this.state.end_time,
                    keshi_original: data.keshi,
                    status: data.status,
                    payments: 0,
                    annual_fee: data.annual_fee,
                    retail_price: data.retail_price,
                }}
            >

                <Form.Item
                    label="开始日期"
                    name="begin_time"
                    rules={[
                        {
                            required: true,
                            message: '请选择开始日期',
                        },
                    ]}
                >
                    <DatePicker onChange={this.handleBeginTimeChange}/>
                </Form.Item>

                <Form.Item
                    label="结束日期"
                    name="end_time"
                    rules={[
                        {
                            required: true,
                            message: '请选择结束日期',
                        },
                    ]}
                >
                    <DatePicker disabled/>
                </Form.Item>


                <Form.Item
                    label="年费（门市价）"
                    name="retail_price"
                >
                    <InputNumber disabled precision={2}/>
                </Form.Item>
                <Form.Item
                    // label="支付金额"
                    label="年费实付"
                    name="annual_fee"
                    rules={[
                        {
                            required: true,
                            message: '请输入年费',
                        },
                    ]}
                >
                    <InputNumber onChange={this.Jisuan}/>
                </Form.Item>
                <Form.Item
                    label="赠送教练费"
                    name="keshi_original"
                    rules={[
                        {
                            required: true,
                            message: '请输入赠送教练费',
                        },
                    ]}
                >
                    <InputNumber onChange={this.Jisuan}/>
                </Form.Item>
                {/*<div>*/}
                {/*每课时：{this.state.avg}元*/}
                {/*</div>*/}
            </Form>

        )
    };


    render() {

        const layout = {
            labelCol: {span: 8},
            wrapperCol: {span: 16},
        };

        const {data} = this.props;
        console.log(data);

        if (!this.props.data) {
            return null;
        }
        return (
            <Modal
                title={`添加会员卡--${data.name}`}
                visible={!!this.props.data}
                onOk={this.handleSubmit}
                onCancel={this.handleClose}
                // confirmLoading={this.state.editModalConfirmLoading}
                // okText="确认"
                // cancelText="取消"
                // footer={null}
                // width={800}
                destroyOnClose={true}
            >

                {data.card_type === 1 ? this.renderNianKa() : this.renderCiKa()}
            </Modal>

        )

    }

}


CardEditModal.propTypes = {
    onClose: PropTypes.func,
    onOK: PropTypes.func,
    data: PropTypes.object,
};

export default CardEditModal;
