import PropTypes from 'prop-types';
import React, {Component, useState} from 'react';
import {Modal, Input, message, Form, InputNumber} from 'antd';
import nzhcn from 'nzh/cn';
import {postChongzhi} from "../../../lib/api_membership";

const formItemLayout = {
    labelCol: {
        xs: {span: 6},
    },
    wrapperCol: {
        xs: {span: 18},
    },
};
const MembershipChongZhiModal = ({id, onOk, onClose}) => {
    const [form] = Form.useForm();
    const [price, setprice] = useState(0);

    const handleSubmit = () => {
        form.validateFields()
            .then(values => {
                postChongzhi(id, values.price, values.remark)
                    .then(res => {
                        if (res.code === 0) {
                            onOk && onOk();
                            message.success("充值成功")
                        }
                    })
            })
    };

    return (
        <Modal
            title="充值教练费"
            visible={true}
            onOk={handleSubmit}
            onCancel={onClose}
            destroyOnClose={true}
        >
            <Form {...formItemLayout}
                  form={form}
            >
                <Form.Item
                    label="充值金额"
                    required
                >
                    <Form.Item
                        name="price"
                        rules={[
                            {
                                required: true,
                                message: '请输入充值金额',
                            },
                        ]}
                    >
                        <InputNumber
                            // defaultValue={1000}
                            formatter={value => `¥ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={value => value.replace(/¥\s?|(,*)/g, '')}
                            style={{width: 100}}
                            onChange={(value) => setprice(value)}
                            min={0}
                        />

                    </Form.Item>
                    {nzhcn.toMoney(price || 0)}
                </Form.Item>
                <Form.Item
                    label="备注"
                    name="remark"
                >
                    <Input.TextArea/>
                </Form.Item>
            </Form>
        </Modal>
    )

};


MembershipChongZhiModal.propTypes = {
    onClose: PropTypes.func,
    onOk: PropTypes.func,
    id: PropTypes.number
};

export default MembershipChongZhiModal;
