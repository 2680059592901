import React, {Component, useState, useEffect} from 'react';
import {withRouter} from 'react-router-dom';
import {PlusOutlined} from '@ant-design/icons';
import {Button, Table, Divider, Switch, message} from 'antd';
import PageContent from '../../../components/page-content/page-content';
import CardsAddModal from './cards-add-modal';
// import CardsAddModalNianKa from './cards-add-modal-nianka';
import CardsEditModal from './cards-edit-modal';
// import CardsEditModalNianKa from './cards-edit-modal-nianka';
import {getCards} from "../../../lib/api_cards";
// import {getCoursesType} from "../../../lib/api_courses_type";
// import {courses_type} from "../../config";
import Box from '../../../components/box/index';
import moment from 'moment';
// import AuthComponent from '../../../components/auth_component';

const {Column} = Table;


const Cards = () => {
    const [list, setlist] = useState([]);
    const [addmodal, setaddmodal] = useState(false);
    const [editmodal, seteditmodal] = useState(null);

    const fetchData = () => {
        getCards()
            .then(res => {
                if (res.code === 0) {
                    setlist(res.data)
                }
            })
    };

    useEffect(fetchData, []);

    return (
        <PageContent title={"次卡管理"}>
            <Box title={"次卡"}
                 extra={
                     <Button type='primary' icon={<PlusOutlined/>} onClick={() => setaddmodal(true)}>添加次卡</Button>
                 }
                 style={{marginBottom: 20}}
            >
                <Table
                    dataSource={list}
                    rowKey={"id"}
                    pagination={false}
                    size={"small"}

                >
                    <Column
                        title="编号"
                        dataIndex="id"
                        key="id"
                        width={50}
                    />
                    <Column
                        title="名称"
                        dataIndex="name"
                        width={120}
                        key="name"
                    />
                    {/*<Column*/}
                    {/*title="包含课程"*/}
                    {/*dataIndex="courses"*/}
                    {/*key="courses_type"*/}
                    {/*ellipsis*/}
                    {/*render={(courses) => {*/}
                    {/*return courses.map(item => item.name).join()*/}
                    {/*}}*/}
                    {/*/>*/}
                    <Column
                        title="正课"
                        dataIndex="keshi"
                        key="keshi"
                        width={70}
                        align="right"
                    />
                    <Column
                        title="门市价"
                        dataIndex="retail_price"
                        key="retail_price"
                        width={90}
                        render={(retail_price) => parseFloat(retail_price).toFixed(2)}
                        align="right"
                    />
                    <Column
                        title="使用限制"
                        dataIndex="time_type"
                        key="time_type"
                        render={(time_type, record) => {
                            if (time_type === 0) {
                                return (<span>
                                        开卡后{record.limit_days}天内可使用
                                    </span>)
                            }
                            if (time_type === 1) {
                                return (<span>
                                        固定日期：到{moment(record.expired_date).format("YYYY-MM-DD")}之前可用
                                    </span>)
                            }
                        }}
                    />
                    <Column
                        title="使用时间"
                        dataIndex="holiday_use"
                        key="holiday_use"
                        width={170}
                        render={(time_type, record) => {
                            return (
                                <span>
                                        {record.holiday_use === 1 && "周末"}
                                    {record.weekdays_use === 1 && "工作日"}
                                    {record.special_use === 1 && "特殊日期"}
                                    </span>
                            )
                        }}
                    />
                    {/*<Column*/}
                        {/*title="状态"*/}
                        {/*dataIndex="status"*/}
                        {/*key="状态"*/}
                        {/*width={60}*/}
                        {/*render={(status, record) => {*/}
                            {/*return status === 0 ? "有效" : "无效";*/}
                        {/*}}*/}
                    {/*/>*/}

                    <Column
                        title="操作"
                        key="action"
                        width={50}
                        render={(text, record) => (
                            <div>
                                <a onClick={() => {
                                    seteditmodal(record);
                                }}>修改</a>
                            </div>
                        )}
                    />
                </Table>
            </Box>

            <CardsAddModal
                onOK={() => {
                    setaddmodal(false);
                    fetchData();
                }}
                onClose={() => {
                    setaddmodal(false)
                }}
                visible={addmodal}
            />
            <CardsEditModal
                onOK={() => {
                    seteditmodal(null);
                    fetchData();
                }}
                onClose={() => {
                    seteditmodal(null);
                }}
                data={editmodal}
            />

        </PageContent>

    )
};


// @AuthComponent
// class Cards extends Component {
//     state = {
//         list: [],
//
//         showAddModal: false,
//         showAddModalNianKa: false,
//         editUser: null,
//         editUserNianKa: null,
//         resetUser: null
//     };
//
//     componentDidMount() {
//         this.loadData()
//     }
//
//     componentWillUnmount() {
//         this.setState = (state, callback) => {
//             return;
//         };
//     }
//
//     loadData = () => {
//         getCards()
//             .then((result) => {
//                 if (result.code === 0) {
//                     this.setState({
//                         list: result.data
//                     })
//                 } else {
//
//                 }
//             })
//     };
//
//     showAddModal = () => {
//         this.setState({
//             showAddModal: true
//         })
//     };
//     hideAddModal = () => {
//         this.setState({
//             showAddModal: false
//         })
//     };
//
//
//     showAddModalNianKa = () => {
//         this.setState({
//             showAddModalNianKa: true
//         })
//     };
//     hideAddModalNianKa = () => {
//         this.setState({
//             showAddModalNianKa: false
//         })
//     };
//
//
//     render() {
//         let {list} = this.state;
//         let listcika = list.filter(item => item.card_type === 0);
//         let listnianka = list.filter(item => item.card_type === 1);
//         return (
//             <PageContent title={"次卡管理"}>
//                 <Box title={"次卡"}
//                      extra={this.hasRole(3)
//                          ? <Button type='primary' icon={<PlusOutlined/>} onClick={this.showAddModal}>添加次卡</Button>
//                          : null}
//                      style={{marginBottom: 20}}
//                 >
//                     <Table
//                         dataSource={listcika}
//                         rowKey={"id"}
//                         pagination={false}
//                         size={"small"}
//
//                     >
//                         <Column
//                             title="编号"
//                             dataIndex="id"
//                             key="id"
//                             width={50}
//                         />
//                         <Column
//                             title="卡片类型"
//                             dataIndex="card_type"
//                             width={80}
//                             key="card_type"
//                             render={(card_type) => <span>{card_type === 0 && "次卡"}{card_type === 1 && "VIP年卡"}</span>}
//                         />
//
//                         <Column
//                             title="名称"
//                             dataIndex="name"
//                             width={120}
//                             key="name"
//                         />
//                         <Column
//                             title="包含课程"
//                             dataIndex="courses"
//                             key="courses_type"
//                             ellipsis
//                             render={(courses) => {
//                                 return courses.map(item => item.name).join()
//                             }}
//                         />
//                         <Column
//                             title="正课"
//                             dataIndex="keshi"
//                             key="keshi"
//                             width={70}
//                             align="right"
//                         />
//                         <Column
//                             title="门市价"
//                             dataIndex="retail_price"
//                             key="retail_price"
//                             width={90}
//                             render={(retail_price) => retail_price.toFixed(2)}
//                             align="right"
//                         />
//                         <Column
//                             title="使用限制"
//                             dataIndex="time_type"
//                             key="time_type"
//                             render={(time_type, record) => {
//                                 if (time_type === 0) {
//                                     return (<span>
//                                         开卡后{record.limit_days}天内可使用
//                                     </span>)
//                                 }
//                                 if (time_type === 1) {
//                                     return (<span>
//                                         固定日期：到{moment(record.expired_date).format("YYYY-MM-DD")}之前可用
//                                     </span>)
//                                 }
//                             }}
//                         />
//                         <Column
//                             title="使用时间"
//                             dataIndex="holiday_use"
//                             key="holiday_use"
//                             width={170}
//                             render={(time_type, record) => {
//                                 return (
//                                     <span>
//                                         {record.holiday_use === 1 && "周末"}
//                                         {record.weekdays_use === 1 && "工作日"}
//                                         {record.special_use === 1 && "特殊日期"}
//                                     </span>
//                                 )
//                             }}
//                         />
//
//                         {this.hasRole(3) &&
//                         <Column
//                             title="操作"
//                             key="action"
//                             width={50}
//                             render={(text, record) => (
//                                 <div>
//                                     <a href="javascript:void(0)" onClick={() => {
//                                         this.setState({editUser: record})
//                                     }}>修改</a>
//                                 </div>
//                             )}
//                         />
//                         }
//                     </Table>
//                 </Box>
//
//
//                 <Box title={"年卡"} extra={<Button type='primary' icon={<PlusOutlined/>} onClick={this.showAddModalNianKa}>添加年卡</Button>}>
//
//                     <Table
//                         dataSource={listnianka}
//                         rowKey={"id"}
//                         pagination={false}
//                         size={"small"}
//
//                     >
//                         <Column
//                             title="编号"
//                             dataIndex="id"
//                             key="id"
//                             width={50}
//                         />
//                         <Column
//                             title="卡片类型"
//                             dataIndex="card_type"
//                             width={80}
//                             key="card_type"
//                             render={(card_type) => <span>{card_type === 0 && "次卡"}{card_type === 1 && "VIP年卡"}</span>}
//                         />
//
//                         <Column
//                             title="名称"
//                             dataIndex="name"
//                             width={120}
//                             key="name"
//                         />
//                         <Column
//                             title="包含课程"
//                             dataIndex="courses"
//                             key="courses_type"
//                             ellipsis
//                             render={(courses) => {
//                                 return courses.map(item => item.name).join()
//                             }}
//                         />
//                         <Column
//                             title="年费（门市价）"
//                             dataIndex="retail_price"
//                             key="retail_price"
//                             width={120}
//                             render={(retail_price) => retail_price.toFixed(2)}
//                             align="right"
//                         />
//                         <Column
//                             title="使用限制"
//                             dataIndex="time_type"
//                             key="time_type"
//                             render={(time_type, record) => {
//                                 if (time_type === 0) {
//                                     return (<span>
//                                         开卡后{record.limit_days}天内可使用
//                                     </span>)
//                                 }
//                                 if (time_type === 1) {
//                                     return (<span>
//                                         固定日期：到{moment(record.expired_date).format("YYYY-MM-DD")}之前可用
//                                     </span>)
//                                 }
//                             }}
//                         />
//                         <Column
//                             title="使用时间"
//                             dataIndex="holiday_use"
//                             key="holiday_use"
//                             width={170}
//                             render={(time_type, record) => {
//                                 return (
//                                     <span>
//                                         {record.holiday_use === 1 && "周末"}
//                                         {record.weekdays_use === 1 && "工作日"}
//                                         {record.special_use === 1 && "特殊日期"}
//                                     </span>
//                                 )
//                             }}
//                         />
//
//                         {this.hasRole(3) &&
//                         <Column
//                             title="操作"
//                             key="action"
//                             width={50}
//                             render={(text, record) => (
//                                 <div>
//                                     <a href="javascript:void(0)" onClick={() => {
//                                         this.setState({editUserNianKa: record})
//                                     }}>修改</a>
//                                 </div>
//                             )}
//                         />
//                         }
//                     </Table>
//
//
//                 </Box>
//
//                 <CardsAddModal
//                     onOK={() => {
//                         this.loadData();
//                         this.hideAddModal()
//                     }}
//                     onClose={this.hideAddModal}
//                     visible={this.state.showAddModal}
//                 />
//
//                 <CardsAddModalNianKa
//                     onOK={() => {
//                         this.loadData();
//                         this.hideAddModalNianKa()
//                     }}
//                     onClose={this.hideAddModalNianKa}
//                     visible={this.state.showAddModalNianKa}
//                 />
//
//                 <CardsEditModal
//                     onOK={() => {
//                         this.loadData();
//                         this.setState({editUser: null})
//                     }}
//                     onClose={() => {
//                         this.setState({editUser: null})
//                     }}
//                     data={this.state.editUser}
//                 />
//
//                 <CardsEditModalNianKa
//                     onOK={() => {
//                         this.loadData();
//                         this.setState({editUserNianKa: null})
//                     }}
//                     onClose={() => {
//                         this.setState({editUserNianKa: null})
//                     }}
//                     data={this.state.editUserNianKa}
//                 />
//
//
//             </PageContent>
//         );
//     }
// }

Cards.propTypes = {};

export default withRouter(Cards);


