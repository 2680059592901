import PropTypes from 'prop-types';
import React from 'react'
import ReactDOM from 'react-dom'
import {useSpring, animated, to} from 'react-spring'
import {useGesture} from 'react-use-gesture'
// import imgs from './imgs'
import styles from './event-calendar.module.css';
import moment from 'moment';
import store from 'store2';
import {withSize} from 'react-sizeme'
import {Dropdown, Menu} from 'antd';

const calcX = (y, ly) => -(y - ly - window.innerHeight / 2) / 20
const calcY = (x, lx) => (x - lx - window.innerWidth / 2) / 20

const wheel = y => {
    const imgHeight = window.innerWidth * 0.3 - 20
    return `translateY(${-imgHeight * (y < 0 ? 6 : 1) - (y % (imgHeight * 5))}px`
}

document.addEventListener('gesturestart', e => e.preventDefault())
document.addEventListener('gesturechange', e => e.preventDefault())

const columns = [{
    title: "教练A",
}, {
    title: "教练B",
}, {
    title: "教练C",
}, {
    title: "教练D",
}, {
    title: "教练E",
}, {
    title: "教练F",
}];

const steps = [5, 10, 20, 60];

// 列定义
function Column({title, width, height, titleHeight, current}) {
    console.log(current)
    const domTarget = React.useRef(null);
    const [{backgroundColor}, set] = useSpring(() => ({
        backgroundColor: "#ffffff",
        config: {mass: 5, tension: 350, friction: 60}
    }));
    const bind = useGesture({
            onHover: ({hovering}) => set({backgroundColor: hovering ? "#f5f5f5" : "#ffffff"}),
        },
        {domTarget, eventOptions: {passive: false}}
    );
    React.useEffect(bind, [bind]);

    return (
        <animated.div ref={domTarget} className={styles.column} style={{width: width, height: height, backgroundColor}}>
            <div className={styles["column-title"]}
                 style={{height: titleHeight, lineHeight: `${titleHeight}px`}}>{title} {current + ""}</div>
        </animated.div>
    )
}

function TimeLine({top, left}) {
    return (<div className={styles.timeline} style={{top, left}}></div>)
}

function getTimes(date, step, show08, times = []) {
    // console.log(moment(date).format("HH:mm"));
    let cell_count = 24 * 60 / step;
    // let times = [];
    // times=[];
    times.splice(0, times.length);
    if (show08) {
        for (let i = 0; i < cell_count; i++) {
            let time = moment(date).add(i * step, "m");
            times.push({
                title: time.format("HH:mm"),
                time: time
            })
        }
    } else {
        for (let i = (8 * 60 / step); i < cell_count; i++) {
            let time = moment(date).add(i * step, "m");
            times.push({
                title: time.format("HH:mm"),
                time: time
            })
        }
    }
    // console.log(times);
    return times;
}

function getHours(date, show08) {
    let cell_count = 24;
    let times = [];
    for (let i = show08 ? 0 : 8; i < cell_count; i++) {
        let time = moment(date).add(i, "h");
        if (i < 12) {
            times.push({
                title: time.format("上午h时"),
                time: time
            })
        } else if (i === 12) {
            times.push({
                title: time.format("正午"),
                time: time
            })
        } else {
            {
                times.push({
                    title: time.format("下午h时"),
                    time: time
                })
            }
        }

    }
    return times;
}

const colorTable = {
    "": ["#000000", "rgba(0,0,0,0)"],
    "red": ["#ff0000", "rgba(255,0,0,0.3)"],
    "green": ["#35a646", "rgba(33, 241, 32, 0.1)"],
    "blue": ["#006aa6", "rgba(0, 164, 255, 0.3)"],
    "orange": ["#c5890f", "rgba(255, 174, 20, 0.3)"],
    "gray": ["#666666", "rgba(176, 176, 176, 0.3)"],
    "pink": ["#ff15e4", "rgba(255, 21, 228, 0.3)"],
    "orange-dark": ["#ff7232", "rgba(255, 114, 50, 0.3)"],
};

function Event({
                   width, height, default_top = 0, default_left = 0,
                   text, row, col, cell_height, cell_width,
                   lock, color, scrollTop, scrollLeft, columns,
                   onClick, onDragStart, onDragEnd, onHover,
                   extra_time, extra_height, begin_time, event_type,
                   menu
               }) {
    // if (lock) {
    //     return (<animated.div className={styles["event-cell"]}
    //                           style={{
    //                               width, height,
    //                               top: default_top, left: default_left,
    //                               borderLeftColor: colorTable[color][0],
    //                               backgroundColor: colorTable[color][1]
    //                           }}
    //                           onClick={onClick}
    //     >
    //         <div className={styles["event-cell-content"]}>
    //             {text}
    //         </div>
    //         <div className={styles["event-cell-extra"]} style={{height: extra_height}}>
    //         </div>
    //     </animated.div>)
    // }

    const domTarget = React.useRef(null);
    const [drag, setDrag] = React.useState(false); // 拖动状态
    const [rightBtn, setRightBtn] = React.useState(false); // 是否是右键状态
    const [{top, left, scale}, set] = useSpring(() => ({
        top: default_top,
        left: default_left,
        scale: 1,
        config: {mass: 1, tension: 450, friction: 30},
    }));

    const bind = useGesture({
            onDragStart: (e) => {
                // console.log(e.buttons);
                if (e.buttons === 2) {
                    setRightBtn(true)
                } else {
                    onDragStart && onDragStart(e);
                }
                // console.log(e);

                // console.log(e);
            },
            onDrag: ({xy: [x, y], movement: [mx, my], distance, ...e}) => {
                // console.log(e.buttons)
                if (rightBtn) return;
                if (distance > 20 && !lock) {
                    setDrag(true);
                }
                if (drag) {
                    set({
                        left: x - 160 - cell_width / 2 + scrollLeft.goal,
                        top: y - 90 - cell_height / 2 + scrollTop.goal,
                        scale: 1
                    })
                }

                // console.log(distance);
            },
            onDragEnd: (e) => {
                // console.log(e);
                if (rightBtn) {
                    setRightBtn(false);
                    return;
                }
                if (drag) {
                    setDrag(false);
                    // 计算位置并插入
                    // console.log(col.goal);
                    set({
                        top: row.goal * cell_height,
                        left: col.goal * cell_width
                    });
                    // columns[col.goal].
                    e.event.ismove = true;
                    e.event.newcolumn = columns[col.goal];
                    onDragEnd && onDragEnd(e.event)

                } else {

                    onClick && onClick(e.event)

                }
                // console.log(e)

            },
            onHover: ({hovering}) => {
                // console.log(hovering);
                onHover && onHover(hovering);
                hovering && !drag && !lock ? set({scale: 1.1}) : set({scale: 1})
            },
        }, {domTarget, eventOptions: {passive: false}}
    );


    return (
        <Dropdown overlay={menu || <div/>} trigger={['contextMenu']}>

            <animated.div className={styles["event-cell"]}
                          ref={domTarget}
                          style={{
                              width, height,
                              top, left,
                              borderLeftColor: colorTable[color][0],
                              backgroundColor: colorTable[color][1],
                              scale,
                              zIndex: event_type === "yuyue" ? 101 : 99
                          }}
                //{...bind()}
                // onClick={onClick}
            >
                <div className={styles["event-cell-content"]}>
                    {text}
                </div>
                <div className={styles["event-cell-extra"]} style={{height: extra_height}}>
                </div>
            </animated.div>
        </Dropdown>
    )
}

function EventCalendar({
                           step,
                           date,
                           cell_width,
                           cell_height,
                           columns,
                           onEventClick,
                           onEventDragEnd,
                           onClick,
                           onPaste,
                           children,
                           size,
                           menu,
                           show08,
                       }) {
    // console.log(size);
    if (size.width - 80 > cell_width * columns.length) {
        cell_width = (size.width - 80) / columns.length;
    }
    const domTarget = React.useRef(null);
    const tableContainer = React.useRef(null);
    // console.log(tableContainer)
    React.useEffect(() => {
        // console.log(tableContainer)
        if (tableContainer.current) {
            tableContainer.current.scrollTop = store("scrollTop") || 0;
            tableContainer.current.scrollLeft = store("scrollLeft") || 0;
        }
    }, [tableContainer]);

    const [{x, y, rotateX, rotateY, rotateZ, zoom, scale}, set] = useSpring(() => ({
        rotateX: 0,
        rotateY: 0,
        rotateZ: 0,
        scale: 1,
        zoom: 0,
        x: 0,
        y: 0,
        config: {mass: 5, tension: 350, friction: 60},
        onFrame: frame => { // Per-item event listeners
            console.log('onFrame:', frame)
        },
    }));

    const [{mouseX, mouseY, row, col, scrollTop, scrollLeft}, setMouse] = useSpring(() => ({
        mouseX: 0,
        mouseY: 0,
        row: 0,
        col: 0,
        scrollTop: store("scrollTop") || 0,
        scrollLeft: store("scrollLeft") || 0,
        immediate: true,
        config: {mass: 1, tension: 0, friction: 60, clamp: true},
        onRest: frame => { // Per-item event listeners
            setReflash(reflash => !reflash);
            // console.log('onFrame:', frame, reflash)
        },
    }));

    // const [{wheelY}, setWheel] = useSpring(() => ({wheelY: 0}));
    const [drag, setDrag] = React.useState(false); // 拖动状态
    const [hovering, setHovering] = React.useState(false); //

    const [follow, setFollow] = React.useState(true); //

    // const [isWheel, setIsWheel] = React.useState(false);
    // const [step, setStep] = React.useState(5);  // 时间间隔（分钟）
    // const [{row, col}, setRowCol] = React.useState({row: 0, col: 0});

    // console.log(date)
    const current_date = moment(date);
    // let timesarr = [];
    const [times, setTimes] = React.useState([]);  // 时间间隔（分钟）
    const [cell_count, setCellCount] = React.useState((show08 ? 24 : 16) * 60 / step);
    const [height, setHeight] = React.useState(cell_count * cell_height);
    const [reflash, setReflash] = React.useState(false);
    // const [mouseX,setMouseX] = React.useState(0);
    // const [mouseY,setMouseY] = React.useState(0);
    // let current_row = 0;
    // let current_col = 0;

    // React.useEffect(() => {
    //     console.log("aaa")
    // }, [row]);

    React.useEffect(() => {
        // console.log(date)
        setTimes(getTimes(moment(date), step, show08, times))
    }, [date, show08]);


    React.useEffect(() => {
        let cell_count = (show08 ? 24 : 16) * 60 / step;
        setTimes(getTimes(current_date, step, show08, times));
        setCellCount(cell_count);
    }, [step]);

    React.useEffect(() => {
        setHeight((cell_count + 1) * cell_height)
    }, [cell_count]);

    // 计算表格小时行的高度
    let time_row_data = getHours(current_date, show08);
    let time_row_height = 60 / step * cell_height;

    // const wheelOffset = React.useRef(0);

    const bind = useGesture({
            // onDragStart: () => setDrag(true),
            // onDrag: ({offset: [x, y]}) => set({x, y, rotateX: 0, rotateY: 0, scale: 1}),
            // onDragEnd: (e) => {
            //     setDrag(false)
            //     console.log(e)
            // },
            // onPinch: ({ offset: [d, a] }) => set({ zoom: d / 200, rotateZ: a }),
            // onMove:(e)=> console.log(e),
            onMove: ({xy: [px, py], dragging}) => {

                if (!follow) return;
                // console.log(x, y, px, py);
                // 计算行列
                let nrow = 0, ncol = 0;
                let top = py - y.goal + scrollTop.goal - 30 - 60;
                // console.log(top);
                nrow = parseInt(top / cell_height) - 1;
                // if(!show08) nrow += 8;
                if (nrow < 0) nrow = 0;
                if (nrow > cell_count - 1) nrow = cell_count - 1;
                if (nrow !== row.goal) {
                    // setMouse({})
                    setMouse({row: nrow});
                    // current_row=nrow;
                    // setReflash(!reflash);
                    // setHeight(height+0);
                }

                let left = px - x.goal + scrollLeft.goal - 80 - 80;
                // console.log(left)
                ncol = parseInt(left / cell_width);
                if (ncol < 0) ncol = 0;
                if (ncol > columns.length - 1) ncol = columns.length - 1;
                // console.log(ncol);
                if (ncol !== col.goal) {
                    // console.log(ncol, current_col);
                    setMouse({col: ncol});
                    // current_col=ncol;
                    // setReflash(!reflash);
                    // setHeight(height+0);
                    // setHeight(height+1);
                }

                // console.log(x.goal);


                // setMouse({
                //     mouseX: px,
                //     mouseY: py
                // })
                // setMouseX(px);
                // setMouseY(py);
                // !dragging && set({scale: 1.1})
            },
            // onHover: ({ hovering }) => !hovering && set({ rotateX: 0, rotateY: 0, scale: 1 }),
            // onScroll:({offset: [,y], vxvy: [, vy],distance,...e})=>{
            //     // if(vy){
            //     //     wheelOffset.current = y;
            //     //     runSprings(dragOffset.current + y, vy)
            //     // }
            //     if(y>0)y = 0;
            //     if(y+height<500)y=500 - height
            //     set({y:distance});
            //     // setMouse({scrollTop: y});
            //     console.log(y,vy, e)
            // },
            // onWheel:(e)=>{
            //     console.log(e)
            // },
            // onWheel: (e) => {
            //     setIsWheel(true);
            //     // console.log(e.offset)
            //     if (e.direction[1] > 0) {
            //         let index = steps.indexOf(step);
            //         index -= 1;
            //         if (index < 0) index = 0;
            //         // console.log(steps[index]);
            //         setStep(steps[index]);
            //         let cell_count = 24 * 60 / steps[index];
            //         // console.log(cell_count);
            //         let rheight = cell_count * cell_height;
            //         setHeight(rheight)
            //         // setWheel({wheelY: -100})
            //     } else {
            //         setWheel({wheelY: 100})
            //
            //     }
            //     // console.log(e);
            //     // if(y > )
            //     // setWheel({ wheelY: y })
            // },
            // onWheelStart: (e) => {
            //     console.log(e);
            //     if (e.direction[1] > 0) {
            //         // stepChange(5);
            //         let index = steps.indexOf(step);
            //         index -= 1;
            //         if (index < 0) index = 0;
            //         setStep(steps[index]);
            //     } else {
            //         // stepChange(60)
            //         let index = steps.indexOf(step);
            //         index += 1;
            //         if (index > steps.length - 1) index = steps.length - 1;
            //         setStep(steps[index]);
            //     }
            //
            // },
            // onWheelEnd: (e) => {
            //     console.log(e)
            //     setIsWheel(false);
            // }
        },
        {domTarget, eventOptions: {passive: false}}
    )
    //
    const containerBind = useGesture({
        onScroll: ({offset: [x, y]}) => {
            // if(y<0)y = 0;
            // set({y});
            // setMouse({scrollTop: y});
            setMouse({scrollTop: y, scrollLeft: x})
            store({scrollTop: y, scrollLeft: x})
            // console.log(y)
        }
    }, {tableContainer, eventOptions: {passive: false}});
    // React.useEffect(bind, [containerBind]);

    const columnBind = useGesture({
        // onDrag: ({distance}) => {
        //     if (distance > 20) {
        //         setDrag(true);
        //     }
        //     // if(y<0)y = 0;
        //     // set({y});
        //     // setMouse({scrollTop: y});
        //     setMouse({scrollTop: y, scrollLeft: x})
        //     store({scrollTop: y, scrollLeft: x})
        //     // console.log(y)
        // },
        onDragEnd: (e) => {
            // console.log(e)
            // if (e.distance < 20) {
            //     handleClick(e, columns[col.goal])
            // }
        }
    }, {eventOptions: {passive: true}});


    // React.useEffect(bind, [bind]);

    // 获得当前时间并计算出位置
    let current_timeline_top = 0;
    if (moment().isSame(current_date, "day")) {
        if (show08) {
            current_timeline_top = moment().diff(current_date, "m");
        } else {
            current_timeline_top = moment().diff(moment(current_date).add(8, "h"), "m");
        }
        current_timeline_top = current_timeline_top / step * cell_height;
        // console.log(current_timeline_top);
    }

    // console.log(x);

    // const bindEvent = useGesture(
    //     {
    //         onDragStart: () => setDrag(true),
    //         onDrag: ({offset: [x, y]}) => set({x, y, rotateX: 0, rotateY: 0, scale: 1}),
    //         onDragEnd: () => setDrag(false),
    //     }
    // )

    // 处理事件显示位置
    columns.forEach(column => {
        column.events && column.events.forEach(event => {
            let begin_time = moment(date + " " + event.begin_time, "YYYY-MM-DD HH:mm");
            let end_time = moment(date + " " + event.end_time, "YYYY-MM-DD HH:mm");
            let minius = begin_time.diff(show08 ? current_date : moment(current_date).add(8, "h"), "m");
            event.top = minius / step * cell_height;
            // 计算高度
            let diff = end_time.diff(begin_time, "m");
            event.height = diff / step * cell_height;
            event.extra_height = event.extra_time / step * cell_height;
            // console.log(event.extra_time);
            event.height += event.extra_height;
            // console.log(diff);
        });
    });

    // console.log("columns")


    const handleClick = function (e, column, yuyue_type = 0) {
        console.log(drag);
        if (!drag) {
            console.log(e);
            e.yuyue_type = yuyue_type;
            e.column = column;
            e.time = times && times[row.goal] && times[row.goal].time;
            e.offset = [col.goal * cell_width - scrollLeft.goal, row.goal * cell_height - scrollTop.goal];
            onClick && onClick(e);
        }
        setFollow(true);
    };

    const handleContextMenuVisibleChange = function (visible) {
        console.log(visible);
        setFollow(!visible);
    }
    const handlePaste = function (e, column) {
        console.log(drag);
        if (!drag) {
            console.log(e);
            // e.yuyue_type = yuyue_type;
            e.column = column;
            e.time = times && times[row.goal] && times[row.goal].time;
            e.offset = [col.goal * cell_width - scrollLeft.goal, row.goal * cell_height - scrollTop.goal];
            onPaste && onPaste(e);
        }
        setFollow(true);
    };

    const handleEventClick = function (e, column, event) {
        e.event = event;
        e.column = column;
        e.time = times && times[row.goal] && times[row.goal].time;
        e.offset = [col.goal * cell_width - scrollLeft.goal, row.goal * cell_height - scrollTop.goal];
        onEventClick && onEventClick(e);
    };
    const handleEventDragEnd = function (e, column, event) {
        e.event = event;
        e.column = column;
        e.time = times && times[row.goal] && times[row.goal].time;
        e.offset = [col.goal * cell_width - scrollLeft.goal, row.goal * cell_height - scrollTop.goal];
        onEventDragEnd && onEventDragEnd(e);
    };

    return (
        <div className={styles.container}>
            {/*{`x:${x}`}*/}
            {/*{`y:${y}`}*/}

            {/*时间列*/}
            <div className={styles["time-column-container"]}>
                <div className={styles["time-column-title"]}>{current_date.format("M月D日")}</div>
                <animated.div className={styles["time-column-cells"]} style={{
                    y: to([scrollTop], scrollTop => -scrollTop)
                }}>
                    {time_row_data.map(time_row => <div className={styles["time-column-cell"]}
                                                        style={{height: time_row_height}}><span>{time_row.title}</span>
                    </div>)}
                </animated.div>
            </div>
            {/*数据表*/}
            <div className={styles["column-container"]}>
                {/*列头*/}
                <div className={styles["column-title-container"]}>
                    <animated.div className={styles["column-title-wrapper"]}
                                  style={{
                                      width: columns.length * cell_width,
                                      x: to([scrollLeft], scrollLeft => -scrollLeft)
                                  }}
                    >
                        {columns.map((column, index) =>
                            <div className={styles["column-title"]} style={{width: cell_width}}>
                                {column.title}
                            </div>
                        )}
                    </animated.div>
                </div>
                <animated.div ref={tableContainer}
                              className={styles["table-container"]}
                              {...containerBind()}
                    // style={{
                    //     scrollTop: to([scrollTop], scrollTop=>scrollTop)
                    // }}
                >
                    <animated.div
                        ref={domTarget}
                        className={`${drag ? styles["table-wrapper"] + ' dragging' : styles["table-wrapper"] + ""}`}
                        // style={{transform: `perspective(600px) translate3d(${x}px,${y}px,0)`}}
                        style={{
                            transform: 'perspective(600px)',
                            x,
                            y,
                            scale: to([scale, zoom], (s, z) => s + z),
                            rotateX,
                            rotateY,
                            rotateZ,
                            height,
                            width: columns.length * cell_width,
                        }}
                    >
                        {/*当前时间线*/}
                        <Dropdown overlay={<Menu>
                            <Menu.Item key="1" onClick={(e) => handleClick(e, columns[col.goal], 0)}>散客预约</Menu.Item>
                            <Menu.Item key="2" onClick={(e) => handleClick(e, columns[col.goal], 1)}>会员预约</Menu.Item>
                            <Menu.Item key="3" onClick={(e) => handleClick(e, columns[col.goal], 2)}>潜在预约</Menu.Item>
                            {/*<Menu.Item key="4" onClick={(e) => handleClick(e, columns[col.goal], 1)}>小组预约</Menu.Item>*/}
                            <Menu.Divider/>
                            <Menu.Item key="100" onClick={(e) => handlePaste(e, columns[col.goal])}>粘贴</Menu.Item>
                        </Menu>} trigger={['contextMenu']}
                                  onVisibleChange={(visible) => handleContextMenuVisibleChange(visible)}>

                            <animated.div className={styles.timeline}
                                          style={{
                                              y: to([row, y], (row, y) => {
                                                  return (row) * cell_height;
                                                  // top = parseInt(top / cell_height) * cell_height;
                                                  // if (top < cell_height) top = cell_height;
                                                  // if (top > cell_height * (cell_count - 1)) top = cell_height * (cell_count - 1);
                                                  // return top
                                              }),
                                              x: to([col, x], (col, x) => {
                                                  return (col) * cell_width;

                                              }, 0),
                                              width: cell_width,
                                              height: hovering ? 1 : cell_height * 12,
                                              // display: hovering?"none": "block"
                                          }}
                                          onClick={(e) => {
                                              console.log(e);
                                              handleClick(e, columns[col.goal])
                                          }}
                            >
                                <span>{times && times[row.goal] && times[row.goal].title}</span>
                                {/*10:10*/}
                                {/*{row.interpolate((v)=>v+"")}*/}
                                {/*{JSON.stringify(row)}*/}
                            </animated.div>
                        </Dropdown>
                        {/*当前时间*/}
                        <div className={styles["time-column-current-time"]} style={{
                            top: current_timeline_top,
                            width: columns.length * cell_width
                        }}/>
                        {/*{columns.map((column, index) =>*/}
                        {/*<Column title={column.title}*/}
                        {/*width={cell_width}*/}
                        {/*titleHeight={cell_height}*/}
                        {/*height={height}*/}
                        {/*current={col.goal===index}*/}
                        {/*/>)}*/}


                        {/*数据列*/}
                        {columns.map((column, index) =>
                            <animated.div
                                // onClick={(e) => {
                                //     console.log(1);
                                //     handleClick(e, column)
                                // }}
                                {...columnBind()}
                                className={styles.column}
                                style={{
                                    width: cell_width,
                                    height: height,
                                    backgroundColor: col.interpolate((v) => v === index ? "#f5f5f5" : "#ffffff")
                                }}>
                                {time_row_data.map(time_row =>
                                    <div className={styles["hour-cell"]} style={{height: cell_height * 12}}/>
                                )}
                                {column.events && column.events.map(event =>
                                    <Event
                                        width={cell_width}
                                        height={event.height}
                                        default_top={event.top}
                                        default_left={index * cell_width}
                                        text={event.text}
                                        row={row}
                                        col={col}
                                        cell_height={cell_height}
                                        cell_width={cell_width}
                                        lock={event.lock}
                                        color={event.color}
                                        scrollLeft={scrollLeft}
                                        scrollTop={scrollTop}
                                        columns={columns}
                                        extra_time={event.extra_time}
                                        extra_height={event.extra_height}
                                        begin_time={event.begin_time}
                                        event_type={event.event_type}
                                        menu={event.menu}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            console.log(e);
                                            setDrag(false)
                                            handleEventClick(e, column, event);
                                        }}
                                        onDragEnd={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            console.log(e);
                                            setDrag(false)
                                            handleEventDragEnd(e, column, event);
                                        }}
                                        onDragStart={(e) => {

                                            setDrag(true)
                                        }}
                                        onHover={(newhovering) => {
                                            if (event.event_type === "paiban" && newhovering === true) {
                                                newhovering = false;
                                            }
                                            hovering !== newhovering && setHovering(newhovering)
                                        }}
                                    />)}
                            </animated.div>
                        )}
                    </animated.div>
                </animated.div>
            </div>
        </div>
    )
}

EventCalendar.propTypes = {
    date: PropTypes.string,
    step: PropTypes.number,
    cell_width: PropTypes.number,
    cell_height: PropTypes.number,
    columns: PropTypes.array,
    onEventClick: PropTypes.func,
    onClick: PropTypes.func,
    onPaste: PropTypes.func,
    onEventDragEnd: PropTypes.func,
    show08: PropTypes.bool, // 显示0-8小时
};
EventCalendar.defaultProps = {
    date: moment().format("YYYY-MM-DD"),
    step: 5,
    cell_width: 120,
    cell_height: 15,
    columns: [{
        title: "教练A",
        events: [
            {begin_time: "01:00", end_time: "01:15", text: "ccccc", lock: true, color: ""}
        ]
    }, {
        title: "教练B",
        events: [
            {begin_time: "01:00", end_time: "01:15", text: "dddddd", lock: false, color: ""}
        ]
    }, {
        title: "教练C",
    }, {
        title: "教练D",
    }, {
        title: "教练E",
    }, {
        title: "教练F",
    }],
    show08: true,
    follow: true
};
export default withSize()(EventCalendar);