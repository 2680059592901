import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {postStaff2, getStaffConfig} from "../../../lib/api_staff";
import '@ant-design/compatible/assets/index.css';
import {Modal, Input, Form, InputNumber, Radio} from 'antd';
import md5 from 'md5';
import CoursesTypeCheck from './courses_type_check';
import RolesCheckbox from '../../../components/config/roles_checkbox';
import CoachLevelRadio from '../../../components/config/coach_level_radio';
import Extattr from './extattr-editor';
import FormDataCheck from '../../../components/data/form-data-check';


class StaffAddModal extends Component {
    formRef = React.createRef();

    state = {
        is_coach: 0,
        extattr: ""
    };

    handleClose = () => {
        this.props.onClose && this.props.onClose();
    };

    handleOK = () => {
        this.props.onOK && this.props.onOK();
    };

    componentDidMount() {
        this.getConfig();
    }

    getConfig = () => {
        getStaffConfig()
            .then(res => {
                if (res.code === 0) {
                    this.setState({
                        extattr: res.data
                    })
                }
            })
    }

    handleSubmit = e => {
        e.preventDefault();

        this.formRef.current.validateFields()
            .then(values => {
                console.log('Received values of form: ', values);
                postStaff2({...values, password: md5(values.password)})
                    .then(result => {
                        console.log(result);
                        // 刷新数据
                        if (result.code === 0) {
                            this.handleOK();

                        }
                    })
            });
    };

    handleRolesChange = (values) => {
        // console.log(values)
        this.setState({
            is_coach: (values.includes(1) || values.includes(4)) ? 1 : 0
        })
    };

    render() {
        const {is_coach, extattr} = this.state;
        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 6},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 18},
            },
        };
        if (!extattr) return null;

        return (
            <Modal
                title="添加成员"
                visible={this.props.visible}
                onOk={this.handleSubmit}
                onCancel={this.handleClose}
                destroyOnClose={true}
                width={600}
            >
                <Form {...formItemLayout}
                    // onSubmit={this.handleSubmit}
                      initialValues={{
                          password: "123456",
                          staff_order_by: 0,
                          gender: 1,
                          extattr: extattr
                      }}
                      ref={this.formRef}
                >
                    <Form.Item
                        label="账号"
                        name="userid"
                        extra="成员唯一标识，设置以后不能修改！"
                        rules={[
                            {
                                required: true,
                                message: '请输入账号',
                            },
                        ]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label="姓名"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: '请输入姓名',
                            },
                        ]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label="别名"
                        name="alias"
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label="性别"
                        name="gender"
                    >
                        <Radio.Group>
                            <Radio value={1}>男</Radio>
                            <Radio value={2}>女</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        label="手机号码"
                        name="mobile"
                        rules={[
                            {
                                required: true,
                                message: '请输入手机号码',
                            },
                        ]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label="邮箱"
                        name="email"
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label="职务"
                        name="position"
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label="角色"
                        name="roles"
                    >
                        <FormDataCheck table="roles" labelkey="name" valuekey="id"/>
                    </Form.Item>
                    {/*<Form.Item*/}
                    {/*label="职位"*/}
                    {/*name="roles"*/}
                    {/*rules={[*/}
                    {/*{*/}
                    {/*required: true,*/}
                    {/*message: '请选择职位',*/}
                    {/*},*/}
                    {/*]}*/}
                    {/*>*/}
                    {/*<RolesCheckbox onChange={this.handleRolesChange}/>*/}
                    {/*</Form.Item>*/}
                    {/*{(is_coach === 1) && <>*/}
                    {/*<Form.Item*/}
                    {/*label="授课类型"*/}
                    {/*name="courses_type"*/}
                    {/*>*/}
                    {/*<CoursesTypeCheck/>*/}
                    {/*</Form.Item>*/}
                    {/*<Form.Item*/}
                    {/*label="教练级别"*/}
                    {/*name="teacher_level"*/}
                    {/*>*/}
                    {/*<CoachLevelRadio />*/}
                    {/*</Form.Item>*/}
                    {/*</>}*/}
                    <Form.Item
                        label="初始密码"
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: '请输入初始密码',
                            },
                        ]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label="备注"
                        name="remark"
                    >
                        <Input.TextArea/>
                    </Form.Item>
                    <Form.Item
                        // label="其他信息"
                        name="extattr"
                        wrapperCol={{
                            span: 24
                        }}
                    >
                        <Extattr/>
                    </Form.Item>
                    {/*<Form.Item*/}
                    {/*label="职级"*/}
                    {/*name="staff_order_by"*/}
                    {/*rules={[*/}
                    {/*{*/}
                    {/*required: true,*/}
                    {/*message: '请输入职级',*/}
                    {/*},*/}
                    {/*]}*/}
                    {/*>*/}
                    {/*<InputNumber/>*/}
                    {/*</Form.Item>*/}
                </Form>
            </Modal>
        )
    }
}

StaffAddModal.propTypes = {
    visible: PropTypes.bool,
    onClose: PropTypes.func,
    onOK: PropTypes.func,
};

export default StaffAddModal;
