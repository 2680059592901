import PropTypes from 'prop-types';
import React, {useState, useEffect} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import PageContent from '../../../components/page-content/page-content';
import Box from '../../../components/box/index';
import {getMembershipInfo} from "../../../lib/api_membership";
import {Descriptions, Button, Row, Col} from 'antd';
import moment from 'moment';
import MembersList from '../members-list';
import MembershipHistory from './membership_history';
import MembershipFee from './membership_fee';
import MembershipChongZhiModal from './membership-chongzhi-modal';
import MembershipZengsongModal from './membership-zengsong-modal';
import MembershipXufeiModal from './membership_xufei_modal';
import MembershipDatesList from './membership_dates_list';
import MembershipXiuMianModal from './membership_xiumian_modal';

const MembershipDetail = ({id}) => {
    const [data, setData] = useState({
        users: []
    });
    const [chongzhi, setchongzhi] = useState(false);
    const [zengsong, setzengsong] = useState(false);
    const [hiskey, sethiskey] = useState(0);
    const [xufei, setxufei] = useState(false);
    const [xiumian, setxiumian] = useState(false);

    const fetchData = () => {
        getMembershipInfo(id)
            .then(res => {
                if (res.code === 0) {
                    setData(res.data);
                }
            })
    };

    useEffect(fetchData, []);

    return (
        <PageContent
            title={"vip会籍详情"}
            routes={[
                {
                    path: '',
                    breadcrumbName: <><span>首页</span></>,
                },
                {
                    path: '//membership',
                    breadcrumbName: 'vip会籍管理',
                },
                {
                    path: `//${id}`,
                    breadcrumbName: 'vip会籍详情',
                },
            ]}
            extra={[
                <Button disabled>升级</Button>,
                <Button disabled>退会</Button>,
                <Button onClick={() => setxiumian(true)}>休眠</Button>,
                <Button onClick={() => setxufei(true)}>续费</Button>,
                <Button onClick={() => setzengsong(true)}>赠送教练费</Button>,
                <Button type={"primary"} onClick={() => setchongzhi(true)}>充值教练费</Button>,
            ]}
        >
            <Box>
                <Descriptions bordered size={"small"}>
                    <Descriptions.Item label="会籍名称">{data.name}</Descriptions.Item>
                    {/*<Descriptions.Item label="指导价格">{data.price}</Descriptions.Item>*/}
                    <Descriptions.Item
                        label="开始日期">{data.begin_time ? moment(data.begin_time).format("YYYY-MM-DD") : ""}
                    </Descriptions.Item>
                    <Descriptions.Item label="剩余教练费">{data.fee_remaining}</Descriptions.Item>
                    <Descriptions.Item label="成员数量">{data.member_count}</Descriptions.Item>
                    <Descriptions.Item
                        label="结束日期">{data.end_time ? moment(data.end_time).format("YYYY-MM-DD") : ""}
                    </Descriptions.Item>
                    {/*<Descriptions.Item label="实际支付年费">{data.payments}</Descriptions.Item>*/}
                    <Descriptions.Item label="剩余赠送教练费">{data.fee_free_remaining}</Descriptions.Item>

                    {/*<Descriptions.Item label="剩余年费">{data.payments_remaining}</Descriptions.Item>*/}
                    <Descriptions.Item label="状态">{data.status === 0 ? "正常" : "失效"}</Descriptions.Item>

                    <Descriptions.Item label="剩余天数">{data.limit_days}</Descriptions.Item>
                </Descriptions>
            </Box>
            <Box title={"会员"} style={{marginTop: 10}}>
                <MembersList list={data.users} is_select={false}/>

            </Box>
            <Box title={"年费"} style={{marginTop: 10}}>
                <MembershipDatesList list={data.memberships_dates}/>

            </Box>
            <Row gutter={12}>
                <Col span={12}>
                    <MembershipFee id={id} key={hiskey}/>
                </Col>
                <Col span={12}>
                    <MembershipHistory id={id} key={hiskey}/>
                </Col>
            </Row>

            {chongzhi && <MembershipChongZhiModal
                id={id}
                onOk={() => {
                    fetchData();
                    setchongzhi(false);
                    sethiskey(hiskey + 1);
                }}
                onClose={() => {
                    setchongzhi(false)
                }}
            />}
            {zengsong && <MembershipZengsongModal
                id={id}
                onOk={() => {
                    fetchData();
                    setzengsong(false);
                    sethiskey(hiskey + 1);
                }}
                onClose={() => {
                    setzengsong(false)
                }}
            />}
            {xufei && <MembershipXufeiModal
                data={data}
                onOk={() => {
                    fetchData();
                    setxufei(false);
                    sethiskey(hiskey + 1);
                }}
                onClose={() => {
                    setxufei(false)
                }}
            />}
            {xiumian && <MembershipXiuMianModal
                data={data}
                onOk={() => {
                    fetchData();
                    setxiumian(false);
                    sethiskey(hiskey + 1);
                }}
                onClose={() => {
                    setxiumian(false)
                }}
            />}


        </PageContent>
    )
};


MembershipDetail.propTypes = {
    id: PropTypes.number.isRequired,
};


const mapStateToProps = state => ({
    courses_type: state.courses_type
});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(MembershipDetail));
