import React, {Component} from 'react';
import {Switch, Route, withRouter} from "react-router-dom";
import {connect} from 'react-redux';
import {Layout, Menu} from 'antd';
import './App.css';
import Login from './containers/login/login';
import './assets/react-contextmenu.css';
import AuthComponent from './components/auth_component';
// import {getFirstMenu} from "./components/sider-menu/menu.config";
import SiderBar, {getFirstMenu} from './components/sider-menu/sidebar';
import Settings from './containers/settings/Router';
import Members from './containers/members/Router';
import Calendar from './containers/calendar/calendar3';
import PaiBan from './containers/paiban/paiban';
import Stat from './containers/stat/Router';
import QueRen from './containers/calendar/queren';

@AuthComponent
class App extends Component {
    state = {
        path: "/",
        siderWidth: 224
    };

    routeChange = (route) => {
        console.log(route); // 这个route里面有当前路由的各个参数信息
        this.setState({
            path: route.pathname
        }, () => {
            // 获取菜单情况
            let menu = getFirstMenu(this.state.path, this.props.menus);
            console.log(menu);
            if (menu && menu.children.length === 0) {
                this.setState({
                    siderWidth: 92
                })
            } else {
                this.setState({
                    siderWidth: 224
                })
            }
        })
    };

    componentDidMount() {
        // 路由监听
        this.historyinstance = this.props.history.listen(this.routeChange);
        setTimeout(()=>{
            this.routeChange(this.props.history.location)
        }, 2000)
        // this.routeChange(this.props.history.location)
    }

    componentWillUnmount() {
        // 取消监听
        this.historyinstance && this.historyinstance();
    }

    render() {
        let {account, menus} = this.props;
        if (!account) {
            return (<Login/>)
        }
        console.log(menus);
        return (
            <Layout style={{minHeight: '100vh'}}>
                {/*侧边导航栏*/}
                <SiderBar path={this.state.path} menus={menus}/>
                <Layout.Content style={{marginLeft: this.state.siderWidth}}>
                    <Switch>
                        <Route path="/settings"
                               component={Settings}/>
                        <Route path="/member"
                               component={Members}/>
                        <Route exact path="/paiban"
                               component={PaiBan}/>
                        <Route path="/stat"
                               component={Stat}/>
                        <Route path="/queren"
                               component={QueRen}/>
                        <Route path="/"
                               component={Calendar}/>
                    </Switch>
                </Layout.Content>
            </Layout>
        );

    }
}


const mapStateToProps = state => ({
    // school: state.school,
    account: state.account,
    menus: state.menus
});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(App));
