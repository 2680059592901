import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {Modal, Form, InputNumber, Input, DatePicker} from 'antd';
import {postMembership} from "../../../lib/api_membership";
import MembershipTypeSelect from '../../settings/membership_type/membership_type_select';
import moment from 'moment';
import MemberSelect from '../member_select';

const MembershipAddModal = ({members, visible, onOk, onCancel}) => {
    const [form] = Form.useForm();
    const [type_id, setTypeID] = useState(0);
    const [memberCount, setMemberCount] = useState(0);
    const [type, settype] = useState(null);

    const handleSubmit = () => {
        form.validateFields()
            .then(values => {
                // 校验会员人数
                if (values.members.length > memberCount) {
                    form.setFields([{
                        name: "members",
                        errors: ["会员人数不能超过会籍人数上限！"]
                    }]);
                    return;
                }

                console.log(values)
                postMembership(values)
                    .then(res => {
                        if (res.code === 0) {
                            onOk && onOk(res.data);
                        }
                    })
            })
    };

    const handleTypeChange = (id, item) => {
        console.log(id, item);
        setTypeID(id);
        setMemberCount(item.member_count);
        settype(item);
        let begin_time = moment(moment().format("YYYY-MM-DD"));
        let end_time = moment(begin_time).add(item.limit_days-1, "d");

        form.setFieldsValue({
            begin_time: begin_time,
            end_time: end_time,
            payments: item.price
        })
    };

    const handleTimeChange = (value) => {
        let begin_time = value;
        let end_time = moment(begin_time).add(type.limit_days-1, "d");
        form.setFieldsValue({
            begin_time: begin_time,
            end_time: end_time,
        })
    };

    const formItemLayout = {
        labelCol: {
            xs: {span: 24},
            sm: {span: 6},
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 18},
        },
    };

    return (
        <Modal
            title="新建vip会籍"
            visible={visible}
            // onOk={form.submit}
            onOk={handleSubmit}
            onCancel={onCancel}
            destroyOnClose={true}
            width={800}
        >
            <Form {...formItemLayout}
                  form={form}
                // onSubmit={this.handleSubmit}
                  initialValues={{
                      price: 0,
                      member_count: 0,
                      limit_days: 0,
                      members: members
                  }}
                // ref={this.formRef}
            >

                <Form.Item
                    label="会员"
                    name="members"
                    rules={[
                        {
                            required: true,
                            message: '请选择会员',
                        },
                    ]}
                >
                    <MemberSelect/>
                </Form.Item>

                <Form.Item
                    label="会籍类型"
                    name="type_id"
                    rules={[
                        {
                            required: true,
                            message: '请选择类型',
                        },
                    ]}
                >
                    <MembershipTypeSelect onChange={handleTypeChange}/>
                </Form.Item>
                {type_id > 0 ? <>
                <Form.Item
                    label="开始日期"
                    name="begin_time"
                    rules={[
                        {
                            required: true,
                            message: '请选择开始日期',
                        },
                    ]}
                >
                    <DatePicker format={"YYYY-MM-DD"} onChange={handleTimeChange}/>
                </Form.Item>
                <Form.Item
                    label="结束日期"
                    name="end_time"
                    rules={[
                        {
                            required: true,
                            message: '请选择结束日期',
                        },
                    ]}
                >
                    <DatePicker format={"YYYY-MM-DD"} disabled/>
                </Form.Item>
                <Form.Item
                    label="实际支付年费"
                    name="payments"
                    rules={[
                        {
                            required: true,
                            message: '请输入实际支付年费',
                        },
                    ]}
                >
                    <InputNumber precision={2} min={0}/>
                </Form.Item>

                </> : null}


            </Form>
        </Modal>
    )
};

MembershipAddModal.propTypes = {
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
    onOk: PropTypes.func,
};

export default MembershipAddModal;
